const SVGCrossIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.41403 19.1425C10.6554 14.9011 14.7951 10.5546 19.0385 6.31114"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19.1425 19.0401C14.9011 14.7987 10.5546 10.659 6.31114 6.41563"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SVGCrossIcon;
