import { useAppSelector } from '@hooks';
import { selectCursorStatus } from '@store/dom-slice';
import { gsap } from 'gsap';
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { CursorStatus } from '../../types';
import styles from './cursor.module.scss';

const Cursor = forwardRef((props, ref: ForwardedRef<any>) => {
  const el = useRef<HTMLDivElement>(null);
  const cursorStatus = useAppSelector(selectCursorStatus);
  const smallAnimation = useRef<GSAPTimeline>();
  const largeAnimation = useRef<GSAPTimeline>();
  const dragAnimation = useRef<GSAPTimeline>();

  useEffect(() => {
    smallAnimation.current = gsap.timeline({ paused: true }).to(el.current, {
      width: '20px',
      height: '20px',
      backgroundColor: '#d2e8df',
      backgroundSize: 0,
    });
    largeAnimation.current = gsap.timeline({ paused: true }).to(el.current, {
      width: '28px',
      height: '28px',
      backgroundColor: 'transparent',
      border: '1px solid #d2e8df',
      backgroundSize: 0,
    });
    dragAnimation.current = gsap.timeline({ paused: true }).to(el.current, {
      width: '116px',
      height: '116px',
      backgroundColor: '#27272a',
      backgroundSize: 'auto',
    });

    switch (cursorStatus) {
      case CursorStatus.small:
        smallAnimation.current.play();
        break;
      case CursorStatus.large:
        largeAnimation.current.play();
        break;
      case CursorStatus.drag:
        dragAnimation.current.play();
        break;
      default:
        break;
    }
  }, [cursorStatus]);

  useImperativeHandle(ref, () => {
    return {
      moveTo(x: number, y: number) {
        gsap.to(el.current!, { x, y });
      },
    };
  });

  return <div ref={el} className={styles.newCursor}></div>;
});
Cursor.displayName = 'Cursor';

export default Cursor;
