import { Button } from '@components';
import { Logo120Years } from '@components/svgs';
import {
  useAppSelector,
  useGSAPSelector,
  useScrollDirection,
  useTabletMediaQuery,
  useWeb3Auth,
} from '@hooks';
import { selectIsAuthenticated } from '@store/auth-slice';
import { selectIsNavbarModalOpen } from '@store/dom-slice';
import { ScrollDirection } from '@types';
import { firebaseAuth } from '@utils/firebase';
import classnames from 'classnames';
import { gsap } from 'gsap';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useLayoutEffect, useState } from 'react';
import HamburgerMenu from './hamburger-menu/hamburger-menu';
import LanguageSelector from './language-selector/language-selector';
import styles from './navbar.module.scss';

const withoutLoginScreens = ['welcome', 'code', 'raffle'];

const PrivateNavbar = () => {
  const scrollDirection = useScrollDirection({ initialDirection: ScrollDirection.SCROLL_DOWN });
  const [scrolledToTop, setScrolledToTop] = useState(true);
  const { t } = useTranslation('common');
  const { ref, q } = useGSAPSelector();
  const isTablet = useTabletMediaQuery();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const { login, logout } = useWeb3Auth();
  const isModalOpen = useAppSelector(selectIsNavbarModalOpen);
  const router = useRouter();
  const [shouldShowLogin, setShouldShowLogin] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const handleScroll = () => {
    setScrolledToTop(window.pageYOffset < 50);
  };

  const goToLanding = () => router.push('/');

  const handleLogout = async () => {
    setIsFetching(true);
    await logout()
      .then(async () => await firebaseAuth.signOut().then(() => router.push('/')))
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    setShouldShowLogin(!withoutLoginScreens.some((route) => router.asPath.includes(route)));
  }, [router.asPath]);

  useLayoutEffect(() => {
    gsap.set(q('.navbarItem'), { opacity: 0 });
    gsap.to(q('.navbarItem'), {
      delay: 1.5,
      y: 0,
      opacity: 1,
      stagger: 0.05,
      duration: 0.5,
      ease: 'shine',
    });
  }, [q]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return !isTablet ? (
    <nav
      className={classnames(
        styles.navbar,
        scrollDirection === ScrollDirection.SCROLL_UP && !scrolledToTop && styles.navbarScrolledUp,
        scrollDirection === ScrollDirection.SCROLL_DOWN &&
          !scrolledToTop &&
          styles.navbarScrolledDown,
      )}>
      <div ref={ref} className={styles.contentWrapper}>
        <Link href="/">
          <div className={classnames('navbarItem', styles.logoMainContainer)}>
            <Image src="/images/main-logo.png" alt="Yael X Manor Logo" width={161} height={40} />
          </div>
        </Link>
        <div className={classnames(styles.languageSelector, 'navbarItem')}>
          <LanguageSelector />
        </div>
        <div className="navbarItem">
          {shouldShowLogin && (
            <>
              {isAuthenticated ? (
                <Button
                  onClick={handleLogout}
                  delay={0}
                  variant="privateSecondary"
                  isLoading={isFetching}>
                  {t('log_out')}
                </Button>
              ) : (
                <Button onClick={login} delay={0} variant="privateSecondary">
                  {t('log_in')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </nav>
  ) : (
    <nav
      className={classnames(
        styles.navbar,
        scrollDirection === ScrollDirection.SCROLL_UP && !scrolledToTop && styles.navbarScrolledUp,
        scrollDirection === ScrollDirection.SCROLL_DOWN &&
          !scrolledToTop &&
          styles.navbarScrolledDown,
      )}>
      <div ref={ref} className={styles.contentWrapper}>
        <div className={classnames(styles.logo120Container, 'navbarItem')}>
          {isModalOpen ? <LanguageSelector /> : <Logo120Years height={'100%'} width={'100%'} />}{' '}
        </div>
        <Link href="/">
          <div className={classnames(styles.logoMainContainer, 'navbarItem')}>
            <Image src="/images/main-logo.png" alt="Yael X Manor Logo" width={161} height={40} />
          </div>
        </Link>
        <div className="navbarItem">
          {shouldShowLogin ? <HamburgerMenu /> : <LanguageSelector />}
        </div>
      </div>
    </nav>
  );
};

export default PrivateNavbar;
