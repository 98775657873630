import { Spinner } from '@components';
import { useGSAPSelector } from '@hooks';
import classnames from 'classnames';
import { gsap } from 'gsap';
import { ReactNode, useEffect, useRef } from 'react';
import styles from './button.module.scss';
import SVGSSecondaryArrowShape from './svgs/svg-secondary-arrow-shape';
import SVGSecondaryBackgroundButton from './svgs/svg-secondary-background-button';
interface ButtonProps {
  children: ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'privatePrimary' | 'privateSecondary';
  delay?: number;
  color?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

const Button = ({
  children,
  variant = 'primary',
  delay = 0,
  color,
  disabled = false,
  isLoading = false,
  onClick,
}: ButtonProps) => {
  const textRef = useRef(null);
  const tl = useRef<GSAPTimeline>();
  const animationSecondaryTl = useRef<GSAPTimeline>();
  const animationTertiaryTl = useRef<GSAPTimeline>();
  const { ref: arrowRef, q } = useGSAPSelector();

  const handleOnMouseEnterSecondary = () => {
    animationSecondaryTl.current?.play();
    animationTertiaryTl.current?.play();
  };

  const handleOnMouseEnterTertiary = () => {
    animationTertiaryTl.current?.play();
  };

  const handleOnMouseLeaveSecondary = () => {
    animationSecondaryTl.current?.reverse();
    animationTertiaryTl.current?.restart().pause();
  };

  const handleOnMouseLeaveTertiary = () => {
    animationTertiaryTl.current?.restart().pause();
  };

  useEffect(() => {
    if (variant === 'secondary') {
      animationSecondaryTl.current = gsap
        .timeline({ paused: true })
        .fromTo('#secondaryBackgroundButton', { drawSVG: '100%' }, { drawSVG: '0%' });

      gsap.set('#secondaryBackgroundButton', { drawSVG: '0%' });
      gsap.set('#secondaryGreenBackgroundButton', { drawSVG: '0%' });
      gsap.set('#secondaryArrowShape', { drawSVG: '0%' });
      tl.current = gsap
        .timeline({ defaults: { duration: 1, delay: 1, ease: 'shine' } })
        .to(['#secondaryBackgroundButton', '#secondaryGreenBackgroundButton'], { drawSVG: '100%' })
        .to('#secondaryArrowShape', { drawSVG: '100%' }, 0)
        .paragraphFadeIn(textRef.current, { delay: 0 }, '<-=50%');
    }

    if (variant === 'tertiary') {
      animationTertiaryTl.current = gsap
        .timeline({ paused: true, repeat: -1, yoyo: true })
        .to(arrowRef.current, { x: -5 });
    }

    return () => {
      tl.current?.kill();
      animationSecondaryTl.current?.kill();
      animationTertiaryTl.current?.kill();
    };
  }, [variant, delay]);

  switch (variant) {
    case 'primary':
      return (
        <button
          disabled={disabled}
          onClick={onClick}
          className={classnames(styles.button, styles.primary)}>
          <div ref={textRef}>{children}</div>
        </button>
      );
    case 'privatePrimary':
      return (
        <button
          disabled={disabled}
          onClick={onClick}
          className={classnames(styles.button, styles.private)}>
          <div ref={textRef}>{isLoading ? <Spinner variant="secondary" /> : children}</div>
        </button>
      );
    case 'privateSecondary':
      return (
        <button
          disabled={disabled}
          onClick={onClick}
          className={classnames(styles.button, styles.privateSecondary)}>
          <div ref={textRef}>{isLoading ? <Spinner variant="primary" /> : children}</div>
        </button>
      );
    case 'secondary':
      return (
        <button
          disabled={disabled}
          onClick={onClick}
          onMouseEnter={handleOnMouseEnterSecondary}
          onMouseLeave={handleOnMouseLeaveSecondary}
          className={styles.button}>
          <div className={classnames(styles.contentWrapper, styles.absoluteCenter)}>
            <div ref={textRef}>{children}</div>
            <div className={styles.secondaryShape}>
              <SVGSSecondaryArrowShape ref={arrowRef} />
            </div>
          </div>
          <div className={styles.shapesWrapper}>
            <SVGSecondaryBackgroundButton zIndex="2" />
            <SVGSecondaryBackgroundButton color="#D2E8DF" id="secondaryGreenBackgroundButton" />
          </div>
        </button>
      );
    case 'tertiary':
      return (
        <button
          disabled={disabled}
          onClick={onClick}
          className={styles.button}
          onMouseEnter={handleOnMouseEnterTertiary}
          onMouseLeave={handleOnMouseLeaveTertiary}>
          <div className={styles.contentWrapper}>
            {children}
            <div ref={arrowRef} className={styles.secondaryShape}>
              <SVGSSecondaryArrowShape color={color} />
            </div>
          </div>
        </button>
      );
    default:
      return (
        <button disabled={disabled} onClick={onClick} className={styles.button}>
          <div className={styles.contentWrapper}>{children}</div>
        </button>
      );
  }
};

export default Button;
