import classNames from 'classnames';
import { forwardRef } from 'react';
import styles from './tag.module.scss';

interface TagProps {
  mode: 'light' | 'dark';
  text: string;
  outlined?: boolean;
  color?: string;
}

const Tag = forwardRef<HTMLDivElement, TagProps>(({ mode, text, color, outlined = false }, ref) => (
  <div
    ref={ref}
    className={classNames(styles.tag, styles[mode], outlined && styles.outlined)}
    style={color ? { color } : {}}>
    {text}
  </div>
));
Tag.displayName = 'Tag';

export default Tag;
