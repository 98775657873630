import { SVGArrowDown } from '@components/svgs';
import { useAppDispatch, useArrayRef, useHasMounted, useOnClickOutside } from '@hooks';
import { setIsNavbarModalOpen } from '@store/dom-slice';
import { gsap } from 'gsap';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import styles from './language-selector.module.scss';

interface DropdownItem {
  id: string;
  locale: string;
  value: string;
  text: string;
}

const items: DropdownItem[] = [
  { id: '01', locale: 'en', value: 'ENG', text: 'English' },
  { id: '02', locale: 'fr', value: 'FR', text: 'Français' },
  { id: '03', locale: 'de', value: 'DE', text: 'Deutsch' },
  { id: '04', locale: 'it', value: 'IT', text: 'Italiano' },
];

const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<DropdownItem>(items[0]);
  const router = useRouter();
  const animation = useRef<GSAPTimeline>();
  const mainWrapperRef = useRef(null);
  const dropDownRef = useRef(null);
  const { refs: dropdownItemRefs, setRef: setDropdownItemRef } = useArrayRef<HTMLAnchorElement>();
  const iconRef = useRef(null);
  const [languages, setLanguages] = useState<DropdownItem[]>(items);
  const hasMounted = useHasMounted();
  const dispatch = useAppDispatch();
  useOnClickOutside(mainWrapperRef, () => setIsOpen(false));

  const handleOnClick = (item: DropdownItem) => {
    setSelectedItem(item);
    dispatch(setIsNavbarModalOpen(false));
  };

  useEffect(() => {
    setSelectedItem(items.find((item) => item.locale === router.locale) || items[0]);
  }, [router.locale]);

  useEffect(() => {
    // hasMounted && setLanguages(items.filter((item) => item !== selectedItem));
  }, [selectedItem, hasMounted]);

  useEffect(() => {
    animation.current = gsap
      .timeline({ paused: true, defaults: { duration: 0.1, ease: 'shine' } })
      .fromTo(iconRef.current, { rotation: 0 }, { rotation: -180 }, 0)
      .fromTo(dropDownRef.current, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.3 }, 0)
      .fromTo(
        dropdownItemRefs.current,
        { autoAlpha: 0, y: '0.5em', stagger: 0.1 },
        { autoAlpha: 1, y: '0em', stagger: 0.1 },
        '<-25%',
      );
  }, [dropdownItemRefs]);

  useEffect(() => {
    isOpen ? animation.current?.play() : animation.current?.reverse();
  }, [isOpen]);

  return (
    <div ref={mainWrapperRef} className={styles.mainWrapper}>
      <div onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)} className={styles.selectedItem}>
        <div className={styles.selectedItemText}>{selectedItem.value}</div>
        <div ref={iconRef} className={styles.selectedItemIcon}>
          <SVGArrowDown />
        </div>
      </div>

      <div ref={dropDownRef} className={styles.dropdown}>
        {languages.map((item) => (
          <Link href={router.asPath} locale={item.locale} key={item.id}>
            <a
              ref={setDropdownItemRef}
              onClick={() => handleOnClick(item)}
              className={styles.dropdownItem}>
              {item.text}
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default LanguageSelector;
