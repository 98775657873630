const SVGMediumShape = () => {
  return (
    <svg
      width="80"
      height="154"
      viewBox="0 0 80 154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.06802 30.6C8.91085 28.8584 9.90496 27.1943 11.0389 25.627C13.5188 22.1968 13.5782 21.9378 18.0815 18.2209C25.5959 12.0224 34.0686 6.47615 42.9771 2.58476C61.6266 -5.56363 73.795 6.70186 77.6842 23.3435C80.9054 37.1254 79.9816 50.9723 79.4892 64.8096C78.6856 87.3825 74.8406 109.417 67.1203 130.768C65.3222 135.726 63.7594 140.765 62.2352 145.9C59.884 153.833 50.111 156.629 43.9024 151.163C39.6618 147.435 35.6149 143.653 32.0078 139.486C25.2916 131.719 20.1797 122.562 14.4745 113.82C14.184 113.376 -0.614983 84.7485 0.019853 68.3618C0.361475 59.55 2.90912 50.8269 4.37657 42.0498C4.53297 41.1889 4.63965 40.3196 4.69607 39.4463C4.78599 38.0232 5.15096 36.6311 5.77073 35.3472C6.43323 33.9887 7.33499 32.1261 8.06802 30.6Z"
        fill="#E3FC5D"
      />
    </svg>
  );
};

export default SVGMediumShape;
