import { Button } from '@components';
import { useAppDispatch, useAppSelector, useWeb3Auth } from '@hooks';
import { selectIsAuthenticated } from '@store/auth-slice';
import { selectIsNavbarModalOpen, setIsNavbarModalOpen } from '@store/dom-slice';
import { firebaseAuth } from '@utils/firebase';
import { gsap } from 'gsap';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import styles from './navbar-modal.module.scss';

const NavbarModalPrivate = () => {
  const modalAnimation = useRef<GSAPTimeline>();
  const modalRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsNavbarModalOpen);
  const { t } = useTranslation('common');
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const { login, logout } = useWeb3Auth();
  const titleRef = useRef(null);
  const router = useRouter();

  // const handleOnClick = () => {
  //   gsap.delayedCall(modalAnimation.current!.duration(), () =>
  //     dispatch(setIsNavbarModalOpen(false)),
  //   );
  // };

  // const handleOnClickSection = (item: MenuItem) => {
  //   scrollTo(item.id, 'down');
  //   dispatch(setIsNavbarModalOpen(false));
  // };

  const handleLogout = async () => {
    dispatch(setIsNavbarModalOpen(false));
    await logout().then(async () => await firebaseAuth.signOut().then(() => router.push('/')));
  };

  const handleLogin = () => {
    dispatch(setIsNavbarModalOpen(false));
    login();
  };

  useEffect(() => {
    modalAnimation.current = gsap
      .timeline({ paused: true })
      .to(modalRef.current, { autoAlpha: 1, duration: 0 })
      .fromTo(modalRef.current, { xPercent: -100 }, { xPercent: 0, ease: 'shine' })
      .to([titleRef.current], { autoAlpha: 1, duration: 0.75 }, '>-15%');
  }, []);

  useEffect(() => {
    isOpen
      ? modalAnimation.current?.timeScale(1.5).play()
      : modalAnimation.current?.timeScale(2).reverse();
    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'visible');
  }, [isOpen]);

  return (
    <div ref={modalRef} className={styles.modalWrapper}>
      <div className={styles.innerWrapperPrivate}>
        <div ref={titleRef} className={styles.listWrapper}>
          <div className={styles.cta}>
            {isAuthenticated ? (
              <Button onClick={handleLogout} delay={0} variant="privateSecondary">
                {t('log_out')}
              </Button>
            ) : (
              <Button onClick={handleLogin} delay={0} variant="privateSecondary">
                {t('log_in')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarModalPrivate;
