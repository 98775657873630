import { useRef } from 'react';

const useArrayRef = <T>() => {
  const refs = useRef<T[]>([]);
  refs.current = [];
  return {
    refs,
    setRef: (ref: T): void => {
      refs && refs.current.push(ref);
    },
  };
};

export default useArrayRef;
