import { SVGLineShape, Tag } from '@components';
import { useAppDispatch, useArrayRef, useOnScreen } from '@hooks';
import { setLateralTexts } from '@store/dom-slice';
import { RoadmapSectionProps } from '@types';
import classNames from 'classnames';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Image from 'next/image';
import React, { FC, useEffect, useRef } from 'react';
import styles from './roadmap-section.module.scss';
import SVGGreyShape from './shapes/svg-grey-shape';
import SVGTopBigShape from './shapes/svg-top-big-shape';

const RoadmapSection: FC<RoadmapSectionProps> = ({
  parentRef,
  isMobile,
  isTablet,
  shouldAvoidMovementAnimations,
  roadmapSectionData,
}) => {
  const { milestones } = roadmapSectionData;
  const sectionRef = useRef<HTMLDivElement>(null);
  const topBigShapeRef = useRef<HTMLDivElement>(null);
  const greyShapeRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const subtitleRef = useRef<HTMLDivElement>(null);
  const tagRef = useRef<HTMLDivElement>(null);
  const { refs: lineShapeRefs, setRef: setLineShapeRef } = useArrayRef<SVGPathElement>();
  const { refs: milestoneRefs, setRef: setMilestoneRef } = useArrayRef<HTMLDivElement>();
  const isOnScreen = useOnScreen(sectionRef);
  const dispatch = useAppDispatch();

  useEffect(() => {
    gsap.set(lineShapeRefs.current, { drawSVG: '0%' });

    const transitionInTween =
      !shouldAvoidMovementAnimations &&
      parentRef?.current &&
      gsap.to(parentRef.current, {
        scrollTrigger: {
          trigger: sectionRef.current,
          start: '-=75% top',
          end: 'top top',
          scrub: true,
        },
        backgroundColor: styles.colorGreyTransparent,
      });

    const inTimeline = gsap
      .timeline({
        paused: true,
        scrollTrigger: {
          trigger: sectionRef.current,
          start: `${isMobile || isTablet ? '-=45%' : '-=25%'} top`,
          once: true,
        },
      })
      .titleIn(titleRef.current, 0)
      .paragraphFadeIn([subtitleRef.current, tagRef.current, ...milestoneRefs.current], 0)
      .to(
        lineShapeRefs.current,
        {
          drawSVG: '100%',
          duration: 2,
        },
        0,
      )
      .from(greyShapeRef.current, { opacity: 0, yPercent: 300, duration: 1 }, 0);

    const topBigShapeTween = gsap.to(topBigShapeRef.current, {
      autoAlpha: 1,
      duration: 1,
      paused: true,
    });

    const outTransitionTween =
      !shouldAvoidMovementAnimations &&
      gsap.to(sectionRef.current, {
        scrollTrigger: {
          trigger: sectionRef.current,
          start: '+=25% top',
          scrub: 2,
        },
        yPercent: -75,
        opacity: 0,
      });

    ScrollTrigger.create({
      trigger: sectionRef.current,
      start: `${isMobile || isTablet ? '-=45%' : '-=25%'} top`,
      onEnter: () => {
        topBigShapeTween.play();
      },
      onUpdate: ({ progress, direction }) =>
        direction === -1 &&
        progress < 0.0000001 &&
        topBigShapeRef.current?.style.opacity === '1' &&
        topBigShapeTween.reverse().duration(0.75),
    });

    return () => {
      transitionInTween && transitionInTween?.kill();
      inTimeline.kill();
      outTransitionTween && outTransitionTween.kill();
      topBigShapeTween.kill();
    };
  }, [isMobile, isTablet, lineShapeRefs, milestoneRefs, parentRef, shouldAvoidMovementAnimations]);

  useEffect(() => {
    isOnScreen && dispatch(setLateralTexts({ leftText: 'lateral_texts.roadmap', rightText: '06' }));
  }, [isOnScreen, dispatch]);

  const renderMilestones = () => {
    return milestones.map((milestone) => (
      <React.Fragment key={milestone._key}>
        <div
          ref={(ref) => ref && setMilestoneRef(ref)}
          key={milestone._key}
          className={
            milestone.details && milestone.details.length > 0
              ? classNames(styles.milestone, styles.detailedMilestone)
              : styles.milestone
          }>
          <div className={styles.milestoneDate}>
            <span>
              <Image src="/svgs/ellipse.svg" alt="ellipse" height={8} width={8} layout="fixed" />
            </span>
            {milestone.date}
          </div>
          <div className={styles.milestoneTitle}>{milestone.title}</div>
          {milestone.details && milestone.details.length > 0 && (
            <div className={styles.milestoneDetailsWrapper}>
              {milestone.details.map((detail, index) => (
                <div key={`${milestone._key}_${index}`}>
                  <span>·</span>
                  {detail}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={styles.milestoneDivider}>
          <SVGLineShape ref={(ref) => ref && setLineShapeRef(ref)} />
        </div>
      </React.Fragment>
    ));
  };

  return (
    <section id="roadmap" data-section-id="#roadmap" ref={sectionRef} className={styles.section}>
      <div ref={greyShapeRef} className={styles.greyShapeWrapper}>
        <SVGGreyShape />
      </div>
      <div ref={topBigShapeRef} className={styles.topBigShapeWrapper}>
        <SVGTopBigShape />
      </div>
      <div ref={titleRef} className={styles.title}>
        <div ref={tagRef} className={styles.tagWrapper}>
          <Tag mode="light" text={roadmapSectionData.tag} />
        </div>
        {roadmapSectionData.title}
      </div>
      <div ref={subtitleRef} className={styles.subtitle}>
        {roadmapSectionData.subtitle}
      </div>
      <div className={styles.milestonesWrapper}>{renderMilestones()}</div>
    </section>
  );
};

export default RoadmapSection;
