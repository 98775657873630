const Phase1Shape = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1963 1585"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M480.357 719.704L479.927 719.45L480.357 719.704C480.532 719.407 481.533 717.983 483.332 715.516C485.119 713.064 487.675 709.607 490.935 705.27C497.456 696.597 506.794 684.403 518.444 669.677C541.743 640.226 574.286 600.649 612.022 558.859C687.513 475.256 783.719 382.864 868.247 344.866C913.723 324.425 961.75 309.664 1010.46 296.342C1027.1 291.792 1043.82 287.409 1060.54 283.025C1092.78 274.576 1125.03 266.121 1156.74 256.457C1166.27 253.698 1175.63 250.393 1184.78 246.56L1184.78 246.56C1199.6 240.328 1215.48 237.022 1231.54 236.825C1248.63 236.683 1272.04 236.423 1291.19 236.15C1313.05 235.903 1334.88 237.573 1356.43 241.142L1356.51 240.649L1356.43 241.142C1359.5 241.65 1362.39 242.122 1365.11 242.568C1383.34 245.547 1394.39 247.354 1406.69 250.957C1420.82 255.098 1436.59 261.613 1466.74 275.023C1567.28 319.775 1666.01 377.494 1750.15 447.938C1838.17 521.61 1866.35 604.525 1853.98 684.358C1841.6 764.226 1788.61 841.103 1714.11 902.614C1602.3 994.92 1472.88 1053.13 1343.42 1111.37C1329.95 1117.42 1316.48 1123.48 1303.03 1129.58C1070.25 1235.09 827.674 1307.15 572.554 1336.59C513.262 1343.37 454.325 1352.94 394.623 1363.39C302.778 1379.49 225.653 1295.09 249.769 1205.15L249.286 1205.02L249.769 1205.15C266.249 1143.51 284.244 1083.57 308.344 1026.15C341.765 946.628 387.423 872.208 433.287 797.454C449.118 771.652 464.973 745.809 480.357 719.704Z"
        stroke="#E3FC5D"
      />
      <path
        d="M1137.11 512.356C1148.12 512.231 1159.11 513.072 1169.96 514.869C1193.7 518.791 1195.17 518.438 1225.48 531.921C1276.04 554.428 1325.7 583.459 1368.03 618.894C1456.65 693.07 1424.88 785.977 1349.9 847.875C1287.81 899.139 1214.92 929.496 1143.17 962.022C1026.12 1015.08 904.133 1051.31 775.839 1066.12C746.036 1069.53 716.41 1074.34 686.396 1079.59C640.038 1087.72 601.11 1045.12 613.282 999.719C621.569 968.725 630.62 938.579 642.743 909.694C665.354 855.892 699.108 806.723 729.24 755.593C730.772 752.991 839.269 605.292 924.371 567.036C970.134 546.466 1021.02 537.335 1069.45 522.575C1074.23 521.191 1078.93 519.533 1083.52 517.609C1091 514.463 1099.01 512.794 1107.12 512.695C1115.72 512.624 1127.49 512.493 1137.11 512.356Z"
        fill="#E3FC5D"
      />
    </svg>
  );
};

export default Phase1Shape;
