import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { FieldError } from 'react-hook-form';
import styles from './form-error-message.module.scss';

interface FormErrorMessageProps {
  errors: { [key: string]: FieldError };
  name: string;
}

const FormErrorMessage: React.FC<FormErrorMessageProps> = ({ errors, name }) => (
  <ErrorMessage
    errors={errors}
    name={name}
    render={({ message }) => <div className={styles.message}>{message}</div>}
  />
);
export default FormErrorMessage;
