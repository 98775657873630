import { GSAPEffect } from '@types';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/dist/CustomEase';
import { SplitText } from 'gsap/dist/SplitText';

gsap.registerPlugin(CustomEase);

const titleInEase = CustomEase.create(
  'titleInEase',
  'M0,0 C0.012,0.097 0.146,0.568 0.43,0.828 0.608,0.991 0.979,1 1,1 ',
);

const registerEffects = (): void => {
  gsap.registerEffect({
    name: GSAPEffect.titleIn,
    extendTimeline: true,
    effect: (targets: any, config: any) => {
      const target = targets[0];
      const mySplitText = new SplitText(target, { type: 'word, chars' });
      const chars = mySplitText.chars;

      gsap.set(target, {
        css: {
          overflow: 'hidden',
        },
      });
      return gsap.from(chars, {
        autoAlpha: 0,
        y: '+=75%',
        stagger: {
          each: 0.03,
        },
        ease: titleInEase,
        duration: 1,
      });
    },
  });

  gsap.registerEffect({
    name: GSAPEffect.paragraphFadeIn,
    extendTimeline: true,
    defaults: { duration: 1, delay: 0 },
    effect: (targets: any, config: any) => {
      const mySplitTexts = targets.map((target: any) => new SplitText(target, { type: 'lines' }));
      const lines = mySplitTexts.map((mySplitText: any) => mySplitText.lines);
      const timeline = gsap.timeline({ duration: config.duration });
      timeline.fromTo(
        lines,
        {
          opacity: 0,
          y: '100%',
          stagger: {
            each: 0.15,
            ease: 'shine',
          },
        },
        {
          opacity: 1,
          y: '0%',
          display: 'inline',
          delay: config.delay,
        },
      );
      return timeline;
    },
  });

  gsap.registerEffect({
    name: 'swapText',
    extendTimeline: true,
    defaults: { duration: 2 },
    effect: (targets: any, config: any) => {
      const tl = gsap.timeline({ delay: config.delay });
      tl.to(targets, { autoAlpha: 0, duration: config.duration / 2 });
      for (const target of targets) {
        tl.add(() => (target.innerText = config.text));
      }
      tl.to(targets, { autoAlpha: 1, duration: config.duration });
      return tl;
    },
  });

  // gsap.registerEffect({
  //   name: GSAPEffect.swapTitleIn,
  //   extendTimeline: true,
  //   effect: (targets: any, config: any) => {
  //     const target = targets[0];
  //     const mySplitText = new SplitText(target, { type: 'word, chars' });
  //     const chars = mySplitText.chars;

  //     gsap.set(target, {
  //       css: {
  //         overflow: 'hidden',
  //       },
  //     });
  //     return gsap.from(chars, {
  //       autoAlpha: 0,
  //       y: '+=75%',
  //       stagger: {
  //         each: 0.03,
  //       },
  //       ease: titleInEase,
  //     });
  //   },
  // });
};

gsap.registerEffect({
  name: 'diamonds',
  extendTimeline: true,
  effect: (targets: any, config: any) => {
    const target = targets[0];
    const mySplitText = new SplitText(target, { type: 'word, chars' });
    const chars = mySplitText.chars as HTMLDivElement[];
    for (const char of chars) {
      if (['o', 'O'].includes(char.innerHTML.toString())) {
        const div = document.createElement('div');
        div.className = 'charWithDiamond';
        div.innerHTML = '✦';
        char.appendChild(div);
      }
    }
  },
});

export { registerEffects, titleInEase };
