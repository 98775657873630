import { ApolloProvider } from '@apollo/client';
import {
  Footer,
  ManorLogo,
  NavbarModalPrivate,
  Overlay,
  PrivateBg,
  PrivateNavbar,
} from '@components';
import { AuthProvider } from '@context/auth-context';
import { Web3AuthProvider } from '@context/web3auth-context';
import { useTabletMediaQuery } from '@hooks';
import { Web3Chain, Web3Network } from '@types';
import client from '@utils/apollo-client';
import { initializePrivate as initializePrivateFirebase } from '@utils/firebase';
import { ReactNode, useEffect } from 'react';
import styles from './private-layout.module.scss';

interface PrivateLayoutProps {
  children: ReactNode;
  showBackground?: boolean;
}

const PrivateLayout = ({ children, showBackground = true }: PrivateLayoutProps) => {
  const isTablet = useTabletMediaQuery();

  useEffect(() => {
    initializePrivateFirebase();
  }, []);

  return (
    <>
      {/* <Head>
        <title>White label project</title>
        <meta name="description" content="White label project Private Section" />
        <link rel="icon" href="/favicon.ico" />
      </Head> */}

      <ApolloProvider client={client}>
        <Web3AuthProvider
          chain={
            (process.env.NEXT_PUBLIC_ENVIRONMENT ?? process.env.NODE_ENV) === 'production'
              ? Web3Chain.polygon
              : Web3Chain.mumbai
          }
          web3AuthNetwork={
            (process.env.NEXT_PUBLIC_ENVIRONMENT ?? process.env.NODE_ENV) === 'production'
              ? Web3Network.mainnet
              : Web3Network.testnet
          }>
          <AuthProvider>
            <PrivateNavbar />
            <NavbarModalPrivate />
            <main>
              <div className={styles.greenBackground}>
                {showBackground && <PrivateBg />}
                <div className={styles.content}>{children}</div>
              </div>
            </main>
            {!isTablet && <ManorLogo />}
            <Overlay />
            <Footer />
          </AuthProvider>
        </Web3AuthProvider>
      </ApolloProvider>
    </>
  );
};

export default PrivateLayout;
