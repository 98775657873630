import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRef } from 'react';
import { useAppSelector, useWeb3Auth } from '@hooks';
import { Tag } from '@components';
import styles from './metamask-tutorial.module.scss';
import { copyToClipboard } from '@utils/utils';
import { logEvent } from 'firebase/analytics';
import { analytics } from '@utils/firebase';
import { selectCurrentUser } from '@store/user-slice';
import { apiCreateTrack } from '@services/track';
import { TrackType } from '../../types';

const MetamaskTutorial = () => {
  const { t } = useTranslation();
  const { web3Auth } = useWeb3Auth();
  const user = useAppSelector(selectCurrentUser);

  const copyKey = async () => {
    const key = await web3Auth?.provider?.request({
      method: 'eth_private_key',
    });

    if (key) copyToClipboard(key as string);

    logEvent(analytics, TrackType.REQUEST_PRIVATE_KEY, {
      content_type: `address: ${user?.address} - email: ${user?.email} - timestamp: ${Date.now()}`,
      content_id: user?.id,
    });

    apiCreateTrack({
      type: TrackType.REQUEST_PRIVATE_KEY,
      user,
      timestamp: Date.now(),
    });
  };

  const stepsRef = useRef([
    {
      text: t('metamask_tutorial.step_0'),
      action: copyKey,
    },
    // {
    //   text: t('metamask_tutorial.step_1'),
    //   link: 'https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-started-with-MetaMask',
    // },
    // {
    //   text: t('metamask_tutorial.step_2'),
    //   link: 'https://metamask.zendesk.com/hc/en-us/articles/360015489331-How-to-import-an-account',
    // },
  ]);
  const steps = stepsRef.current;

  return (
    <div className={styles.container}>
      {steps.map((step, index) => (
        <div key={index} className={styles.step}>
          <div className={styles.info}>
            <div className={styles.title}>
              <div className={styles.tag}>
                <Tag text={`0${index}`} mode="light" />
              </div>
              <div className={classnames(styles.text, styles[`--${index}`])} onClick={step.action}>
                <div>{step.text}</div>
                {index === 0 && (
                  <Image
                    src="/images/metamask-tutorial/clipboard.png"
                    width={30}
                    height={30}
                    alt="clipboard"
                  />
                )}
              </div>
            </div>
            {/* {step.image && ( */}
            {/* <div className={styles.imageContainer}> */}
            <div>
              {/* <Image src={step.image} layout="fill" objectFit="contain" alt="" /> */}
              {/* {step?.link && (
                <a className={styles.link} href={step.link} target="_blank" rel="noreferrer">
                  <div>{t(`metamask_tutorial.step_${index}_link_alt`)}</div>
                  <Image
                    src="/images/metamask-tutorial/link.svg"
                    width={20}
                    height={20}
                    alt="link"
                  />
                </a>
              )} */}
            </div>
            {/* </div> */}
            {/* )} */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MetamaskTutorial;
