export const onDOMElementLoad = (
  selector: string,
  host: Element | ShadowRoot | Document = document,
): Promise<Element | null> => {
  return new Promise((resolve) => {
    if (host.querySelector(selector)) {
      return resolve(host.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (host.querySelector(selector)) {
        resolve(host.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(host, {
      childList: true,
      subtree: true,
    });
  });
};

export const onDOMElementRemove = (
  element: Element,
  host: Element | ShadowRoot = document.body,
): Promise<void> => {
  console.log('element', element);
  return new Promise((resolve) => {
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        for (const el of Array.from(mutation.removedNodes)) {
          if (el === element) {
            observer.disconnect();
            resolve();
          }
        }
      }
    });

    observer.observe(host, {
      childList: true,
      subtree: true,
    });
  });
};

// WEB3AUTH MODAL
export const listenWeb3AuthModalLayout = () => {
  onDOMElementLoad('.w3a-modal__loader-adapter').then(() => {
    const header = document.querySelector('.w3a-modal__header') as HTMLElement;
    const footer = document.querySelector('.w3a-modal__footer') as HTMLElement;
    const power = document.querySelector('.w3a-spinner-power') as HTMLElement;
    header.style.opacity = '0';
    footer.style.opacity = '0';
    power.style.opacity = '1';
    onDOMElementLoad('.w3ajs-wallet-connect').then(() => {
      header.style.opacity = '1';
      footer.style.opacity = '1';
      power.style.opacity = '0';
      listenWeb3AuthModalLayout();
    });
  });
};
