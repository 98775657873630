import { forwardRef, ForwardedRef } from 'react';
import { SVGExtendedProps } from '@types';

const SVGMaskShape = forwardRef(
  (
    { isMobile, color = '#E3FC5D', strokeWidth = '2' }: SVGExtendedProps,
    ref: ForwardedRef<SVGSVGElement> | undefined,
  ) => {
    return isMobile ? (
      <svg
        ref={ref}
        width="267"
        height="293"
        viewBox="0 0 267 293"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M168.164 291.935L168.164 291.935C169.461 292.104 170.89 291.928 172.584 291.602C173.116 291.5 173.679 291.382 174.268 291.258C175.337 291.034 176.49 290.791 177.696 290.595C178.344 289.06 179.013 287.564 179.673 286.088C181.219 282.63 182.714 279.286 183.77 275.83C187.014 265.207 190.152 254.552 193.291 243.892C198.444 226.389 203.602 208.872 209.247 191.457C211.859 183.4 214.399 175.322 216.941 167.24C227.268 134.406 237.621 101.488 252.874 69.513C259.323 55.9933 263.189 41.9694 265.642 27.7012C266.632 21.9468 265.567 17.2803 262.499 13.6752C259.4 10.0348 254.136 7.33203 246.437 5.80585C240.555 4.64023 234.493 3.98029 228.388 3.31561C226.35 3.09381 224.308 2.87146 222.266 2.62961C176.404 -2.81342 136.407 5.18091 103.33 29.5745C95.2211 35.5557 86.8276 41.4146 77.9122 46.7723L77.3971 45.9152L77.9121 46.7724C52.7724 61.8788 33.7339 80.7004 14.5394 99.6762C12.7045 101.49 10.8682 103.306 9.02505 105.119C4.34018 109.73 1.84056 114.131 1.18035 118.533C0.52141 122.926 1.67132 127.477 4.60094 132.441C7.0457 136.584 9.40554 140.734 11.7606 144.877C16.0131 152.356 20.2501 159.809 24.9436 167.136C35.0794 182.959 43.8253 198.991 49.1471 216.313C55.2231 236.089 65.8145 254.87 82.0868 271.733C87.5057 277.348 94.0548 281.385 102.898 282.761L102.898 282.761C124.559 286.134 146.35 289.085 168.164 291.935Z"
          stroke={color}
          strokeWidth={strokeWidth}
        />
      </svg>
    ) : (
      <svg
        ref={ref}
        width="100%"
        height="100%"
        viewBox="0 0 980 1080"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M633.879 1102.73L633.749 1103.72L633.879 1102.73C639.237 1103.43 644.996 1102.69 651.431 1101.45C653.526 1101.05 655.697 1100.59 657.944 1100.12C662.383 1099.19 667.119 1098.2 672.144 1097.43C674.713 1091.29 677.412 1085.26 680.087 1079.28C685.956 1066.17 691.712 1053.3 695.744 1040.11C708 1000.03 719.839 959.859 731.679 919.685C751.107 853.77 770.538 787.84 791.819 722.247C801.685 691.838 811.278 661.369 820.872 630.894C859.815 507.198 898.79 383.399 956.225 263.111C980.682 211.883 995.327 158.785 1004.61 104.845C1008.45 82.5486 1004.36 64.0786 992.133 49.7335C979.882 35.353 959.339 24.974 929.986 19.1609C907.67 14.7433 884.636 12.2382 861.578 9.73047C853.916 8.89722 846.251 8.0636 838.609 7.15942C665.115 -13.4115 513.513 16.7817 388.069 109.207C357.521 131.717 325.942 153.737 292.421 173.862C197.306 230.961 125.272 302.109 52.9099 373.581C45.9811 380.424 39.0492 387.271 32.0939 394.108C14.1619 411.739 4.319 428.839 1.71085 446.21C-0.896021 463.573 3.70117 481.366 14.9655 500.435C24.1366 515.959 33.0158 531.561 41.8898 547.154C57.9563 575.385 74.0061 603.586 91.7416 631.247C129.91 690.774 162.746 750.939 182.711 815.861C205.752 890.785 245.919 961.932 307.598 1025.79C328.387 1047.31 353.615 1062.86 387.658 1068.16L387.505 1069.14L387.658 1068.16C469.385 1080.87 551.599 1091.99 633.879 1102.73Z"
          stroke={color}
          strokeWidth={strokeWidth}
        />
        <rect x="0" y="0" width="100%" height="100%" />
      </svg>
    );
  },
);
SVGMaskShape.displayName = 'SVGMaskShape';

export default SVGMaskShape;
