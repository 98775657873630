import { SVGIconInstagram, SVGIconTwitter } from '@components/svgs';
import { IconNames } from '@types';

interface IconProps {
  name: IconNames;
}

const Icon = ({ name }: IconProps) => {
  switch (name) {
    case IconNames.instagram:
      return <SVGIconInstagram />;
    case IconNames.twitter:
      return <SVGIconTwitter />;

    default:
      return <SVGIconInstagram />;
  }
};

export default Icon;
