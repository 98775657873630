import { gsap } from 'gsap';
import { useMemo, useRef } from 'react';

const useGSAPSelector = () => {
  const ref = useRef(null);
  const q = useMemo(() => gsap.utils.selector(ref), [ref]);
  return { q, ref };
};

export default useGSAPSelector;
