import { useAppDispatch, useOnScreen } from '@hooks';
import { setLateralTexts } from '@store/dom-slice';
import { TokenizedCommunitySectionProps } from '@types';
import classnames from 'classnames';
import { FC, useEffect, useRef } from 'react';
import Slide0 from './components/slide0/slide0';
import Slide1 from './components/slide1/slide1';
import styles from './tokenized-community-section.module.scss';

const TokenizedCommunitySection: FC<TokenizedCommunitySectionProps> = ({
  isTablet,
  isDesktop,
  shouldAvoidMovementAnimations,
  tokenizedCommunityData,
}) => {
  const sectionRef = useRef<HTMLElement>(null);
  const isOnScreen = useOnScreen(sectionRef);
  const dispatch = useAppDispatch();

  useEffect(() => {
    isOnScreen &&
      dispatch(setLateralTexts({ leftText: 'lateral_texts.community', rightText: '04' }));
  }, [isOnScreen, dispatch]);

  return (
    <section
      id="community"
      data-section-id="#community"
      ref={sectionRef}
      className={classnames(styles.section, 'tokenizedCommunitySection')}>
      <Slide0
        isTablet={isTablet}
        shouldAvoidMovementAnimations={shouldAvoidMovementAnimations}
        tokenizedCommunityData={tokenizedCommunityData}
      />
      <Slide1
        isTablet={isTablet}
        isDesktop={isDesktop}
        shouldAvoidMovementAnimations={shouldAvoidMovementAnimations}
        tokenizedCommunityData={tokenizedCommunityData}
      />
    </section>
  );
};

export default TokenizedCommunitySection;
