interface SVGSecondaryButtonProps {
  color?: string;
  zIndex?: string;
  id?: string;
}

const SVGSecondaryBackgroundButton = ({
  color = '#27272A',
  zIndex = '1',
  id = 'secondaryBackgroundButton',
}: SVGSecondaryButtonProps) => {
  return (
    <svg
      style={{ zIndex: zIndex }}
      width="241"
      height="67"
      viewBox="0 0 241 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        id={id}
        d="M100.354 1C72.8213 2.7495 45.2405 6.48233 27.2266 14.4462C9.06633 22.4748 -1.82343 32.2306 1.63974 42.6173C4.58442 51.4488 25.6208 57.2668 47.2854 60.5596C66.3629 63.4592 85.3433 65.0236 105.961 65.6318C128.476 66.2959 151.892 66.2214 173.837 64.0581C202.541 61.2287 225.484 52.3988 235.317 42.5892C245.144 32.7852 239.23 21.5033 220.154 13.6453C199.696 5.21769 170.651 1.70659 140.156 1.30911C121.788 1.0697 103.737 2.01163 85.4287 2.01163"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SVGSecondaryBackgroundButton;
