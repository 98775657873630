const SVGSmallShape = () => {
  return (
    <svg width="52" height="60" viewBox="0 0 52 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5441 0C22.1698 0.305721 22.6609 0.670285 23.211 0.793085C28.6066 2.0019 33.488 4.41185 38.1886 7.20555C41.3288 9.07185 44.3779 11.0929 47.4514 13.068C48.0437 13.4517 48.5759 13.925 49.1182 14.383C49.8427 15.0008 50.1568 15.7644 50.3928 16.7353C52.1725 24.0662 51.6045 31.5443 51.9879 38.9634C52.1558 42.2368 50.5659 44.4344 48.2002 46.3583C45.1498 48.8386 42.2468 51.5082 39.1041 53.8619C36.3204 55.9456 33.3085 57.7301 30.3671 59.6002C29.3592 60.2398 28.4437 60.0543 27.3128 59.4506C24.315 57.8439 21.1684 56.5085 18.0923 55.0515C13.4866 52.8616 8.8796 50.678 4.30207 48.4344C3.28032 47.9303 2.33146 47.2909 1.48118 46.5335C1.08369 46.1779 0.952903 45.4424 0.850325 44.854C0.161772 40.8886 0.0643224 36.8912 0.14382 32.8797C0.195109 30.3393 0.486169 27.7439 0.0758586 25.2712C-0.334452 22.7986 0.991373 21.2917 2.32873 19.836C6.92934 14.8268 11.6556 9.93017 16.3729 5.02969C18.0423 3.28618 19.8362 1.65268 21.5441 0Z"
        fill="#FF8D00"
      />
    </svg>
  );
};

export default SVGSmallShape;
