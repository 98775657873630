const SVGBottomShape = () => {
  return (
    <svg
      width="933"
      height="566"
      viewBox="0 0 933 566"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M931.786 926.894C932.979 947.215 932.384 967.601 930.008 987.814C924.826 1032.04 925.609 1034.73 903.311 1091.97C866.086 1187.46 816.711 1281.87 754.844 1363.32C625.339 1533.84 450.642 1483.59 329.545 1350.68C229.255 1240.61 166.705 1108.68 100.242 979.051C-8.1761 767.584 -85.8935 545.462 -124.511 309.73C-133.428 254.966 -144.925 200.659 -157.267 145.672C-176.363 60.7465 -100.936 -15.0719 -15.8652 3.28537C42.2115 15.7751 98.7866 29.7542 153.297 49.5258C254.83 86.4081 348.767 144.305 446.015 195.329C450.963 197.922 733.76 384.962 811.994 538.742C854.06 621.435 875.409 714.632 906.956 802.783C909.936 811.493 913.416 820.026 917.377 828.336C923.852 841.87 927.641 856.53 928.535 871.503C929.418 887.38 930.69 909.115 931.786 926.894Z"
        fill="#E3FC5D"
      />
    </svg>
  );
};

export default SVGBottomShape;
