import { AnimatedShape, Tag } from '@components';
import {
  useAppDispatch,
  useLargeMobileQuery,
  useOnScreen,
  useScrollDirection,
  useTabletMediaQuery,
} from '@hooks';
import { setLateralTexts } from '@store/dom-slice';
import { HeroSectionProps, ScrollDirection } from '@types';
import classnames from 'classnames';
import { gsap } from 'gsap';
import { useTranslation } from 'next-i18next';
import { FC, MutableRefObject, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import styles from './hero-section.module.scss';
import Parallax from './parallax/parallax';
import SVGMainShape from './svg-main-shape/svg-main-shape';

interface Slide {
  id: string;
  titlePart1: string;
  titlePart2: string;
  titlePart3: string;
  titlePart4: string;
  subtitle: string;
  tag: string;
  shapeOpacity: number;
  animatedShapeConfig: {
    position: string;
    right: string;
    transform: string;
    bottom: string;
  };
}

const HeroSection: FC<HeroSectionProps> = ({
  heroData,
  collectionData,
  shouldAvoidMovementAnimations,
}) => {
  const slidesRef: MutableRefObject<Slide[]> = useRef([
    {
      id: '01',
      titlePart1: heroData.titlePart1,
      titlePart2: heroData.titlePart2,
      titlePart3: heroData.titlePart3,
      titlePart4: heroData.titlePart4,
      subtitle: heroData.subtitle,
      tag: heroData.tag,
      shapeOpacity: 1,
      animatedShapeConfig: {
        position: 'absolute',
        bottom: '-173%',
        right: '-47%',
        transform: 'rotate(0deg)',
      },
    },
    {
      id: '02',
      titlePart1: collectionData.titlePart1,
      titlePart2: collectionData.titlePart2,
      titlePart3: collectionData.titlePart3,
      titlePart4: collectionData.titlePart4,
      subtitle: collectionData.subtitle,
      tag: collectionData.tag,
      shapeOpacity: 0,
      animatedShapeConfig: {
        position: 'absolute',
        right: '-61%',
        transform: 'rotate(-95deg)',
        bottom: '43%',
      },
    },
  ]);
  const slides = slidesRef.current;
  const { t } = useTranslation('common');
  const firstLoadTl = useRef<GSAPTimeline>();
  const scrollTl = useRef<GSAPTimeline>();
  const sectionRef = useRef<HTMLElement>(null);
  const titleLine1Ref = useRef(null);
  const titleLine2Ref = useRef(null);
  const titleLine3Ref = useRef(null);
  const titlePart1Ref = useRef(null);
  const titlePart2Ref = useRef<HTMLDivElement>(null);
  const titlePart3Ref = useRef(null);
  const titlePart4Ref = useRef<HTMLDivElement>(null);
  const tagRef = useRef(null);
  const subtitleRef = useRef(null);
  const mainShapeRef = useRef(null);
  const animatedShapeRef = useRef<HTMLDivElement>(null);
  const leftSmallTextRef = useRef(null);
  // const ctaRef = useRef(null);
  const isOnScreen = useOnScreen(sectionRef);
  const dispatch = useAppDispatch();
  const isTablet = useTabletMediaQuery();
  const isLargeMobile = useLargeMobileQuery();
  const scrollDirection = useScrollDirection({ initialDirection: ScrollDirection.SCROLL_DOWN });
  const isLessThan600px = useMediaQuery({ maxWidth: '600px' });

  useEffect(() => {
    dispatch(setLateralTexts({ leftText: t('lateral_texts.hero'), rightText: '01' }));
  }, [dispatch, t]);

  const getSlideTimeline = (index: number): GSAPTimeline => {
    gsap.set('#lateralShape', { scale: isLessThan600px ? 0.7 : 1 });
    if (isLargeMobile) {
      return gsap
        .timeline({ paused: true })
        .swapText(subtitleRef.current, { text: slides[index].subtitle }, 0)
        .swapText(titlePart1Ref.current, { text: slides[index].titlePart1 }, 0)
        .swapText(titlePart2Ref.current, { text: slides[index].titlePart2 }, 0)
        .swapText(titlePart3Ref.current, { text: slides[index].titlePart3 }, 0)
        .swapText(titlePart4Ref.current, { text: slides[index].titlePart4 }, 0)
        .swapText(tagRef.current, { text: slides[index].tag }, 0)
        .to(mainShapeRef.current, { opacity: slides[index].shapeOpacity, duration: 2 }, 0);
      // .to(
      //   ctaRef.current,
      //   { opacity: slides[index].shapeOpacity, duration: 1, delay: index === 1 ? 0 : 1 },
      //   0,
      // );
    }
    gsap.set('#lateralShape', { scale: 1 });
    return gsap
      .timeline({ paused: true })
      .add('start')
      .to(mainShapeRef.current, { opacity: slides[index].shapeOpacity, duration: 1 }, 'start')
      .add('swapTexts')
      .swapText(subtitleRef.current, { text: slides[index].subtitle }, 'swapTexts')
      .swapText(titlePart1Ref.current, { text: slides[index].titlePart1 }, 'swapTexts')
      .swapText(titlePart2Ref.current, { text: slides[index].titlePart2 }, 'swapTexts')
      .swapText(titlePart3Ref.current, { text: slides[index].titlePart3 }, 'swapTexts')
      .swapText(titlePart4Ref.current, { text: slides[index].titlePart4 }, 'swapTexts')
      .swapText(tagRef.current, { text: slides[index].tag }, 'swapTexts');
    // .to(
    //   [ctaRef.current, leftSmallTextRef.current],
    //   { opacity: slides[index].shapeOpacity, duration: 1, delay: index === 1 ? 0 : 1 },
    //   'swapTexts',
    // );
  };

  const getFirstLoadTL = () => {
    gsap.set('#theGradient', { attr: { x1: -1000, x2: 0 } });
    gsap.set(animatedShapeRef.current, { css: slides[0].animatedShapeConfig });
    gsap.set(tagRef.current, { opacity: 0 });
    gsap.set(subtitleRef.current, { opacity: 0 });
    gsap.set(sectionRef.current, { opacity: 1 });

    if (isLargeMobile) {
      return gsap
        .timeline({ paused: false })
        .titleIn(titleLine1Ref.current)
        .titleIn(titleLine2Ref.current, 0.5)
        .titleIn(titleLine3Ref.current, 1)
        .fromTo(
          animatedShapeRef.current,
          { opacity: 0 },
          { opacity: 1, duration: 0.5, ease: 'shine' },
          0,
        )
        .to(
          '#theGradient',
          {
            duration: 2,
            attr: { x1: 1500, x2: 2000 },
            ease: 'shine',
            delay: 0.2,
          },
          0,
        )
        .to(tagRef.current, { opacity: 1, delay: 1 }, 0)
        .to(subtitleRef.current, { opacity: 1, delay: 1 }, 0);
    }
    return gsap
      .timeline({ paused: false })
      .titleIn(titleLine1Ref.current)
      .titleIn(titleLine2Ref.current, 0.5)
      .fromTo(
        animatedShapeRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: 'shine' },
        0,
      )
      .to(
        '#theGradient',
        {
          duration: 2,
          attr: { x1: 1500, x2: 2000 },
          ease: 'shine',
          delay: 0.2,
        },
        0,
      )
      .to(tagRef.current, { opacity: 1, delay: 1 }, 0)
      .to(subtitleRef.current, { opacity: 1, delay: 1 }, 0)
      .paragraphFadeIn(leftSmallTextRef.current, { delay: 0.3 }, 0);
  };

  useEffect(() => {
    firstLoadTl.current = getFirstLoadTL();

    const slide0Tl = getSlideTimeline(0);
    const slide1Tl = getSlideTimeline(1);

    scrollTl.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          pin: sectionRef.current,
          immediateRender: false,
          start: 'top top',
          end: '+=400%',
          scrub: 2,
          onUpdate: ({ direction, progress }) => {
            if (
              direction === 1 &&
              progress > 0.1 &&
              !slide1Tl.isActive() &&
              titlePart2Ref.current?.innerText !== slides[1].titlePart2
            ) {
              slide0Tl.kill();
              slide1Tl.restart();
              dispatch(
                setLateralTexts({
                  leftText: 'lateral_texts.collection',
                  rightText: '02',
                }),
              );
            }
            if (
              direction === -1 &&
              progress < 0.1 &&
              !slide0Tl.isActive()
              // word2Ref.current?.innerText !== slides[0].titleWord2
            ) {
              slide0Tl.restart();
              dispatch(
                setLateralTexts({
                  leftText: 'lateral_texts.hero',
                  rightText: '01',
                }),
              );
            }
          },
        },
      })
      .to(animatedShapeRef.current, { css: slides[1].animatedShapeConfig }, 0);

    const transitionOutTween =
      !shouldAvoidMovementAnimations &&
      gsap.to(sectionRef.current, {
        autoAlpha: 0,
        yPercent: -100,
        duration: 2,
        scrollTrigger: {
          trigger: sectionRef.current,
          start: '+=10% top',
          scrub: 2,
          toggleActions: 'restart pause revert pause',
        },
      });

    return () => {
      firstLoadTl.current?.kill();
      scrollTl.current?.kill();
      slide0Tl.kill();
      slide1Tl.kill();
      transitionOutTween && transitionOutTween.kill();
    };
  }, []);

  useEffect(() => {
    isOnScreen &&
      scrollDirection === ScrollDirection.SCROLL_UP &&
      dispatch(setLateralTexts({ leftText: 'lateral_texts.collection', rightText: '02' }));
  }, [isOnScreen, dispatch, scrollDirection]);

  return (
    <section
      id="start"
      data-section-id="#start"
      ref={sectionRef}
      className={classnames(styles.section, 'heroSection')}>
      <Parallax parentRef={sectionRef} />
      <div ref={mainShapeRef} className={styles.mainShape}>
        <SVGMainShape />
      </div>
      <div ref={animatedShapeRef}>
        <AnimatedShape />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tag}>
          <Tag ref={tagRef} mode="dark" text={heroData.tag} />
        </div>
        {!isLargeMobile ? (
          <div className={styles.textWrapper}>
            <div ref={titleLine1Ref} className={styles.titleLine}>
              {!isTablet ? (
                <div className={styles.titleAppendix}>
                  <p ref={leftSmallTextRef}>{heroData.prefix}</p>
                </div>
              ) : null}
              <div
                ref={titlePart1Ref}
                className={classnames(styles.titlePrimary, styles.firstWord)}>
                {slides[0].titlePart1}
              </div>
              <div className={styles.titlePrimary}>
                <span ref={titlePart2Ref}>{slides[0].titlePart2} </span>
              </div>
            </div>
            <div ref={titleLine2Ref} className={styles.titleLine}>
              <div className={styles.titlePrimary}>
                <span ref={titlePart3Ref} className={styles.firstWord}>
                  {heroData.titlePart3}
                </span>
                <span ref={titlePart4Ref}>{heroData.titlePart4}</span>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.textWrapper}>
            {/* FIRST LINE */}
            <div ref={titleLine1Ref} className={styles.titleLine}>
              <div ref={titlePart1Ref} className={styles.titlePrimary}>
                {slides[0].titlePart1}
              </div>
            </div>
            {/* SECOND LINE*/}
            <div ref={titleLine2Ref} className={styles.titleLine}>
              <div
                ref={titlePart2Ref}
                className={classnames(styles.titlePrimary, styles.firstWordSecondLineMobile)}>
                {slides[0].titlePart2}
              </div>
              <div className={styles.marginSeparator}>&nbsp;</div>
              <div ref={titlePart3Ref} className={styles.titlePrimary}>
                {heroData.titlePart3}
              </div>
            </div>
            {/* THIRD LINE*/}
            <div ref={titleLine3Ref} className={styles.titleLine}>
              <div className={styles.titlePrimary} ref={titlePart4Ref}>
                {heroData.titlePart4}
              </div>
            </div>
          </div>
        )}
        <h2 ref={subtitleRef} className={styles.subtitle}>
          {heroData.subtitle}
        </h2>
        {/* <div ref={ctaRef}>
          <Button variant="secondary" delay={3}>
            {t('learn_more')}
          </Button>
        </div> */}
      </div>
    </section>
  );
};

export default HeroSection;
