import { BreakPoints } from '@types';
import { useMediaQuery } from 'react-responsive';
const useMobileMediaQuery = () => useMediaQuery({ maxWidth: BreakPoints.mobile });
const useLargeMobileQuery = () => useMediaQuery({ maxWidth: BreakPoints.largeMobile });
const useTabletMediaQuery = () => useMediaQuery({ maxWidth: BreakPoints.tablet });
const useDesktopMediaQuery = () => useMediaQuery({ maxWidth: BreakPoints.desktop });
const useLargeDesktopMediaQuery = () => useMediaQuery({ maxWidth: BreakPoints.lDesktop });
const useXlDesktopMediaQuery = () => useMediaQuery({ maxWidth: BreakPoints.xlDesktop });

export {
  useMobileMediaQuery,
  useLargeMobileQuery,
  useTabletMediaQuery,
  useDesktopMediaQuery,
  useLargeDesktopMediaQuery,
  useXlDesktopMediaQuery,
};
