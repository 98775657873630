import Image from 'next/image';
import { SVGProps } from 'react';

const AnimatedShape = ({ width = '2043px', height = '2150px' }: SVGProps<SVGElement>) => {
  return (
    <Image src="/svgs/animated-shape.svg" alt="Animated Shape" width={width} height={height} />
  );
};

export default AnimatedShape;
