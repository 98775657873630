import { useLargeMobileQuery } from '@hooks';
import Image from 'next/image';
import styles from './private-bg.module.scss';

const PrivateBg = () => {
  const isLargeMobile = useLargeMobileQuery();
  return (
    <div className={styles.background}>
      <Image
        src={isLargeMobile ? '/images/private-bg-mobile.png' : '/images/private-bg.png'}
        alt="background image"
        layout="fill"
        objectFit="cover"
        priority
      />
    </div>
  );
};

export default PrivateBg;
