import { SerializedPortableText, Tag } from '@components';
import { SVGArrowDown, SVGLineShape } from '@components/svgs';
import { useAppDispatch, useArrayRef, useOnScreen } from '@hooks';
import { FAQ } from '@models';
import { setLateralTexts } from '@store/dom-slice';
import { FAQSSectionProps } from '@types';
import { stripUndefined } from '@utils/utils';
import { gsap } from 'gsap';
import { DrawSVGPlugin } from 'gsap/dist/DrawSVGPlugin';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useCallback, useEffect, useRef, useState } from 'react';
import SVGBigShape from './components/svg-big-shape';
import SVGBottomShape from './components/svg-bottom-shape';
import SVGMediumShape from './components/svg-medium-shape';
import SVGSmallShape from './components/svg-small-shape';
import styles from './faqs-section.module.scss';

const FAQsSection: React.FC<FAQSSectionProps> = ({
  isMobile,
  isTablet,
  shouldAvoidMovementAnimations,
  faqsSectionData,
}) => {
  const sectionRef = useRef(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const subtitleRef = useRef<HTMLDivElement>(null);
  const tagRef = useRef<HTMLDivElement>(null);
  const bottomShapeRef = useRef<HTMLDivElement>(null);
  const topBigShapeRef = useRef<HTMLDivElement>(null);
  const topMediumShapeRef = useRef<HTMLDivElement>(null);
  const topSmallShapeRef = useRef<HTMLDivElement>(null);
  const { refs: lineShapeRefs, setRef: setLineShapeRef } = useArrayRef<SVGPathElement>();
  const { refs: questionTextRefs, setRef: setQuestionTextRef } = useArrayRef<HTMLDivElement>();
  const { refs: answerRefs, setRef: setAnswerRef } = useArrayRef<HTMLDivElement>();
  const { refs: arrowDownRefs, setRef: setArrowDownRef } = useArrayRef<HTMLDivElement>();
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null);
  const [answerTls, setAnswerTl] = useState<GSAPTimeline[]>([]);
  const isOnScreen = useOnScreen(sectionRef);
  const dispatch = useAppDispatch();

  const getAnswerTl = useCallback(
    (index: number): GSAPTimeline =>
      gsap
        .timeline({ paused: true })
        .paragraphFadeIn(answerRefs.current[index], { duration: 0.3 }, 0)
        .to(answerRefs.current[index], { height: 'auto', marginTop: '1%', duration: 0.3 }, 0)
        .to(
          arrowDownRefs.current[index],
          { css: { transform: 'rotate(160deg)' }, duration: 0.3 },
          0,
        ),
    [answerRefs, arrowDownRefs],
  );

  const selectQuestion = (index: number): void => {
    if (index === selectedQuestion) {
      answerTls[selectedQuestion]?.reverse().duration(0.3);
      setSelectedQuestion(null);
    } else {
      selectedQuestion !== null && answerTls[selectedQuestion]?.reverse().duration(0.3);
      setSelectedQuestion(index);
      answerTls[index]?.play();
    }
  };

  const flatten = useCallback(
    (arr: any[]): any[] =>
      stripUndefined(arr).reduce(
        (a: any, b: any) => a.concat(Array.isArray(b) ? flatten(stripUndefined(b)) : b),
        [],
      ),
    [],
  );

  useEffect(() => {
    gsap.registerPlugin(DrawSVGPlugin);
    gsap.set(lineShapeRefs.current, { drawSVG: '0%' });
    setAnswerTl(faqsSectionData.faqs.map((_, index) => getAnswerTl(index)));

    const inTimeline = gsap
      .timeline({
        paused: true,
      })
      .titleIn(titleRef.current, 0)
      .paragraphFadeIn(
        [
          subtitleRef.current,
          tagRef.current,
          flatten(
            questionTextRefs.current.map((questionTextRef) =>
              questionTextRef?.querySelectorAll('div'),
            ),
          ),
        ],
        0,
      )
      .to(
        lineShapeRefs.current,
        {
          drawSVG: '100%',
          duration: 2,
        },
        0,
      )
      .from(bottomShapeRef.current, { opacity: 0, yPercent: 200, duration: 1.5 }, 0)
      .from(arrowDownRefs.current, { opacity: 0, duration: 1 }, 0)
      .from(
        topBigShapeRef.current,
        !shouldAvoidMovementAnimations
          ? { autoAlpha: 0, yPercent: 500, duration: 1.5 }
          : { autoAlpha: 0, duration: 1.5 },
        0,
      )
      .from(
        topMediumShapeRef.current,
        !shouldAvoidMovementAnimations
          ? { autoAlpha: 0, yPercent: 350, duration: 1 }
          : { autoAlpha: 0, duration: 1.5 },
        0,
      )
      .from(
        topSmallShapeRef.current,
        !shouldAvoidMovementAnimations
          ? { autoAlpha: 0, yPercent: 250, duration: 1.5 }
          : { autoAlpha: 0, duration: 1.5 },
        0,
      );

    ScrollTrigger.create({
      trigger: sectionRef.current,
      start: `${isMobile || isTablet ? '-=60%' : '-=25%'} top`,
      once: true,
      onEnter: () => inTimeline.play().duration(1.5),
    });

    return () => {
      inTimeline.kill();
    };
  }, [
    questionTextRefs,
    lineShapeRefs,
    faqsSectionData?.faqs,
    getAnswerTl,
    flatten,
    arrowDownRefs,
    isMobile,
    isTablet,
    shouldAvoidMovementAnimations,
  ]);
  useEffect(() => {
    isOnScreen && dispatch(setLateralTexts({ leftText: 'lateral_texts.faqs', rightText: '07' }));
    if (!isOnScreen && selectedQuestion !== null) {
      answerTls[selectedQuestion]?.reverse().duration(0.5);
      setSelectedQuestion(null);
    }
  }, [isOnScreen, dispatch, selectedQuestion, answerTls]);

  const renderFAQ = (FAQ: FAQ, index: number) => (
    <div key={index} className={styles.faq} onClick={() => selectQuestion(index)}>
      <div className={styles.faqQuestion}>
        <div ref={(ref) => ref && setQuestionTextRef(ref)} className={styles.faqQuestionText}>
          <div className={styles.faqIndex}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</div>
          <div>{FAQ.question}</div>
        </div>
        <div ref={(ref) => ref && setArrowDownRef(ref)}>
          <SVGArrowDown />
        </div>
      </div>
      <div className={styles.faqDivider}>
        <SVGLineShape ref={(ref) => ref && setLineShapeRef(ref)} />
      </div>
      <div ref={(ref) => ref && setAnswerRef(ref)} id="answer" className={styles.faqAnswer}>
        <SerializedPortableText value={FAQ.answer} />
      </div>
    </div>
  );

  return (
    <section id="faqs" data-section-id="#faqs" ref={sectionRef} className={styles.section}>
      <div ref={topBigShapeRef} className={styles.topShapeBig}>
        <SVGBigShape />
      </div>
      <div ref={topMediumShapeRef} className={styles.topShapeMedium}>
        <SVGMediumShape />
      </div>
      <div ref={topSmallShapeRef} className={styles.topShapeSmall}>
        <SVGSmallShape />
      </div>
      <div ref={titleRef} className={styles.title}>
        <div ref={tagRef} className={styles.tag}>
          <Tag text={faqsSectionData.tag} mode="light" />
        </div>
        {faqsSectionData.titlePart1}
        <div className={styles.titleDivider}>-</div>
        <span>{faqsSectionData.titlePart2}</span>
      </div>
      <p ref={subtitleRef} className={styles.subtitle}>
        {faqsSectionData.subtitle}
      </p>
      <div className={styles.faqsContainer}>{faqsSectionData?.faqs?.map(renderFAQ)}</div>
      <div ref={bottomShapeRef} className={styles.bottomShape}>
        <SVGBottomShape />
      </div>
    </section>
  );
};

export default FAQsSection;
