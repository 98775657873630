const Phase2Shape = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1793 1398"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M479.58 692.266C479.884 683.696 478.197 676.326 480.645 670.766C498.86 629.376 517.356 588.09 536.922 547.358C545.137 530.275 559.707 519.635 578.037 512.422C632.182 491.111 688.878 482.399 746.148 483.38C795.568 484.225 843.21 477.486 891.111 467.824C925.696 460.849 960.754 456.228 995.56 450.303C1026.23 445.088 1050.13 453.04 1071.2 478.189C1117.72 533.688 1165.56 587.868 1197.65 653.675C1207.95 674.79 1219.86 695.227 1232.27 715.213C1283.35 797.453 1285.93 882.618 1248.99 970.344C1242.48 985.791 1236.73 1001.7 1228.67 1016.32C1212.74 1045.27 1187.86 1052.75 1156.26 1040.81C1118.34 1026.49 1081.71 1009.48 1047.67 987.626C948.3 923.823 841.366 874.147 736.317 821.006C664.262 784.544 591.004 750.476 518.467 714.951C505.174 708.408 492.773 700.019 479.58 692.266Z"
        fill="#FFC93E"
      />
      <path
        opacity="0.7"
        d="M1478.63 611.812L1479.01 611.626L1478.63 611.812C1498.56 652.68 1521.61 692.23 1545.62 730.899C1644.34 889.84 1649.34 1054.42 1577.93 1223.98C1574.77 1231.49 1571.7 1239.04 1568.64 1246.6C1559.48 1269.16 1550.32 1291.72 1538.65 1312.88L1539.09 1313.13L1538.65 1312.89C1523.29 1340.8 1503.64 1358.32 1480.27 1366C1456.88 1373.68 1429.69 1371.55 1399.19 1360.02C1325.86 1332.33 1255.03 1299.44 1189.22 1257.19C1034.92 1158.12 871.199 1076.65 707.529 995.213C667.259 975.176 626.993 955.14 586.871 934.843C500.134 890.952 412.489 848.851 324.846 806.75C271.644 781.193 218.441 755.636 165.443 729.68C147.95 721.071 131.271 710.816 114.342 700.406C106.486 695.576 98.5763 690.713 90.5062 685.965C90.6851 680.497 90.4522 675.264 90.2337 670.351C90.1248 667.903 90.0194 665.534 89.9702 663.255C89.8198 656.285 90.1924 650.127 92.5131 644.856C127.75 564.791 163.526 484.93 201.373 406.144C217.191 373.248 245.255 352.734 280.641 338.81C385.313 297.611 494.924 280.767 605.654 282.664C701.306 284.298 793.507 271.256 886.189 252.56C929.247 243.877 972.674 237.081 1016.12 230.283C1040.17 226.519 1064.22 222.755 1088.23 218.669C1117.83 213.636 1144.13 214.963 1168.1 223.593C1192.07 232.223 1213.75 248.17 1234.09 272.442C1241.9 281.769 1249.74 291.075 1257.57 300.378C1339.93 398.188 1421.97 495.628 1478.63 611.812Z"
        stroke="#FFC93E"
      />
    </svg>
  );
};

export default Phase2Shape;
