import { Button } from '@components';
import { Logo120Years } from '@components/svgs';
import { useAppSelector, useGSAPSelector, useScrollDirection, useTabletMediaQuery } from '@hooks';
import { selectIsNavbarModalOpen } from '@store/dom-slice';
import { ScrollDirection } from '@types';
import classnames from 'classnames';
import { gsap } from 'gsap';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import HamburgerMenu from './hamburger-menu/hamburger-menu';
import LanguageSelector from './language-selector/language-selector';
import styles from './navbar.module.scss';

interface MenuItem {
  id: string;
  title: string;
}

const Navbar = () => {
  const scrollDirection = useScrollDirection({ initialDirection: ScrollDirection.SCROLL_DOWN });
  const [scrolledToTop, setScrolledToTop] = useState(true);
  const { t } = useTranslation('common');
  const { ref, q } = useGSAPSelector();
  const isTablet = useTabletMediaQuery();
  const isModalOpen = useAppSelector(selectIsNavbarModalOpen);
  const anchorsMenuItemsRef = useRef([
    { id: '#start', title: t('navbar_menu_items.start') },
    { id: '#projects', title: t('navbar_menu_items.projects') },
    { id: '#community', title: t('navbar_menu_items.community') },
    { id: '#benefits', title: t('navbar_menu_items.benefits') },
    { id: '#roadmap', title: t('navbar_menu_items.roadmap') },
    { id: '#faqs', title: t('navbar_menu_items.faqs') },
  ]);
  const anchorsMenuItems = anchorsMenuItemsRef.current;
  const keys = anchorsMenuItems.map((item) => item.id);
  const [anchorItemSelected, setAnchorItemSelected] = useState<typeof keys[number]>('#start');

  const handleScroll = () => {
    setScrolledToTop(window.pageYOffset < 50);
  };

  const scrollToTop = () => scrollTo({ left: 0, top: 0, behavior: 'smooth' });

  const handleScrollAnchorsMenu = (item: MenuItem) => {
    setAnchorItemSelected(item.id);
    switch (item.id) {
      case '#start':
        scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        break;
      default: {
        // I'M SORRY
        const sectionElement = document.querySelector(item.id) as HTMLElement;
        const sectionPosition = sectionElement?.getBoundingClientRect()?.top ?? 0;
        const projectElement = document.querySelector('#projects') as HTMLElement;
        const projectHeight = projectElement?.offsetHeight ?? 0;
        const projectPosition = projectElement?.getBoundingClientRect()?.top ?? 0;
        const benefitsElement = document.querySelector('#benefits') as HTMLElement;
        const benefitsHeight = benefitsElement?.offsetHeight ?? 0;
        const benefitsPosition = benefitsElement?.getBoundingClientRect()?.top ?? 0;
        const scrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
        const isProjectsBackwards = item.id === '#projects' && projectPosition < 0;
        const isPastProjectForward =
          sectionPosition > projectPosition && scrollPosition < projectPosition;
        const isPastBenefitsForward =
          sectionPosition > benefitsPosition && scrollPosition < benefitsPosition;
        const defaultOffsetY = 0;

        let targetId = item.id;
        let offsetY: number = defaultOffsetY;

        if (isPastProjectForward) {
          offsetY = projectHeight * -6;
          targetId = '#projects';
        } else if (['#benefits', '#roadmap'].includes(item.id) && sectionPosition < 0) {
          offsetY = -500;
        } else if (isProjectsBackwards) {
          offsetY = projectHeight * 5;
        }

        const scrollTl = gsap.timeline({ paused: true }).to(window, {
          duration: 2,
          scrollTo: {
            y: targetId,
            offsetY,
          },
          ease: 'power2',
        });

        if (isPastProjectForward)
          scrollTl.add(
            gsap.to(window, {
              duration: 1.5,
              scrollTo: {
                y: isPastBenefitsForward ? '#benefits' : item.id,
                offsetY: isPastBenefitsForward
                  ? benefitsHeight
                  : item.id === '#benefits' && benefitsPosition > 0
                  ? 500
                  : defaultOffsetY,
              },
              ease: 'power2',
            }),
          );

        if (isPastBenefitsForward) {
          scrollTl.add(
            gsap.to(window, {
              duration: 1,
              scrollTo: { y: item.id, offsetY: defaultOffsetY },
              ease: 'power2',
            }),
          );
        }
        scrollTl.play();
      }
    }
  };

  useEffect(() => {
    const sectionRefs = document.querySelectorAll(
      anchorsMenuItems.map((item) => item.id).join(','),
    );
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const dataSectionId = entry.target.getAttribute('data-section-id');
            setAnchorItemSelected(dataSectionId!);
          }
        });
      },
      {
        rootMargin: '-50% 0% -50% 0%',
        threshold: 0,
      },
    );
    sectionRefs.forEach((sectionRef) => observer.observe(sectionRef));

    return () => observer.disconnect();
  }, [anchorsMenuItems]);

  useLayoutEffect(() => {
    gsap.set(q('.navbarItem'), { opacity: 0 });
    gsap.to(q('.navbarItem'), {
      delay: 1.5,
      y: 0,
      opacity: 1,
      stagger: 0.05,
      duration: 0.5,
      ease: 'shine',
    });
  }, [q]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return !isTablet ? (
    <nav
      className={classnames(
        styles.navbar,
        scrollDirection === ScrollDirection.SCROLL_UP && !scrolledToTop && styles.navbarScrolledUp,
        scrollDirection === ScrollDirection.SCROLL_DOWN &&
          !scrolledToTop &&
          styles.navbarScrolledDown,
      )}>
      <div ref={ref} className={styles.contentWrapper}>
        <div className={classnames('navbarItem', styles.logoMainContainer)} onClick={scrollToTop}>
          <Image src="/images/main-logo.png" alt="Yael X Manor Logo" width={161} height={40} />
        </div>
        {/* <div className={classnames('navbarItem', styles.anchorsMenu)}>
          {anchorsMenuItems.map((item) => (
            <div
              onClick={() => handleScrollAnchorsMenu(item)}
              key={item.id}
              className={classnames(
                styles.anchorsMenuItem,
                item.id === anchorItemSelected && styles.selected,
              )}>
              {item.title}
            </div>
          ))}
        </div> */}
        <div className={classnames(styles.languageSelector, 'navbarItem')}>
          <LanguageSelector />
        </div>
        <div className="navbarItem">
          <Link href="/member-login">
            <a>
              <Button delay={0}>{t('member_login')}</Button>
            </a>
          </Link>
        </div>
      </div>
    </nav>
  ) : (
    <nav
      className={classnames(
        styles.navbar,
        scrollDirection === ScrollDirection.SCROLL_UP && !scrolledToTop && styles.navbarScrolledUp,
        scrollDirection === ScrollDirection.SCROLL_DOWN &&
          !scrolledToTop &&
          styles.navbarScrolledDown,
      )}>
      <div ref={ref} className={styles.contentWrapper}>
        <div className={classnames(styles.logo120Container, 'navbarItem')}>
          {isModalOpen ? <LanguageSelector /> : <Logo120Years height={'100%'} width={'100%'} />}
        </div>
        <div className={classnames(styles.logoMainContainer, 'navbarItem')} onClick={scrollToTop}>
          <Image src="/images/main-logo.png" alt="Yael X Manor Logo" width={161} height={40} />
        </div>
        <div className="navbarItem">
          <HamburgerMenu />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
