import { Button } from '@components';
import { useAppDispatch, useAppSelector } from '@hooks';
import { selectIsNavbarModalOpen, setIsNavbarModalOpen } from '@store/dom-slice';
import { scrollTo } from '@utils/utils';
import { gsap } from 'gsap';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useEffect, useRef } from 'react';
import styles from './navbar-modal.module.scss';

interface MenuItem {
  id: string;
  title: string;
}

const NavbarModal = () => {
  const modalAnimation = useRef<GSAPTimeline>();
  const modalRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef(null);
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsNavbarModalOpen);
  const { t } = useTranslation('common');
  const menuItemsRef = useRef([
    { id: '#start', title: t('navbar_menu_items.start') },
    { id: '#projects', title: t('navbar_menu_items.projects') },
    { id: '#community', title: t('navbar_menu_items.community') },
    { id: '#benefits', title: t('navbar_menu_items.benefits') },
    { id: '#roadmap', title: t('navbar_menu_items.roadmap') },
    { id: '#faqs', title: t('navbar_menu_items.faqs') },
  ]);
  const menuItems = menuItemsRef.current;

  const handleOnClick = () => {
    gsap.delayedCall(modalAnimation.current!.duration(), () =>
      dispatch(setIsNavbarModalOpen(false)),
    );
  };

  const handleOnClickSection = (item: MenuItem) => {
    scrollTo(item.id, 'down');
    dispatch(setIsNavbarModalOpen(false));
  };

  useEffect(() => {
    modalAnimation.current = gsap
      .timeline({ paused: true })
      .to(modalRef.current, { autoAlpha: 1, duration: 0 })
      .fromTo(modalRef.current, { xPercent: -100 }, { xPercent: 0, ease: 'shine' })
      .to([titleRef.current], { autoAlpha: 1, duration: 0.75 }, '>-15%');
  }, []);

  useEffect(() => {
    isOpen
      ? modalAnimation.current?.timeScale(1.5).play()
      : modalAnimation.current?.timeScale(2).reverse();
    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'visible');
  }, [isOpen]);

  return (
    <div ref={modalRef} className={styles.modalWrapper}>
      <div className={styles.innerWrapper}>
        <div ref={titleRef} className={styles.listWrapper}>
          {menuItems.map((item, index) => (
            <div key={index} onClick={() => handleOnClickSection(item)} className={styles.listItem}>
              {item.title}
            </div>
          ))}

          {/* {items.map((item) => (
            <Link href={router.asPath} locale={item.locale} key={item.id}>
              <a onClick={handleOnClick} className={styles.listItem}>
                {item.value}
              </a>
            </Link>
          ))} */}
        </div>
        <div className={styles.cta}>
          <Link href="/member-login">
            <a>
              <Button delay={0} onClick={() => dispatch(setIsNavbarModalOpen(false))}>
                {t('member_login')}
              </Button>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavbarModal;
