import { Button } from '@components';
import { User } from '@models';
import { RequiredValidation } from '@utils/form-validations';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import FormErrorMessage from '../form-error-message/form-error-message';
import styles from './profile-form.module.scss';

interface Profile {
  name: string;
  surname: string;
  email: string;
}

interface ProfileFormProps {
  disabled?: boolean;
  profile?: Partial<User> | null;
  onSubmit: (profile: Profile) => void;
}

const ProfileForm: FC<ProfileFormProps> = ({ disabled = false, profile, onSubmit }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Profile>({
    defaultValues: useMemo(() => {
      return {
        name: profile?.name,
        surname: profile?.surname,
      };
    }, [profile?.name, profile?.surname]),
  });

  useEffect(() => {
    reset({
      name: profile?.name,
      surname: profile?.surname,
    });
  }, [profile?.name, profile?.surname, reset]);

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      {/* <div className={styles.row}>
        <label className={styles.label}>name@domain.com</label>
        <Button delay={0}>CHANGE EMAIL</Button>
      </div> */}
      <div className={styles.row}>
        <div className={styles.input}>
          <input
            {...register('name', { required: new RequiredValidation() })}
            type="text"
            id="name"
            name={t('name')}
            placeholder="Name"
            defaultValue={profile?.name}
          />
          <FormErrorMessage errors={errors} name="name" />
        </div>
        <div className={styles.input}>
          <input
            {...register('surname', { required: new RequiredValidation() })}
            type="text"
            id="surname"
            name="surname"
            placeholder={t('surname')}
            defaultValue={profile?.surname}
          />
          <FormErrorMessage errors={errors} name="surname" />
        </div>

        <Button variant="privatePrimary" isLoading={disabled} disabled={disabled} delay={0}>
          {t('save')}
        </Button>
      </div>
    </form>
  );
};

export default ProfileForm;
