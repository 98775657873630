import { Network } from '@ethersproject/networks';
import { SafeEventEmitterProvider } from '@web3auth/base';
import { ethers, providers } from 'ethers';

export const ethProvider = (provider: providers.Web3Provider): WalletProvider => {
  const getAccounts = async (): Promise<string[]> => provider.listAccounts();

  const getBalance = async (): Promise<ethers.BigNumber> => {
    const accounts = await getAccounts();
    return provider.getBalance(accounts[0]);
  };

  const signMessage = async (message: string): Promise<any> => {
    const accounts = await getAccounts();
    (provider.provider as any)?.send(
      {
        method: 'eth_sign',
        params: [accounts[0], message],
        from: accounts[0],
      },
      (err: Error, result: any) => {
        if (err) throw err;
        return result;
      },
    );
  };

  const signPersonalMessage = async (message: string): Promise<string> => {
    const signer = provider.getSigner();
    const signature = await signer.signMessage(message);
    return signature;
  };

  const signTransaction = async (): Promise<string> => {
    const accounts = await provider.listAccounts();
    const signer = provider.getSigner();
    return signer.signTransaction({
      from: accounts[0],
      to: accounts[0],
      value: ethers.utils.formatUnits('0.01', 'gwei'),
    });
  };

  const signAndSendTransaction = async (): Promise<string> => {
    const accounts = await provider.listAccounts();
    const signer = provider.getSigner();
    const txRes = await signer.sendTransaction({
      from: accounts[0],
      to: accounts[0],
      value: ethers.utils.formatUnits('0.01', 'gwei'),
    });
    return txRes.hash;
  };

  const getChain = (): Promise<Network> => provider.getNetwork();

  return {
    getAccounts,
    getBalance,
    signMessage,
    signPersonalMessage,
    signAndSendTransaction,
    signTransaction,
    getChain,
  };
};

export interface WalletProvider {
  getAccounts: () => Promise<string[]>;
  getBalance: () => Promise<ethers.BigNumber>;
  signAndSendTransaction: () => Promise<string>;
  signTransaction: () => Promise<string>;
  signMessage: (message: string) => Promise<any>;
  signPersonalMessage: (message: string) => Promise<string>;
  getChain: () => Promise<Network>;
}

export const getWalletProvider = (
  chain: string,
  provider: SafeEventEmitterProvider,
): WalletProvider => ethProvider(new ethers.providers.Web3Provider(provider));
