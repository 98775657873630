import { FC } from 'react';
import { SVGExtendedProps } from '@types';

const SVGMainShape1: FC<SVGExtendedProps> = ({
  width = '1141',
  height = '838',
  isMobile = false,
}) => {
  return !isMobile ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1141 838"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.2"
        d="M791.422 720.425C804.186 718.97 816.794 716.398 829.101 712.739C856.032 704.744 857.786 704.939 890.939 684.914C946.236 651.492 999.539 610.644 1043.42 563.445C1135.29 464.638 1084.98 361.635 989.134 300.834C909.757 250.479 820.909 225.911 733.065 198.665C589.765 154.221 443.192 129.984 292.428 131.486C257.409 131.869 222.392 130.601 186.861 128.882C131.98 126.206 93.08 181.214 113.78 232.033C127.885 266.73 142.752 300.335 160.994 332.032C195.008 391.067 241.256 443.116 283.593 497.963C285.746 500.754 432.898 656.071 537.039 688.013C593.04 705.188 653.309 708.368 711.554 718.424C717.295 719.333 722.979 720.57 728.578 722.131C737.699 724.688 747.226 725.456 756.632 724.392C766.599 723.225 780.25 721.666 791.422 720.425Z"
        fill="#CCC9F4"
      />
      <path
        opacity="0.2"
        d="M241.325 535.432C254.312 534.688 262.671 530.216 270.971 526.105C289.361 517.012 303.099 502.762 311.902 484.658C316.356 475.507 318.346 465.017 320.365 454.919C322.903 442.266 324.313 429.361 326.247 416.592C326.956 411.902 325.98 410.076 321.643 409.03C306.546 405.358 291.528 401.276 276.291 398.276C260.293 395.125 244.128 392.646 227.931 390.618C215.741 389.087 203.601 390.409 192.681 396.627C188.658 398.917 185.143 402.235 181.766 405.463C171.203 415.566 165.288 428.479 163.478 442.395C161.696 455.799 162.8 469.429 166.715 482.373C171.28 497.513 179.512 509.823 190.971 520.079C200.1 528.253 211.508 532.87 223.533 535.006C230.396 536.231 237.611 535.391 241.325 535.432Z"
        fill="#CCC9F4"
      />
    </svg>
  ) : (
    <svg
      width="762"
      height="560"
      viewBox="0 0 762 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.2"
        d="M528.471 481.063C536.994 480.091 545.413 478.374 553.631 475.931C571.614 470.592 572.785 470.722 594.923 457.35C631.848 435.033 667.441 407.757 696.741 376.239C758.087 310.261 724.495 241.482 660.493 200.881C607.489 167.257 548.161 150.852 489.504 132.658C393.815 102.981 295.941 86.7968 195.268 87.7999C171.885 88.0552 148.502 87.2087 124.776 86.0607C88.1297 84.2738 62.1544 121.006 75.9766 154.94C85.3951 178.109 95.3225 200.548 107.504 221.714C130.216 261.134 161.099 295.89 189.369 332.514C190.807 334.378 289.067 438.09 358.608 459.42C396.002 470.888 436.246 473.012 475.14 479.727C478.973 480.333 482.769 481.16 486.507 482.202C492.597 483.909 498.959 484.422 505.24 483.712C511.895 482.933 521.011 481.892 528.471 481.063Z"
        fill="#CCC9F4"
      />
      <path
        opacity="0.2"
        d="M241.052 472.891C248.276 472.478 252.926 469.99 257.543 467.704C267.773 462.645 275.415 454.718 280.312 444.648C282.789 439.557 283.896 433.722 285.02 428.105C286.431 421.066 287.216 413.888 288.292 406.785C288.686 404.176 288.143 403.16 285.731 402.578C277.333 400.536 268.979 398.265 260.503 396.596C251.604 394.843 242.611 393.464 233.601 392.336C226.821 391.484 220.068 392.22 213.993 395.679C211.755 396.952 209.8 398.798 207.921 400.594C202.046 406.214 198.755 413.397 197.748 421.138C196.757 428.594 197.371 436.176 199.549 443.376C202.088 451.798 206.667 458.646 213.042 464.351C218.12 468.898 224.466 471.467 231.155 472.655C234.973 473.336 238.986 472.869 241.052 472.891Z"
        fill="#CCC9F4"
      />
    </svg>
  );
};

export default SVGMainShape1;
