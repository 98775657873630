import { i18n } from 'next-i18next';

// CLASSES
export abstract class FormValidation {
  protected abstract value: boolean | string | number | RegExp;
  message: string;

  constructor(message: string) {
    this.message = message;
  }
}

export class RequiredValidation extends FormValidation {
  value: boolean;

  constructor() {
    super(i18n!.t('required'));
    this.value = true;
  }
}

export class MaxLengthValidation extends FormValidation {
  value: number;

  constructor(value: number) {
    super(i18n!.t('max_length_exceeded', { value }));
    this.value = value;
  }
}

export class MinLengthValidation extends FormValidation {
  value: number;

  constructor(value: number) {
    super(i18n!.t('min_length_required', { value }));
    this.value = value;
  }
}

export class EmailValidation extends FormValidation {
  value = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  constructor() {
    super(i18n!.t('invalid_email'));
  }
}
