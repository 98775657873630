import classnames from 'classnames';
import styles from './spinner.module.scss';

interface SpinnerProps {
  variant: 'primary' | 'secondary';
}

const Spinner = ({ variant }: SpinnerProps) => {
  return (
    <div
      className={classnames(
        styles.spinner,
        variant === 'primary' && styles.primary,
        variant === 'secondary' && styles.secondary,
      )}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;
