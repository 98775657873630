import { gsap } from 'gsap';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';
import SVGSSecondaryArrowShape from '../button/svgs/svg-secondary-arrow-shape';
import styles from './go-back-button.module.scss';

interface GoBackButtonProps {
  onClick?: () => void;
}

const GoBackButton = ({ onClick }: GoBackButtonProps) => {
  const { t } = useTranslation('common');
  const hoverAnimation = useRef<GSAPTimeline>();
  const arrowRef = useRef(null);

  const onMouseEnter = () => hoverAnimation.current?.play();
  const onMouseLeave = () => hoverAnimation.current?.restart().pause();

  useEffect(() => {
    hoverAnimation.current = gsap
      .timeline({ paused: true, repeat: -1, yoyo: true })
      .to(arrowRef.current, { x: 5 });
  }, []);

  return (
    <button
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={styles.button}>
      <div ref={arrowRef} className={styles.arrowShape}>
        <SVGSSecondaryArrowShape />
      </div>
      <div>{t('go_back')}</div>
    </button>
  );
};

export default GoBackButton;
