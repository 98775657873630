import OverlayGradient from './overlay-gradient/overlay-gradient';
import OverlayGrain from './overlay-grain/overlay-grain';
import styles from './overlay.module.scss';

const Overlay = () => {
  return (
    <div className={styles.overlay}>
      <OverlayGrain />
      <OverlayGradient />
    </div>
  );
};

export default Overlay;
