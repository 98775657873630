import React from 'react';
import { PortableText, PortableTextProps, PortableTextReactComponents } from '@portabletext/react';
import styles from './serialized-portable-text.module.scss';

const components: Partial<PortableTextReactComponents> = {
  marks: {
    internalLink: ({ children, value }) => {
      const { slug = {} } = value;
      const href = `/${slug.current}`;
      return (
        <a className={styles.link} href={href}>
          {children}
        </a>
      );
    },
    link: ({ children, value }) => {
      const { blank, href } = value;
      return blank ? (
        <a className={styles.link} href={href} target="_blank" rel="noreferrer">
          {children}
        </a>
      ) : (
        <a className={styles.link} href={href}>
          {children}
        </a>
      );
    },
  },
};

const SerializedPortableText = ({ value }: PortableTextProps) => (
  <PortableText value={value} components={components} />
);

export default SerializedPortableText;
