import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@types';

interface AuthState {
  token: string | null;
  shouldAutoLogin: boolean;
}

const initialState: AuthState = {
  token: null,
  shouldAutoLogin: true,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state: AuthState, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setShouldAutoLogin: (state: AuthState, { payload }: PayloadAction<boolean>) => {
      state.shouldAutoLogin = payload;
    },
    resetAuthState: (state: AuthState) => ({
      ...initialState,
      shouldAutoLogin: state.shouldAutoLogin,
    }),
  },
});

export const selectIsAuthenticated = (state: RootState) => !!state.auth.token;
export const selectShouldAutoLogin = (state: RootState) => state.auth.shouldAutoLogin;

export const { setToken, setShouldAutoLogin, resetAuthState } = authSlice.actions;

export default authSlice.reducer;
