import Image from 'next/image';
import styles from './overlay-grain.module.scss';

const OverlayGrain = () => {
  return (
    <div className={styles.mask}>
      <Image
        src="/svgs/grain-bg.svg"
        alt="texture"
        layout="fill"
        quality={100}
        objectFit={'cover'}
        priority
      />
    </div>
  );
};

export default OverlayGrain;
