import { ForwardedRef, forwardRef } from 'react';

interface SVGMaskedImageProps {
  id: string;
  maskedImage: string;
}

const SVGMaskedImage = forwardRef(
  ({ maskedImage, id }: SVGMaskedImageProps, ref: ForwardedRef<SVGSVGElement> | undefined) => {
    return (
      <svg
        ref={ref}
        width="492"
        height="541"
        viewBox="0 0 492 541"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <mask
          id={`mask${id}`}
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="492"
          height="541">
          <path
            d="M163.048 538.224C170.626 539.353 176.734 541.571 182.126 540.864C222.275 535.602 262.396 530.151 302.281 523.919C319.013 521.307 331.416 513.623 341.622 503.015C371.772 471.665 391.411 436.733 402.676 399.946C412.399 368.193 428.394 338.753 447.008 309.599C460.447 288.549 471.92 266.833 484.48 245.48C495.549 226.663 493.637 210.149 476.044 192.778C437.22 154.448 399.843 115.475 348.928 84.7786C332.589 74.9275 317.194 64.1466 302.295 53.1208C240.963 7.73866 166.856 -7.06972 82.1085 3.02176C67.1832 4.79514 52.0158 6.01971 37.4838 8.90883C8.72744 14.6281 -3.09673 29.3911 0.684899 51.4609C5.22013 77.9255 12.3772 103.991 24.3351 129.145C59.2466 202.575 80.1697 278.667 104.533 354.078C121.245 405.81 135.612 457.946 151.41 509.836C154.308 519.356 159.042 528.577 163.048 538.224Z"
            fill="#FDFDFD"
          />
        </mask>
        <g mask={`url(#mask${id})`}>
          <rect x="-135" y="-4" width="980" height="547" fill={`url(#pattern${id})`} />
        </g>
        <defs>
          <pattern id={`pattern${id}`} patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref={`#image${id}`} transform="scale(0.000290408 0.000520291)" />
          </pattern>
          <image id={`image${id}`} width="3444" height="1922" xlinkHref={maskedImage} />
        </defs>
      </svg>
    );
  },
);
SVGMaskedImage.displayName = 'SVGMaskedImage';

export default SVGMaskedImage;
