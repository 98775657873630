import { ForwardedRef, forwardRef } from 'react';
import { SVGExtendedProps } from '@types';

const SVGCenterShape = forwardRef(
  ({ color = '#E3FC5D' }: SVGExtendedProps, ref: ForwardedRef<SVGSVGElement> | undefined) => {
    return (
      <svg
        ref={ref}
        width="227"
        height="212"
        viewBox="0 0 227 212"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M83.9617 205.646C66.0124 200.029 55.8668 190.811 45.6775 182.121C23.0958 162.887 8.85359 138.055 2.89145 109.555C-0.127723 95.1471 0.779337 79.7294 1.50715 64.8506C2.40864 46.2033 4.97968 27.6009 6.76931 9.00481C7.42764 2.17435 9.43996 -0.0434164 15.8908 0.0163551C38.355 0.181322 60.8525 -0.258399 83.2772 0.898768C106.82 2.11219 130.363 4.32606 153.79 7.18487C171.422 9.32908 187.979 15.4599 201.101 28.0286C205.935 32.6583 209.693 38.5523 213.291 44.2701C224.543 62.1645 228.286 82.3647 225.918 102.527C223.691 121.961 217.339 140.696 207.287 157.477C195.548 177.112 179.666 191.484 159.983 201.839C144.299 210.092 126.676 212.553 109.06 211.315C99.0048 210.617 89.1836 206.897 83.9617 205.646Z"
          fill={color}
        />
      </svg>
    );
  },
);
SVGCenterShape.displayName = 'SVGCenterShape';

export default SVGCenterShape;
