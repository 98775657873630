import { Button, Tag } from '@components';
import { useTabletMediaQuery } from '@hooks';
import { gsap } from 'gsap';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef, useEffect, useRef } from 'react';
import styles from './welcome-state.module.scss';

interface WelcomeStateProps {
  onVerify(): void;
}

const WelcomeState = forwardRef(
  ({ onVerify }: WelcomeStateProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation('common');
    const firstRenderAnimation = useRef<GSAPTimeline>();
    const isTablet = useTabletMediaQuery();
    // **** References ****
    const titleLine1Ref = useRef(null);
    const titleLine2Ref = useRef(null);
    const titleLine3Ref = useRef(null);
    const titleLine4Ref = useRef(null);
    const tagRef = useRef(null);
    const subtitleRef = useRef(null);
    const ctaRef = useRef(null);
    // ********************

    useEffect(() => {
      firstRenderAnimation.current = isTablet
        ? gsap
            .timeline()
            .titleIn(titleLine1Ref.current)
            .titleIn(titleLine2Ref.current, 0.5)
            .titleIn(titleLine3Ref.current, 1)
            .titleIn(titleLine4Ref.current, 1.5)
            .fromTo(
              [tagRef.current, subtitleRef.current, ctaRef.current],
              { opacity: 0 },
              { opacity: 1, delay: 1, ease: 'none', stagger: 0.2 },
              0,
            )
        : gsap
            .timeline()
            .titleIn(titleLine1Ref.current)
            .titleIn(titleLine2Ref.current, 0.5)
            .fromTo(
              [tagRef.current, subtitleRef.current, ctaRef.current],
              { opacity: 0 },
              { opacity: 1, delay: 1, ease: 'none', stagger: 0.2 },
              0,
            );

      return () => {
        firstRenderAnimation.current?.kill();
      };
    }, []);

    return (
      <div ref={ref} className={styles.contentWrapper}>
        <div ref={tagRef} className={styles.tag}>
          <Tag mode={'light'} text={t('verify.get_started')} />
        </div>
        {isTablet ? (
          <div>
            <div ref={titleLine1Ref} className={styles.titleLine}>
              <h1 dangerouslySetInnerHTML={{ __html: t('verify.title1') }}></h1>
            </div>
            <div ref={titleLine2Ref} className={styles.titleLine}>
              <h1 dangerouslySetInnerHTML={{ __html: t('verify.title2') }}></h1>
            </div>
            <div ref={titleLine3Ref} className={styles.titleLine}>
              <h1 dangerouslySetInnerHTML={{ __html: t('verify.title3') }}></h1>
            </div>
            <div ref={titleLine4Ref} className={styles.titleLine}>
              <h1 dangerouslySetInnerHTML={{ __html: t('verify.title4') }}></h1>
            </div>
          </div>
        ) : (
          <div>
            <div ref={titleLine1Ref} className={styles.titleLine}>
              <h1 dangerouslySetInnerHTML={{ __html: t('verify.titleDesktop1') }}></h1>
            </div>
            <div ref={titleLine2Ref} className={styles.titleLine}>
              <h1 dangerouslySetInnerHTML={{ __html: t('verify.titleDesktop2') }}></h1>
            </div>
          </div>
        )}

        <div ref={subtitleRef} className={styles.subtitle}>
          <p>{t('verify.subtitle')}</p>
        </div>
        <div ref={ctaRef} className={styles.ctaButton}>
          <Button onClick={onVerify} variant="privateSecondary">
            {t('verify.cta')}
          </Button>
        </div>
      </div>
    );
  },
);
WelcomeState.displayName = 'WelcomeState';

export default WelcomeState;
