import { Button, SerializedPortableText } from '@components';
import { Event } from '@models';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import styles from './event-item.module.scss';

interface EventItemProps {
  item: Event;
}

const EventItem = ({ item }: EventItemProps) => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Image src={item.image} alt="Event image" layout="fill" objectFit="cover" />
      </div>
      <div className={styles.title}>{item.name}</div>
      <div className={styles.description}>
        <SerializedPortableText value={item.description} />
      </div>
      <div className={styles.cta}>
        <Link href={`/events/${item._id}`}>
          <a>
            <Button variant="tertiary">{item.isActive ? 'RSVP' : t('see_details')}</Button>
          </a>
        </Link>
      </div>
    </div>
  );
};

export default EventItem;
