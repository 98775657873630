import { Analytics, getAnalytics } from 'firebase/analytics';
import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import { Firestore, getFirestore } from 'firebase/firestore';

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.firebaseApiKey,
  authDomain: process.env.firebaseAuthDomain,
  projectId: process.env.firebaseProjectId,
  storageBucket: process.env.firebaseStorageBucket,
  messagingSenderId: process.env.firebaseMessagingSenderId,
  appId: process.env.firebaseAppId,
  measurementId: process.env.firebaseMeasurementId,
};

let firebaseApp: FirebaseApp;
let firebaseAuth: Auth;
let firestore: Firestore;
let analytics: Analytics;

const initialize = (): void => {
  firebaseApp = initializeApp(firebaseConfig);
  analytics = getAnalytics(firebaseApp);
};

const initializePrivate = (): void => {
  firebaseAuth = getAuth(firebaseApp);
  firestore = getFirestore(firebaseApp);
};

export { initialize, initializePrivate, firebaseAuth, firestore, analytics };
