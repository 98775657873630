import { forwardRef, ForwardedRef, SVGProps } from 'react';

const SVGLineShape = forwardRef(
  ({ width = 700 }: SVGProps<SVGElement>, ref: ForwardedRef<SVGPathElement> | undefined) => {
    return (
      <svg
        width={width}
        height="2"
        viewBox={`0 0 ${width} 2`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path ref={ref} d={`M0 1H${width}`} stroke="#27272A" />
      </svg>
    );
  },
);
SVGLineShape.displayName = 'SVGLineShape';

export default SVGLineShape;
