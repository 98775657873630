import { SVGExtendedProps } from '@types';
import Image from 'next/image';
import { FC } from 'react';

const SVGGraphicShape: FC<SVGExtendedProps> = ({ isMobile = false }) => {
  return !isMobile ? (
    <Image
      src="/svgs/graphic-shape-desktop.svg"
      alt="Community graph"
      width="685"
      height="652"
      layout="fixed"
    />
  ) : (
    <Image
      src="/svgs/graphic-shape-mobile.svg"
      alt="Community graph"
      width="455px"
      height="436px"
      layout="fixed"
    />
  );
};

export default SVGGraphicShape;
