import { ForwardedRef, forwardRef } from 'react';

const SVGArrowUp = forwardRef((props, ref: ForwardedRef<SVGSVGElement> | undefined) => {
  return (
    <svg
      ref={ref}
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 10.0409C1 7.281 2.70184 3.03858 4.46296 1.19595C6.13016 -0.548407 10.4195 9.89755 12 11"
        stroke="#27272A"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
});
SVGArrowUp.displayName = 'SVGArrayUp';

export default SVGArrowUp;
