import { Overlay } from '@components';
import { gsap } from 'gsap';
import Image from 'next/image';
import { useEffect, useRef } from 'react';
import styles from './loading-screen.module.scss';

const LoadingScreen = ({
  isVisible,
  showProgressBar = true,
}: {
  isVisible: boolean;
  showProgressBar?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const inTween = useRef<GSAPTween>();

  useEffect(() => {
    inTween.current = gsap.from(ref.current, { paused: true, autoAlpha: 0, duration: 3 });

    return () => {
      inTween.current?.kill();
    };
  }, []);

  useEffect(() => {
    isVisible && inTween.current?.play();
  }, [isVisible]);

  return (
    <div ref={ref} className={styles.screen}>
      <Overlay />
      <div className={styles.shape}>
        <Image src="/svgs/loading-screen-shape.svg" alt="Shape" width="502" height="633" />
      </div>
      <div className={styles.logo}>
        <Image
          src="/svgs/yael-x-manor-logo.svg"
          alt="Yael x Manor Logo"
          width={240}
          height={326}
          layout="responsive"
        />
      </div>
      {showProgressBar && (
        <div className="progress-bar">
          <div className="progress-bar-value"></div>
        </div>
      )}
    </div>
  );
};

export default LoadingScreen;
