import { ManorLogo, Navbar, Overlay } from '@components';
import { useTabletMediaQuery } from '@hooks';
import Head from 'next/head';
import { ReactNode } from 'react';

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const isTablet = useTabletMediaQuery();
  return (
    <>
      <Head>
        <title>YAxMNR 120th Anniversary</title>
      </Head>

      <Navbar />

      <main>{children}</main>

      {!isTablet && <ManorLogo />}

      <Overlay />
      {/* <Footer /> */}
    </>
  );
};

export default MainLayout;
