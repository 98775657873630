import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CursorStatus, RootState } from '@types';

interface DomState {
  leftText: string;
  rightText: string;
  cursorStatus: CursorStatus;
  isSideModalOpen: boolean;
  isNavbarModalOpen: boolean;
}

const initialState: DomState = {
  leftText: '120 years',
  rightText: '01',
  cursorStatus: CursorStatus.small,
  isSideModalOpen: false,
  isNavbarModalOpen: false,
};

export const domSlice = createSlice({
  name: 'dom',
  initialState,
  reducers: {
    setLateralTexts: (
      state: DomState,
      action: PayloadAction<{ leftText: string; rightText: string }>,
    ) => {
      state.leftText = action.payload.leftText;
      state.rightText = action.payload.rightText;
    },
    setCursorStatus: (state: DomState, action: PayloadAction<CursorStatus>) => {
      state.cursorStatus = action.payload;
    },
    setIsSideModalOpen: (state: DomState, action: PayloadAction<boolean>) => {
      state.isSideModalOpen = action.payload;
    },
    setIsNavbarModalOpen: (state: DomState, action: PayloadAction<boolean>) => {
      state.isNavbarModalOpen = action.payload;
    },
  },
});

export const selectLeftText = (state: RootState) => state.dom.leftText;
export const selectRightText = (state: RootState) => state.dom.rightText;
export const selectCursorStatus = (state: RootState) => state.dom.cursorStatus;
export const selectIsSideModalOpen = (state: RootState) => state.dom.isSideModalOpen;
export const selectIsNavbarModalOpen = (state: RootState) => state.dom.isNavbarModalOpen;
export const { setLateralTexts, setCursorStatus, setIsSideModalOpen, setIsNavbarModalOpen } =
  domSlice.actions;

export default domSlice.reducer;
