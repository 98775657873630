import { ForwardedRef, forwardRef } from 'react';
interface SVGSSecondaryArrowShapeProps {
  color?: string;
}

const SVGSSecondaryArrowShape = forwardRef(
  ({ color = '#27272A' }: SVGSSecondaryArrowShapeProps, ref: ForwardedRef<any>) => {
    return (
      <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          ref={ref}
          id="secondaryArrowShape"
          d="M1.38331 5.60156C2.48631 5.60156 4.18182 4.88994 4.91824 4.15352C5.61538 3.45638 1.4406 1.66279 1 1.00189"
          stroke={color}
          strokeLinecap="round"
        />
      </svg>
    );
  },
);

SVGSSecondaryArrowShape.displayName = 'SVGSSecondaryArrowShape';
export default SVGSSecondaryArrowShape;
