import { Network } from '@ethersproject/networks';
import { CHAIN_CONFIG, Web3Chain, Web3Network } from '@types';
import {
  ADAPTER_EVENTS,
  SafeEventEmitterProvider,
  UserInfo,
  WALLET_ADAPTERS,
} from '@web3auth/base';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { Web3Auth } from '@web3auth/web3auth';
import { getWalletProvider, WalletProvider } from '@web3providers/eth.provider';
import { ethers } from 'ethers';
import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';

export interface Web3AuthContext {
  web3Auth: Web3Auth | null;
  provider: WalletProvider | null;
  isLoading: boolean;
  chain: string;
  user: unknown;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  getUserInfo: () => Promise<Partial<UserInfo> | undefined>;
  signMessage: (message: string) => Promise<any>;
  signPersonalMessage: (message: string) => Promise<string | undefined>;
  getAccounts: () => Promise<string[] | undefined>;
  getAccount: () => Promise<string | undefined>;
  getBalance: () => Promise<ethers.BigNumber | undefined>;
  signTransaction: () => Promise<string | undefined>;
  signAndSendTransaction: () => Promise<string | undefined>;
  getChain: () => Promise<Network | undefined>;
}

export const Web3AuthContext = createContext<Web3AuthContext>({
  web3Auth: null,
  provider: null,
  isLoading: false,
  chain: '',
  user: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  login: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: async () => {},
  getUserInfo: async () => undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signMessage: async (message: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signPersonalMessage: async (message: string) => undefined,
  getAccounts: async () => [],
  getAccount: async () => undefined,
  getBalance: async () => undefined,
  signTransaction: async () => undefined,
  signAndSendTransaction: async () => undefined,
  getChain: async () => undefined,
});

const openLoginMethods = [
  'google',
  'facebook',
  'twitter',
  'reddit',
  'discord',
  'twitch',
  'apple',
  'line',
  'github',
  'kakao',
  'linkedin',
  'weibo',
  'wechat',
  'email_passwordless',
];

interface Web3AuthProps {
  children?: ReactNode;
  web3AuthNetwork: Web3Network;
  chain: Web3Chain;
}

export const Web3AuthProvider: React.FC<Web3AuthProps> = ({
  children,
  web3AuthNetwork,
  chain,
}: Web3AuthProps) => {
  const [web3Auth, setWeb3Auth] = useState<Web3Auth | null>(null);
  const [provider, setProvider] = useState<WalletProvider | null>(null);
  const [user, setUser] = useState<unknown | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const setWalletProvider = useCallback(
    (web3authProvider: SafeEventEmitterProvider) => {
      const walletProvider = getWalletProvider(chain, web3authProvider);
      setProvider(walletProvider);
    },
    [chain],
  );

  useEffect(() => {
    const subscribeAuthEvents = (web3auth: Web3Auth) => {
      // Can subscribe to all ADAPTER_EVENTS and LOGIN_MODAL_EVENTS
      web3auth.on(ADAPTER_EVENTS.CONNECTED, (data: unknown) => {
        console.log('Yeah!, you are successfully logged in', data);
        setUser(data);
        if (web3auth.provider) setWalletProvider(web3auth.provider);
      });

      web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
        console.log('connecting');
      });

      web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
        console.log('disconnected');
        setUser(null);
      });

      web3auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
        console.error('Some error or user has cancelled login request', error);
      });
    };

    const currentChainConfig = CHAIN_CONFIG[chain];

    const init = async () => {
      try {
        const clientId = process.env.web3AuthClientId;

        if (clientId) {
          setIsLoading(true);
          const web3AuthInstance = new Web3Auth({
            chainConfig: currentChainConfig,
            clientId,
            uiConfig: {
              appLogo: '/images/web3modal-manor-logo.png',
            },
          });
          const adapter = new OpenloginAdapter({
            adapterSettings: {
              network: web3AuthNetwork,
              clientId,
            },
          });
          web3AuthInstance.configureAdapter(adapter);

          subscribeAuthEvents(web3AuthInstance);
          setWeb3Auth(web3AuthInstance);
          await web3AuthInstance.initModal({
            modalConfig: {
              [WALLET_ADAPTERS.OPENLOGIN]: {
                label: 'openlogin',
                loginMethods: openLoginMethods.reduce(
                  (o, key) => ({ ...o, [key]: { showOnModal: key === 'email_passwordless' } }),
                  {},
                ),
              },
            },
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    init();
  }, [chain, web3AuthNetwork, setWalletProvider]);

  const login = async (): Promise<void> => {
    if (web3Auth) {
      const localProvider = await web3Auth.connect().catch(() => null);
      if (localProvider) setWalletProvider(localProvider);
    } else handleNotInitialized();
  };

  const logout = async (): Promise<void> => {
    if (web3Auth) {
      setUser(null);
      await web3Auth.logout().catch(() => null);
      setProvider(null);
    } else handleNotInitialized();
  };

  const getUserInfo = async (): Promise<Partial<UserInfo> | undefined> => {
    if (web3Auth) return web3Auth.getUserInfo();
    else handleNotInitialized();
  };

  const getAccounts = async (): Promise<string[] | undefined> => {
    if (provider) return provider.getAccounts();
    else handleProviderNotInitialized();
  };

  const getAccount = async (): Promise<string | undefined> => {
    if (provider) {
      const accounts = await provider.getAccounts();
      return accounts.length > 0 ? accounts[0] : undefined;
    } else handleProviderNotInitialized();
  };

  const getBalance = async (): Promise<ethers.BigNumber | undefined> => {
    if (provider) return provider.getBalance();
    else handleProviderNotInitialized();
  };

  const signMessage = async (message: string): Promise<string | undefined> => {
    if (provider) return provider.signMessage(message);
    else handleProviderNotInitialized();
  };

  const signPersonalMessage = async (message: string): Promise<string | undefined> => {
    if (provider) return provider.signPersonalMessage(message);
    else handleProviderNotInitialized();
  };

  const signTransaction = async (): Promise<any> => {
    if (provider) return provider.signTransaction();
    else handleProviderNotInitialized();
  };

  const signAndSendTransaction = async (): Promise<string | undefined> => {
    if (provider) return provider.signAndSendTransaction();
    else handleProviderNotInitialized();
  };

  const getChain = async (): Promise<Network | undefined> => {
    if (provider) return provider.getChain();
    else handleProviderNotInitialized();
  };

  const handleNotInitialized = (): void => console.log('Web3auth not initialized yet');
  const handleProviderNotInitialized = (): void => console.log('Provider not initialized yet');

  const contextProvider: Web3AuthContext = {
    web3Auth,
    chain,
    provider,
    user,
    isLoading,
    login,
    logout,
    getUserInfo,
    getAccounts,
    getAccount,
    getBalance,
    signMessage,
    signPersonalMessage,
    signTransaction,
    signAndSendTransaction,
    getChain,
  };
  return <Web3AuthContext.Provider value={contextProvider}>{children}</Web3AuthContext.Provider>;
};
