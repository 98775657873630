const SVGIconInstagram = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2936 0H4.29338C3.2325 0 2.21516 0.421369 1.46499 1.17154C0.714826 1.9217 0.293457 2.93904 0.293457 3.99992V20.0001C0.293457 21.061 0.714826 22.0783 1.46499 22.8285C2.21516 23.5787 3.2325 24 4.29338 24H20.2936C21.3544 24 22.3718 23.5787 23.122 22.8285C23.8721 22.0783 24.2935 21.061 24.2935 20.0001V3.99992C24.2935 2.93904 23.8721 1.9217 23.122 1.17154C22.3718 0.421369 21.3544 0 20.2936 0ZM12.2934 5.99956C10.7022 5.99956 9.1761 6.6317 8.05076 7.75686C6.9256 8.8822 6.29346 10.4083 6.29346 11.9995C6.29346 13.5908 6.9256 15.1168 8.05076 16.2422C9.1761 17.3673 10.7022 17.9995 12.2934 17.9995C13.8847 17.9995 15.4107 17.3673 16.5361 16.2422C17.6612 15.1168 18.2934 13.5908 18.2934 11.9995C18.2934 10.4083 17.6612 8.8822 16.5361 7.75686C15.4107 6.6317 13.8847 5.99956 12.2934 5.99956ZM12.2934 15.9994C11.2325 15.9994 10.2152 15.578 9.46504 14.8278C8.71487 14.0776 8.2935 13.0603 8.2935 11.9994C8.2935 10.9386 8.71487 9.92122 9.46504 9.17105C10.2152 8.42088 11.2325 7.99952 12.2934 7.99952C13.3543 7.99952 14.3716 8.42088 15.1218 9.17105C15.872 9.92122 16.2933 10.9386 16.2933 11.9994C16.2933 13.0603 15.872 14.0776 15.1218 14.8278C14.3716 15.578 13.3543 15.9994 12.2934 15.9994ZM18.7935 6.99791C19.6219 6.99791 20.2935 6.32643 20.2935 5.49792C20.2935 4.66958 19.6219 3.99793 18.7935 3.99793C17.965 3.99793 17.2935 4.66958 17.2935 5.49792C17.2935 6.32643 17.965 6.99791 18.7935 6.99791Z"
        fill="#27272A"
      />
    </svg>
  );
};

export default SVGIconInstagram;
