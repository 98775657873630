import classnames from 'classnames';
import styles from './env-banner.module.scss';

const EnvBanner = () => {
  const env = process.env.NEXT_PUBLIC_ENVIRONMENT ?? process.env.NODE_ENV;
  return env && env !== 'production' ? (
    <div className={classnames(styles.envBanner, styles[`--${env}`])}>{env}</div>
  ) : null;
};

export default EnvBanner;
