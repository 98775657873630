const SVGGreyShape = () => {
  return (
    <svg
      width="146"
      height="130"
      viewBox="0 0 146 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M142.179 59.2872C143.069 61.2887 143.781 63.364 144.308 65.4889C145.461 70.1381 145.642 70.3789 145.565 76.9826C145.435 87.9989 144.041 99.3642 140.898 109.894C134.321 131.938 114.835 133.451 97.6117 124.623C83.3481 117.312 72.0554 106.379 60.4559 95.8253C41.5339 78.6088 25.2986 59.1643 12.4834 36.8782C9.51172 31.6988 6.29766 26.6623 2.97276 21.5893C-2.1658 13.7555 2.55469 3.28244 11.8128 1.93041C18.1315 1.00311 24.3555 0.282302 30.591 0.222515C42.2077 0.116622 53.8557 2.41282 65.5767 3.89231C66.173 3.96729 101.722 12.1631 115.421 24.7058C122.787 31.4507 128.465 40.0327 134.979 47.7229C135.609 48.488 136.283 49.2163 136.996 49.9042C138.16 51.0235 139.097 52.3572 139.754 53.8314C140.444 55.3971 141.396 57.538 142.179 59.2872Z"
        fill="#DBD5D1"
      />
    </svg>
  );
};

export default SVGGreyShape;
