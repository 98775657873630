import { Button } from '@components';
import Image from 'next/image';
import { Benefit } from '@models';
import styles from './benefit-item.module.scss';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

interface BenefitItemProps {
  item: Benefit;
}

const BenefitItem = ({ item }: BenefitItemProps) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Image src={item.image} alt="Benefit image" layout="fill" objectFit="cover" />
      </div>
      <div className={styles.title}>{item.title}</div>
      <div className={styles.description}>
        <p>{item.descriptionSmall}</p>
      </div>
      <div className={styles.cta}>
        <Link href={`/benefits/${item._id}`}>
          <a>
            <Button variant="tertiary">{t('how_to_claim')}</Button>
          </a>
        </Link>
      </div>
    </div>
  );
};

export default BenefitItem;
