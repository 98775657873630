import Image from 'next/image';
import { toast } from 'react-toastify';

interface ToastProps {
  variant: 'success' | 'error' | 'warning' | 'info';
  message: string;
}

const Toast = ({ variant, message }: ToastProps) => {
  switch (variant) {
    case 'error':
      toast.error(message, {
        icon: <Image src="/images/toast-error-icon.png" alt="toast icon" height={35} width={35} />,
      });
      return;
    case 'success':
      toast.success(message, {
        icon: (
          <Image src="/images/toast-success-icon.png" alt="toast icon" height={35} width={35} />
        ),
      });
      return;
    case 'warning':
      toast.warn(message, {
        icon: (
          <Image src="/images/toast-warning-icon.png" alt="toast icon" height={35} width={35} />
        ),
      });
      return;
    case 'info':
      toast.info(message, {
        icon: <Image src="/images/toast-info-icon.png" alt="toast icon" height={35} width={35} />,
      });
      return;
    default:
      return <Image src="/images/toast-success-icon.png" alt="toast icon" height={35} width={35} />;
  }
};

export default Toast;
