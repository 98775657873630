import { Button } from '@components';
import { SVGArrowSolid } from '@components/svgs';
import { NFT } from '@models';
import { useTranslation } from 'next-i18next';
import styles from './nft-item.module.scss';

interface NFTItemProps {
  item: NFT;
}

const NFTItem = ({ item }: NFTItemProps) => {
  const { t } = useTranslation('common');
  const { name, image, animation_url, attributes } = item;
  const openOpenSea = () => {
    window.open(
      `${process.env.NEXT_PUBLIC_OPENSEA_URL}/${item.collection.id}/${item.tokenID}`,
      '_blank',
    ); // TODO: dynamic netowrk url
  };

  const openPolygonScan = () => {
    window.open(
      `${process.env.NEXT_PUBLIC_POLYGON_SCAN_URL}/${item.collection.id}?a=${item.tokenID}`,
      '_blank',
    ); // TODO: dynamic netowrk url
  };

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <video
          autoPlay
          playsInline
          loop
          muted
          poster={image}
          controls
          controlsList="nodownload"
          preload="metadata"
          className={styles.video}>
          <source src={animation_url} type="video/mp4" />
        </video>
      </div>
      <div className={styles.title}>
        <div>{name}</div>
        <div>
          #{item.tokenID} {t('of')} {item.collection.maxSupply}
        </div>
      </div>
      <ul className={styles.list}>
        {attributes.map((attribute) => (
          <li key={attribute.trait_type}>
            <span className={styles.traitType}>{attribute.trait_type}:&nbsp;</span>
            {attribute.value}
          </li>
        ))}
      </ul>
      <div className={styles.buttonsWrapper}>
        <div className={styles.arrow}>
          <SVGArrowSolid />
        </div>
        <div className={styles.cta} onClick={() => openOpenSea()}>
          <Button variant="tertiary">{t('profile.view_on')} opensea</Button>
        </div>
        <div className={styles.cta} onClick={() => openPolygonScan()}>
          <Button variant="tertiary">Polygonscan</Button>
        </div>
      </div>
    </div>
  );
};

export default NFTItem;
