import Image from 'next/image';
import { CSSProperties, forwardRef } from 'react';
import styles from './parallax-image.module.scss';

interface ParallaxImageProps {
  styles: CSSProperties;
  src: string;
  text: string;
  srcType: 'image' | 'video';
}

const ParallaxImage = forwardRef<HTMLDivElement, ParallaxImageProps>((props, ref) => {
  return (
    <div
      ref={ref}
      className={styles.wrapper}
      style={{
        ...props.styles,
      }}>
      {/* <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '20px',
          fontWeight: '500',
          color: 'red',
          zIndex: 999,
        }}>
        {props.text}
      </div> */}
      {props.srcType === 'image' ? (
        <Image src={props.src} alt="parallax" layout="fill" />
      ) : (
        <video autoPlay loop muted playsInline className={styles.video}>
          <source src={props.src} type="video/mp4" />
        </video>
      )}
    </div>
  );
});

ParallaxImage.displayName = 'ParallaxImage';

export default ParallaxImage;
