import { api } from '@services/axios';
import { apiInternal } from '@services/axios-internal';
import { AirdropType, AttendingStatus } from '@types';

export const apiAirdropVerifyToken = async (
  token: string,
  address: string,
  web3authEmail?: string,
): Promise<void> => api.post('user/airdrop/verify-token', { token, address, web3authEmail });

export interface VerificationInvitationDto {
  token: string;
  type: AirdropType;
  redeemed: boolean;
  txHash: string;
}

export interface AddReferralDto {
  name: string;
  surname: string;
  email: string;
}

export const apiVerifyInvitation = async (email: string): Promise<VerificationInvitationDto> =>
  api.post('user/invitation/verify-email', { email });

export const apiRSVP = async (
  eventId: string,
  rsvp: AttendingStatus,
  ics: string,
  shouldJoinVIPRaffle = false,
  isComingAlone?: boolean,
): Promise<AttendingStatus> =>
  apiInternal.post(`/api/rsvp`, { rsvp, shouldJoinVIPRaffle, ics, isComingAlone });

export const apiGetRSVP = async (eventId: string): Promise<AttendingStatus> =>
  api.get(`user/invitation/rsvp/${eventId}`);

export const apiGetReport = async (): Promise<string> => apiInternal.get('/api/report');

export const apiAddReferral = (addReferralDto: AddReferralDto): Promise<void> =>
  apiInternal.post('/api/referral', addReferralDto);

// export const apiGetInvitations = async (): Promise<Invitation[]> => api.get('user/invitations');
