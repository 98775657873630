import { SiweMessage } from 'siwe';
import { api } from './axios';
import apiInternal from './axios-internal';

export const apiGetNonce = async (address: string): Promise<{ nonce: string }> =>
  api.post('/auth/nonce', { address });

export const apiVerifyMessage = async (
  address: string,
  message: SiweMessage,
  signature: string,
): Promise<{ token: string }> => api.post('/auth/verify', { address, message, signature });

export const apiHasMembership = async (address: string): Promise<boolean> => {
  return api.get(`/auth/membership/${address}`, {
    auth: {
      username: process.env.BASIC_AUTH_USER!,
      password: process.env.BASIC_AUTH_PASS!,
    },
  });
};

export const apiInternalHasMembership = async (address: string): Promise<boolean> => {
  return apiInternal.get(`/api/membership?address=${address}`);
};
