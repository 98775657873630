import Link from 'next/link';
import { useRouter } from 'next/router';

const LangSwitch: React.FC = () => {
  const router = useRouter();

  return (
    <div>
      <Link href={router.asPath} locale={'en'}>
        <button type="button">EN</button>
      </Link>
      <Link href={router.asPath} locale={'de'}>
        <button type="button">DE</button>
      </Link>
      <Link href={router.asPath} locale={'fr'}>
        <button type="button">FR</button>
      </Link>
    </div>
  );
};

export default LangSwitch;
