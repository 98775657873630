import { SiweMessage } from 'siwe';

export const createSiweMessage = (address: string, nonce: string, chainId: number): SiweMessage => {
  const messagePrefix = 'Sign in with Ethereum to the app.';
  const domain = window.location.host;
  const origin = window.location.origin;
  const message = new SiweMessage({
    domain,
    address,
    statement: messagePrefix,
    uri: origin,
    version: '1',
    chainId: chainId,
    nonce,
  });
  return message;
};

export const getIPFSGateway = (url: string): string => {
  const gateway = 'https://ipfs.io/'; // TODO: Evaluate ipfs gateways
  return `${gateway}${url}`.replace('ipfs://', 'ipfs/');
};
