import { ForwardedRef, forwardRef } from 'react';

const SVGArrowDown = forwardRef((props, ref: ForwardedRef<SVGSVGElement> | undefined) => {
  return (
    <svg
      ref={ref}
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1.95909C1 4.719 2.70184 8.96142 4.46296 10.804C6.13016 12.5484 10.4195 2.10245 12 1"
        stroke="#27272A"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
});
SVGArrowDown.displayName = 'SVGArrowDown';

export default SVGArrowDown;
