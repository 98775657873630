const SVGBigShape = () => {
  return (
    <svg
      width="110"
      height="166"
      viewBox="0 0 110 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M73.4935 165.148C71.7967 165.495 70.4298 166.175 69.2224 165.958C60.2339 164.344 51.2524 162.67 42.3291 160.759C38.5862 159.956 35.8068 157.6 33.5223 154.343C26.7726 144.724 22.3754 134.007 19.8538 122.718C17.6774 112.976 14.0967 103.943 9.92967 94.9973C6.9215 88.5386 4.35278 81.8753 1.54007 75.3234C-0.937162 69.5494 -0.508826 64.4809 3.42952 59.1523C12.1199 47.3899 20.4888 35.4314 31.8865 26.0126C35.5435 22.9899 38.991 19.6833 42.3263 16.3002C56.0502 2.37524 72.6465 -2.16853 91.6187 0.926521C94.9596 1.47205 98.3559 1.84641 101.608 2.73568C108.046 4.49057 110.694 9.02043 109.847 15.7909C108.832 23.9126 107.229 31.9105 104.552 39.6286C96.7368 62.1596 92.0526 85.5061 86.5991 108.647C82.8562 124.52 79.6401 140.516 76.1038 156.439C75.4495 159.359 74.3904 162.191 73.4935 165.148Z"
        fill="#DBD5D1"
      />
    </svg>
  );
};

export default SVGBigShape;
