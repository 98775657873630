const SVGIconTwitter = () => {
  return (
    <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28 2.72285C26.9587 3.18462 25.8492 3.49069 24.6925 3.63931C25.8825 2.921 26.7908 1.79223 27.2178 0.431692C26.1083 1.10046 24.8832 1.57285 23.5777 1.83646C22.5243 0.702385 21.0227 0 19.3848 0C16.2067 0 13.6482 2.60785 13.6482 5.80485C13.6482 6.26485 13.6868 6.70715 13.7812 7.12823C9.009 6.89292 4.78625 4.58054 1.9495 1.058C1.45425 1.92669 1.16375 2.921 1.16375 3.99138C1.16375 6.00123 2.1875 7.78285 3.7135 8.81431C2.79125 8.79661 1.8865 8.52592 1.12 8.09954C1.12 8.11723 1.12 8.14023 1.12 8.16323C1.12 10.9834 3.10975 13.3258 5.719 13.8655C5.25175 13.9946 4.7425 14.0565 4.214 14.0565C3.8465 14.0565 3.4755 14.0353 3.12725 13.9575C3.871 16.2557 5.9815 17.9453 8.491 18.0002C6.538 19.5447 4.05825 20.4753 1.37375 20.4753C0.903 20.4753 0.4515 20.4541 0 20.3957C2.54275 22.0535 5.55625 23 8.806 23C19.369 23 25.144 14.1538 25.144 6.486C25.144 6.22946 25.1353 5.98177 25.123 5.73585C26.2622 4.91846 27.2195 3.89762 28 2.72285Z"
        fill="#27272A"
      />
    </svg>
  );
};

export default SVGIconTwitter;
