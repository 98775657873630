import axios from 'axios';

export const apiInternal = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

apiInternal.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.log('axios error ==> ', error);
    // TODO: call sentry?
    throw error;
  },
);

export default apiInternal;
