import React, { useEffect } from 'react';

interface ErrorFallbackProps {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
}

const ErrorFallback = ({ error, componentStack, resetError }: ErrorFallbackProps) => {
  useEffect(() => {
    console.log('👾 error:', error);
    resetError();
  }, [resetError]);
  return (
    <React.Fragment>
      <div>You have encountered an error</div>
      <div>{error?.toString()}</div>
      <div>{componentStack}</div>
      <button
        onClick={() => {
          // this.setState({ message: 'This is my app' });
          {
            /* When resetError() is called it will remove the Fallback component */
          }
          {
            /* and render the Sentry ErrorBoundary's children in their initial state */
          }
          resetError();
        }}>
        Click here to reset!
      </button>
    </React.Fragment>
  );
};

export default ErrorFallback;
