import { Web3Chain } from '@types';
import { CHAIN_NAMESPACES, CustomChainConfig } from '@web3auth/base';

export const CHAIN_CONFIG: { [key in Web3Chain]: CustomChainConfig } = {
  ethereum: {
    displayName: 'Ethereum Mainnet',
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x1',
    rpcTarget: `https://mainnet.infura.io/v3/776218ac4734478c90191dde8cae483c`,
    blockExplorer: 'https://etherscan.io/',
    ticker: 'ETH',
    tickerName: 'Ethereum',
  },
  polygon: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: 'https://polygon-rpc.com',
    blockExplorer: 'https://polygonscan.com/',
    chainId: '0x89',
    displayName: 'Polygon Mainnet',
    ticker: 'matic',
    tickerName: 'Matic',
  },
  mumbai: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: 'https://rpc-mumbai.maticvigil.com',
    blockExplorer: 'https://polygonscan.com/',
    chainId: '0x13881',
    displayName: 'Polygon Mumbai',
    ticker: 'matic',
    tickerName: 'Matic',
  },
};

export const PRE_SALE_ICS = [
  'BEGIN:VCALENDAR',
  'CALSCALE:GREGORIAN',
  'VERSION:2.0',
  'X-WR-CALNAME:Yael Anders X Manor Collection Pre-Sale Event',
  'METHOD:PUBLISH',
  'PRODID:-//Apple Inc.//macOS 12.5//EN',
  'BEGIN:VTIMEZONE',
  'TZID:Europe/Zurich',
  'BEGIN:DAYLIGHT',
  'TZOFFSETFROM:+0100',
  'RRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU',
  'DTSTART:19810329T020000',
  'TZNAME:CEST',
  'TZOFFSETTO:+0200',
  'END:DAYLIGHT',
  'BEGIN:STANDARD',
  'TZOFFSETFROM:+0200',
  'RRULE:FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU',
  'DTSTART:19961027T030000',
  'TZNAME:CET',
  'TZOFFSETTO:+0100',
  'END:STANDARD',
  'END:VTIMEZONE',
  'BEGIN:VEVENT',
  'TRANSP:OPAQUE',
  'DTEND;TZID=Europe/Zurich:20221006T210000',
  'DTSTART;TZID=Europe/Zurich:20221006T180000',
  'UID:E036CC6D-D231-4E96-8A9E-183B50992233',
  'DTSTAMP:20220907T073338Z',
  'ORGANIZER;CN="MANOR":mailto:nftcommunity@manor.ch',
  'LOCATION:Rue de Cornavin 6\nGeneva, Switzerland',
  `DESCRIPTION:All holders of the Phase 1 NFT are invited to celebrate Manor's 120th anniversary.\n\nJoin us in Geneva for an exclusive pre-sale event and be among the first ones to shop the Yael Anders collection\, before it's available elsewhere.\n\nParticipate to the raffle of 10 very rare NFTs, only accessible to the participants of the event.\n\nEnjoy drinks and a DJ in a nice atmosphere and profit from an attractive goodie bag.`,
  'SEQUENCE:1',
  'SUMMARY:Yael Anders X Manor Collection Pre-Sale Event',
  'LAST-MODIFIED:20220907T073338Z',
  'CREATED:20220907T073042Z',
  'END:VEVENT',
  'END:VCALENDAR',
];

export const NOT_FOUND_REDIRECT = {
  redirect: {
    destination: '/404',
    permanent: false,
  },
};
