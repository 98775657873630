import { Button, Tag, Toast } from '@components';
import { Airdrop } from '@models';
import { apiVerifyInvitation, VerificationInvitationDto } from '@services';
import { AxiosError } from 'axios';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ForwardedRef, forwardRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './verification-state.module.scss';

interface VerifyEmailFormData {
  email: string;
}

const VerificationState = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
  const router = useRouter();
  const { t } = useTranslation('common');
  const [hasVerified, setHasVerified] = useState<boolean>(false);
  const [airdrop, setAirdrop] = useState<Airdrop>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<VerifyEmailFormData>();

  const onSubmit = async (data: VerifyEmailFormData) => {
    const { email } = data;
    setIsFetching(true);

    await apiVerifyInvitation(email)
      .then((res: VerificationInvitationDto) => {
        setHasVerified(true);
        setAirdrop(res);
      })
      .catch((err: AxiosError) => {
        console.log(err);
        if (err.response?.status === 404) {
          setError('email', {
            type: 'apiError',
            message: t('email_not_registered'),
          });
        } else if (err.response?.status === 607) {
          setError('email', {
            type: 'apiError',
            message: t('invitation_expired'),
          });
        } else {
          setError('email', {
            type: 'apiError',
            message: `Error ${err.response?.status}- ${t('contact_support')}`,
          });
        }
      })
      .finally(() => setIsFetching(false));
  };

  const claimNFT = () => {
    if (!airdrop?.redeemed && airdrop?.token) router.push(`/welcome/${airdrop.token}`);
    else Toast({ variant: 'warning', message: t('email_already_registered') });
  };

  return (
    <div ref={ref} className={styles.modal}>
      <Tag text={t('verify.step')} mode={'light'} />
      <h1 className={styles.title}>{t('verify.verify_your_email')}</h1>
      {!hasVerified ? (
        <>
          <p className={styles.subtitle}>{t('verify.text')}</p>
          <p className={styles.subtitle1}>{t('verify.text1')}</p>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <input
              {...register('email', {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t('invalid_email'),
                },
              })}
              className={classnames(errors.email && styles.inputWithError)}
            />
            <div className={styles.errorMessage}>
              {errors.email && errors.email.type === 'required' && (
                <span>{t('email_required')}</span>
              )}
              {errors.email &&
                (errors.email.type === 'pattern' || errors.email.type === 'apiError') && (
                  <span>{errors.email.message}</span>
                )}
            </div>
            <div className={styles.submitButton}>
              <Button variant="privatePrimary" isLoading={isFetching}>
                {t('verify.verify')}
              </Button>
            </div>
          </form>
        </>
      ) : (
        <>
          <p className={styles.subtitle}>{t('verify.text')}</p>
          <div className={styles.successButton}>{t('success')}!</div>
          <Tag text={t('verify.step2')} mode={'light'} />
          <h1 className={styles.title}>{t('verify.register_with_our_community')}</h1>
          <div className={styles.followText}>
            <p>{t('verify.text2')}</p>
            <Button variant="privateSecondary" onClick={claimNFT}>
              {t('verify.start')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
});
VerificationState.displayName = 'VerificationState';

export default VerificationState;
