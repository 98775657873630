import { Toast } from '@components';
import { gsap } from 'gsap';
import { i18n } from 'next-i18next';

export const stripUndefined = (array: any[]) => {
  return array
    .filter((item) => item !== undefined)
    .filter((n) => n)
    .reduce(function (result, item) {
      result.push(Array.isArray(item) && !item.length ? stripUndefined(item) : item);
      return result;
    }, []);
};

export const formatICSDate = (dateString: string) => {
  const dateTime = new Date(dateString);
  return [
    dateTime.getUTCFullYear(),
    pad(dateTime.getUTCMonth() + 1),
    pad(dateTime.getUTCDate()),
    'T',
    pad(dateTime.getUTCHours()),
    pad(dateTime.getUTCMinutes()) + '00Z',
  ].join('');
};

const pad = (num: number) => {
  // Ensure date values are double digits
  return num < 10 ? '0' + num : num;
};

export const scrollTo = (sectionId: string, direction: 'up' | 'down', offset = 0) => {
  // let offset: number;
  // const offset = 0;
  // if (isDesktop) {
  //   offset = window.innerHeight;
  // } else {
  //   offset = 0;
  // }
  gsap.to(window, {
    scrollTo: {
      y: sectionId,
      offsetY: direction === 'up' ? offset : -offset,
    },
  });
};

export const copyToClipboard = async (text: string) =>
  await navigator.clipboard
    .writeText(text)
    .then(() => Toast({ variant: 'success', message: i18n!.t('copy_to_clipboard') }));
