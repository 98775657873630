import { forwardRef, ForwardedRef } from 'react';

const SVGLineShape = forwardRef((_, ref: ForwardedRef<SVGPathElement> | undefined) => {
  return (
    <svg
      width="1465"
      height="2"
      viewBox="0 0 1465 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path ref={ref} d="M0.00390625 1L1464 1.00017" stroke="#27272A" />
    </svg>
  );
});
SVGLineShape.displayName = 'SVGLineShape';

export default SVGLineShape;
