import { SerializedPortableText, Tag } from '@components';
import { SVGArrowSolid } from '@components/svgs';
import { useAppDispatch, useArrayRef, useOnScreen, useXlDesktopMediaQuery } from '@hooks';
import { setLateralTexts } from '@store/dom-slice';
import { CollectionSlide, ProjectSectionProps } from '@types';
import classnames from 'classnames';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useTranslation } from 'next-i18next';
import React, { FC, MutableRefObject, useCallback, useEffect, useRef } from 'react';
import SVGBottomShape from './components/svg-bottom-shape';
import SVGCenterShape from './components/svg-center-shape';
import SVGLineShape from './components/svg-line-shape';
import SVGMaskShape from './components/svg-mask-shape';
import SVGMaskedImage from './components/svg-masked-image';
import SVGTopShape from './components/svg-top-shape';
import styles from './project-section.module.scss';

const ProjectSection: FC<ProjectSectionProps> = ({ parentRef, projectData }) => {
  const slidesRef: MutableRefObject<CollectionSlide[]> = useRef([
    {
      id: '01',
      backgroundColor: '#d2e8df',
      svgColor: '#E3FC5D',
      maskedImage: '/images/masked-image-1.png',
      titlePrimary: projectData.projectSlide1.titlePrimary,
      titleSecondary1: projectData.projectSlide1.titleSecondary1,
      titleSecondary2: projectData.projectSlide1.titleSecondary2,
      descriptionTitle: projectData.projectSlide1.descriptionTitle,
      descriptionText: projectData.projectSlide1.descriptionText,
      videoSrc: '/videos/YAEL_X_MANOR_PROJECT_0_1080p.mp4',
      mobileVideoSrc: '/videos/YAEL_X_MANOR_PROJECT_0_720.mp4',
      tagText: projectData.projectSlide1.tag,
    },
    {
      id: '02',
      backgroundColor: '#FDE4C6',
      svgColor: '#FF8D00',
      maskedImage: 'images/masked-image-2.png',
      titlePrimary: projectData.projectSlide2.titlePrimary,
      titleSecondary1: projectData.projectSlide2.titleSecondary1,
      titleSecondary2: projectData.projectSlide2.titleSecondary2,
      descriptionTitle: projectData.projectSlide2.descriptionTitle,
      descriptionText: projectData.projectSlide2.descriptionText,
      videoSrc: '/videos/YAEL_X_MANOR_PROJECT_1_1080p.mp4',
      mobileVideoSrc: '/videos/YAEL_X_MANOR_PROJECT_1_720.mp4',
      tagText: projectData.projectSlide2.tag,
    },
    {
      id: '03',
      backgroundColor: '#DDE4EC',
      svgColor: '#CCC9F4',
      maskedImage: '/images/masked-image-3.png',
      titlePrimary: projectData.projectSlide3.titlePrimary,
      titleSecondary1: projectData.projectSlide3.titleSecondary1,
      titleSecondary2: projectData.projectSlide3.titleSecondary2,
      descriptionTitle: projectData.projectSlide3.descriptionTitle,
      descriptionText: projectData.projectSlide3.descriptionText,
      videoSrc: '/videos/YAEL_X_MANOR_making_of_1080.mp4',
      mobileVideoSrc: '/videos/YAEL_X_MANOR_making_of_720.mp4',
      tagText: projectData.projectSlide3.tag,
    },
  ]);
  const slides = slidesRef.current;
  const { t } = useTranslation('common');
  const isXlDesktop = useXlDesktopMediaQuery();
  const sectionRef = useRef<HTMLDivElement>(null);
  const titlePrimaryRef = useRef<HTMLSpanElement>(null);
  const titleSecondary1Ref = useRef<HTMLSpanElement>(null);
  const titleSecondary2Ref = useRef<HTMLSpanElement>(null);
  const videoSourceRef = useRef(null);
  const videoWrapperRef = useRef<HTMLDivElement>(null);
  const svgCenterRef = useRef<SVGSVGElement>(null);
  const svgTopRef = useRef<SVGSVGElement>(null);
  const svgBottomRef = useRef<SVGSVGElement>(null);
  const svgMaskRef = useRef<SVGSVGElement>(null);
  const svgLineRef = useRef<SVGPathElement>(null);
  const descriptionTitleRef = useRef<HTMLDivElement>(null);
  const tagRef = useRef<HTMLDivElement>(null);
  const scrollingTextRef = useRef<HTMLDivElement>(null);
  const { refs: maskedImageRefs, setRef: setMaskedImageRef } = useArrayRef<HTMLDivElement>();
  const { refs: videoRefs, setRef: setVideoRef } = useArrayRef<HTMLVideoElement>();
  const { refs: descriptionTextRefs, setRef: setDescriptionTextRef } =
    useArrayRef<HTMLDivElement>();
  const { refs: pagerIndexRefs, setRef: setPagerIndexRef } = useArrayRef<
    HTMLDivElement | HTMLSpanElement
  >();
  const isOnScreen = useOnScreen(sectionRef);
  const dispatch = useAppDispatch();

  const getSlideTimeline = useCallback(
    (index: number): gsap.core.Timeline =>
      videoRefs.current &&
      gsap
        .timeline({ paused: true })
        .call(() => {
          index !== 2 &&
            gsap.set(videoWrapperRef.current, {
              css: { width: `${videoWrapperRef.current?.offsetWidth}px` },
            });
        })
        .swapText(
          tagRef.current,
          {
            text: slides[index].tagText,
            duration: 2,
          },
          0,
        )
        .swapText(
          titlePrimaryRef.current,
          {
            text: slides[index].titlePrimary,
            duration: 2,
          },
          0,
        )
        .swapText(
          pagerIndexRefs.current,
          {
            text: slides[index].id,
            duration: 2,
          },
          0,
        )
        .swapText(
          titleSecondary1Ref.current,
          {
            text: slides[index].titleSecondary1,
            opacity: 0,
            duration: 2,
          },
          0,
        )
        .swapText(
          titleSecondary2Ref.current,
          {
            text: slides[index].titleSecondary2,
            opacity: 0,
            duration: 2,
          },
          0,
        )
        .swapText(
          descriptionTitleRef.current,
          {
            text: slides[index].descriptionTitle,
            opacity: 0,
            duration: 2,
          },
          0,
        )
        .to(descriptionTextRefs.current, { opacity: 0, duration: 1 }, 0)
        .call(
          () => {
            const descriptionToShow = descriptionTextRefs.current[index];
            const descriptionTextsToHide = descriptionTextRefs.current.filter(
              (_, i) => i !== index,
            );
            descriptionToShow?.removeAttribute('hidden');
            for (const image of descriptionTextsToHide) image.setAttribute('hidden', 'true');
          },
          [],
          0.9,
        )
        .to(descriptionTextRefs.current[index], { opacity: 1, duration: 2 }, 1)
        .to(
          parentRef?.current,
          { duration: 2, backgroundColor: slides[index].backgroundColor, ease: 'none' },
          0,
        )
        .to(
          [
            svgCenterRef.current?.querySelector('path'),
            svgTopRef.current?.querySelector('path'),
            svgBottomRef.current?.querySelector('path'),
          ],
          { fill: slides[index].svgColor, duration: 1 },
          0,
        )
        .to(
          svgMaskRef.current?.querySelector('path'),
          { stroke: slides[index].svgColor, duration: 1 },
          0,
        )
        .to(maskedImageRefs.current, { opacity: 0, duration: 1 }, 0)
        .call(
          () => {
            const imageToShow = maskedImageRefs.current[index];
            const imagesToHide = maskedImageRefs.current.filter((_, i) => i !== index);
            imageToShow?.removeAttribute('hidden');
            for (const image of imagesToHide) image.setAttribute('hidden', 'true');
          },
          [],
          0.9,
        )
        .to(maskedImageRefs.current[index], { opacity: 1, duration: 2 }, 1)
        .to(videoRefs.current, { opacity: 0, duration: 1 }, 0)
        .call(
          () => {
            const videoToShow = videoRefs.current[index];
            const videosToHide = videoRefs.current.filter((_, i) => i !== index);
            videoToShow?.removeAttribute('hidden');
            for (const video of videosToHide) {
              video.setAttribute('hidden', 'true');
            }
          },
          [],
          0.9,
        )
        .to(videoRefs.current[index], { opacity: 1, duration: 2 }, 1),
    [descriptionTextRefs, maskedImageRefs, pagerIndexRefs, parentRef, slides, videoRefs],
  );

  useEffect(() => {
    const totalSectionScroll = 600;

    const slide0Tl = getSlideTimeline(0);
    const slide1Tl = getSlideTimeline(1);
    const slide2Tl = getSlideTimeline(2);

    gsap.set(svgLineRef.current, { drawSVG: '0%' });

    const svgLineTls = slides.map((_, index) => {
      const start = totalSectionScroll * (index === 0 ? 0 : index === 1 ? 0.33 : 0.66);

      return gsap
        .timeline({
          scrollTrigger: {
            trigger: sectionRef.current,
            start: `+=${start}% top`,
            end: () => `+=200%`,
            scrub: 2,
            onLeave: (self) => {
              if (index === 2) gsap.set(svgLineRef.current, { drawSVG: '100%' });
              self.kill(false, true);
            },
          },
        })
        .set(svgLineRef.current, { drawSVG: '0%' })
        .to(svgLineRef.current, { drawSVG: '100%' });
    });

    ScrollTrigger.create({
      trigger: sectionRef.current,
      pin: sectionRef.current,
      start: 'top top',
      end: () => `+=${totalSectionScroll}%`,
      scrub: 1,
      onUpdate: ({ direction, progress }) => {
        if (
          direction === -1 &&
          progress < 0.33 &&
          !slide0Tl.isActive() &&
          titlePrimaryRef.current?.innerText !== slides[0].titlePrimary
        )
          slide0Tl.restart();
        if (
          progress > 0.33 &&
          progress < 0.66 &&
          !slide1Tl.isActive() &&
          titlePrimaryRef.current?.innerText !== slides[1].titlePrimary
        ) {
          if (direction === 1)
            gsap.set(titlePrimaryRef.current, {
              marginRight: '1%',
              delay: 1,
            });
          slide1Tl.restart();
        }
        if (
          direction === 1 &&
          progress > 0.66 &&
          !slide2Tl.isActive() &&
          titlePrimaryRef.current?.innerText !== slides[2].titlePrimary
        )
          slide2Tl.restart();
      },
    });

    const transitionInTl =
      parentRef?.current &&
      gsap
        .timeline({
          scrollTrigger: {
            trigger: sectionRef.current,
            start: '-=150% top',
            end: '-=25% top',
            scrub: 2,
          },
        })
        .fromTo(
          parentRef.current,
          { backgroundColor: 'transparent' },
          {
            backgroundColor: styles.colorGreen,
          },
          0,
        )
        .from(
          sectionRef.current,
          {
            yPercent: 75,
            opacity: 0,
          },
          0,
        )
        .fromTo(
          svgTopRef.current,
          { opacity: 0, transform: 'rotate(-90deg)', yPercent: -200 },
          { opacity: 1, transform: 'rotate(0deg)', yPercent: 0 },
          0,
        );

    const transitionOutTl =
      parentRef?.current &&
      gsap
        .timeline({
          scrollTrigger: {
            trigger: sectionRef.current,
            start: '+=25% top',
            scrub: 2,
          },
        })
        .to(sectionRef.current, {
          yPercent: -100,
          opacity: 0,
        })
        .to(parentRef.current, { backgroundColor: 'transparent' });

    const scrollingTextTween = gsap.from(scrollingTextRef.current, {
      duration: 0.5,
      y: -10,
      repeat: -1,
      yoyo: true,
    });

    return () => {
      slide0Tl.kill();
      slide1Tl.kill();
      slide2Tl.kill();
      transitionOutTl?.kill();
      transitionInTl?.kill();
      scrollingTextTween.kill();
      svgLineTls.map((tl) => tl.kill());
    };
  }, [getSlideTimeline, parentRef, slides]);

  useEffect(() => {
    isOnScreen &&
      dispatch(setLateralTexts({ leftText: 'lateral_texts.projects', rightText: '03' }));
  }, [isOnScreen, dispatch]);

  // const resize = () => {
  //   const width = window.innerWidth;
  //   const height = window.innerHeight;
  //   const aspectRatio = width / height;
  //   console.log('width:', width);
  //   console.log('height', height);
  //   console.log('aspectRatio', aspectRatio);
  // };

  // useEffect(() => {
  //   resize();

  //   window.addEventListener('resize', resize);
  //   return () => window.removeEventListener('resize', resize);
  // }, []);

  return (
    <section id="projects" data-section-id="#projects" ref={sectionRef} className={styles.section}>
      <div className={styles.topShape}>
        <SVGTopShape ref={svgTopRef} />
      </div>
      <div className={styles.bottomShape}>
        <SVGBottomShape ref={svgBottomRef} />
      </div>
      <div className={styles.maskedImage}>
        {slides.map((slide, index) => (
          <div key={index} ref={(ref) => ref && setMaskedImageRef(ref)} hidden={index > 0}>
            <SVGMaskedImage id={`slide${index}`} maskedImage={slide.maskedImage} />
          </div>
        ))}
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.textWrapper}>
          <div className={styles.tag}>
            <Tag ref={tagRef} mode="light" text={slides[0].tagText} />
          </div>
          <div className={styles.titleLine}>
            <div
              ref={(ref) => ref && setPagerIndexRef(ref)}
              className={classnames(styles.titleAppendix, styles.before)}>
              {slides[0].id}
            </div>
            <span ref={titlePrimaryRef} className={styles.titlePrimary}>
              {slides[0].titlePrimary}&nbsp;
            </span>
            <span ref={titleSecondary1Ref} className={styles.titleSecondary}>
              {slides[0].titleSecondary1}
            </span>
          </div>
          <div className={styles.titleLine}>
            <span ref={titleSecondary2Ref} className={styles.titleSecondary}>
              {slides[0].titleSecondary2}
            </span>
            <span
              ref={(ref) => ref && setPagerIndexRef(ref)}
              className={classnames(styles.titleAppendix, styles.after)}>
              {slides[0].id}
            </span>
          </div>
        </div>
        <div className={classnames(styles.textWrapper, styles.description)}>
          <div className={styles.arrow}>
            <SVGArrowSolid />
          </div>
          <div ref={descriptionTitleRef} className={styles.descriptionTitle}>
            {slides[0].descriptionTitle}
          </div>
          <div className={styles.descriptionDivider}>
            <SVGLineShape ref={svgLineRef} width={isXlDesktop ? 600 : 700} />
          </div>
          {slides.map((slide, index) => (
            <div key={index} ref={(ref) => ref && setDescriptionTextRef(ref)} hidden={index > 0}>
              <SerializedPortableText value={slide.descriptionText} />
            </div>
          ))}
          <div className={styles.descriptionPager}>
            <span ref={(ref) => ref && setPagerIndexRef(ref)}>01</span>&nbsp;{t('of')} 03
          </div>
        </div>
      </div>
      <div ref={videoWrapperRef} className={styles.videoWrapper}>
        <div className={styles.centerShape}>
          <SVGCenterShape ref={svgCenterRef} />
        </div>
        {slides.map((slide, index) => (
          <video
            key={index}
            ref={(ref) => ref && setVideoRef(ref)}
            hidden={index > 0}
            autoPlay
            playsInline
            muted
            loop
            preload="true"
            className={classnames(styles.video, styles[`--${index}`])}>
            <source ref={videoSourceRef} src={slide.videoSrc} />
          </video>
        ))}
        <div className={styles.videoShape}>
          <SVGMaskShape ref={svgMaskRef} />
        </div>
      </div>
      <div ref={scrollingTextRef} className={styles.scrollingText}>
        KEEP SCROLLING
      </div>
    </section>
  );
};

export default React.memo(ProjectSection);
