const Phase3Shape = () => {
  return (
    <svg
      width="2070"
      height="1409"
      viewBox="0 0 2070 1409"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1904.73 436.505L1905.18 436.735L1051.2 1204.57C980.22 1219.5 906.755 1222.54 833.251 1225.57C797.187 1227.06 761.113 1228.55 725.317 1231.44L725.284 1230.95C725.283 1230.95 725.282 1230.95 725.28 1230.95C714.558 1231.66 703.885 1232.99 693.314 1234.94L693.313 1234.94C676.185 1238.11 658.608 1237.93 641.558 1234.43C623.428 1230.62 598.591 1225.48 578.258 1221.34C555.065 1216.55 532.343 1209.73 510.355 1200.97L510.169 1201.44L510.354 1200.97C507.218 1199.72 504.273 1198.56 501.491 1197.45C482.892 1190.09 471.604 1185.61 459.422 1178.96C445.417 1171.3 430.224 1160.76 401.413 1139.59C305.346 1068.97 214.188 985.038 141.41 891.011C65.2757 792.665 54.6185 698.368 86.1853 616.712C117.765 535.022 191.638 465.897 284.732 418.017C424.427 346.165 574.882 314.479 725.388 282.782C741.048 279.484 756.708 276.186 772.356 272.843C1043.15 214.99 1316.58 194.826 1593.44 222.666C1657.76 229.197 1722.36 232.693 1787.97 235.445C1888.94 239.641 1951.08 346.85 1904.73 436.505Z"
        stroke="#CCC9F4"
      />
      <path
        d="M822.924 944.542C812.148 942.315 801.591 939.147 791.375 935.076C769.017 926.176 767.506 926.207 740.77 906.565C696.178 873.784 653.86 834.822 620.073 791.169C549.329 699.786 600.197 615.802 686.657 571.334C758.259 534.505 835.95 520.407 912.991 503.947C1038.67 477.097 1165.58 467.737 1294.08 480.659C1323.92 483.689 1353.89 485.311 1384.33 486.588C1431.36 488.542 1460.29 538.472 1438.71 580.226C1424 608.736 1408.72 636.256 1390.71 661.887C1357.14 709.623 1313.67 750.453 1273.32 793.973C1271.26 796.188 1133.74 917.324 1042.43 936.532C993.335 946.86 941.676 944.92 891.212 949.001C886.244 949.333 881.3 949.95 876.403 950.849C868.425 952.326 860.238 952.245 852.296 950.612C843.884 948.847 832.359 946.463 822.924 944.542Z"
        fill="#CCC9F4"
      />
    </svg>
  );
};

export default Phase3Shape;
