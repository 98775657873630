import { SVGProps } from 'react';
import styles from './fixed-shape.module.scss';

const SVGFixedShape = ({ width = '1727px', height = '1227px' }: SVGProps<SVGElement>) => {
  return (
    <div className={styles.shape}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 1727 1227"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.04"
          d="M1 749.168C11.4354 678.469 47.7054 517.298 109.302 438.206C152.454 385.816 308.985 299.625 364.828 256.53C420.672 213.435 462.977 149.214 494.284 112.034C525.59 74.8538 599.201 35.1386 676.198 25.8435C753.194 16.5485 1050.18 -11.3367 1166.94 7.25343C1283.71 25.8435 1474.93 74.8538 1632.3 256.53C1721.99 361.312 1730.45 460.178 1724.53 527.778C1718.61 595.378 1682.23 678.189 1583.23 724.664C1484.24 771.139 1419.08 771.139 1428.39 952.815C1425.85 1029.71 1409.78 1118.44 1336.17 1170.83C1262.55 1223.22 1206.71 1262.93 1095.87 1170.83C978.26 1082.1 888.572 1022.95 830.19 1022.95C771.808 1022.95 710.042 1053.37 537.435 1170.83C463.654 1212.74 317.728 1188.29 253.988 1170.83C221.271 1169.7 146.531 1145.98 109.302 1060.13C62.7662 952.815 15.3839 809.164 1 788.884"
          stroke="#27272A"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default SVGFixedShape;
