import { Toast } from '@components';
import { useAppDispatch, useAppSelector, useWeb3Auth } from '@hooks';
import { apiGetNonce, apiVerifyMessage } from '@services';
import { apiInternalHasMembership } from '@services/auth';
import {
  resetAuthState,
  selectIsAuthenticated,
  selectShouldAutoLogin,
  setToken,
} from '@store/auth-slice';
import { setUser } from '@store/user-slice';
import { firebaseAuth, firestore } from '@utils/firebase';
import { createSiweMessage } from '@utils/web3';
import { AxiosError } from 'axios';
import { signInWithCustomToken, User } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import nookies from 'nookies';
import { createContext, useCallback, useEffect } from 'react';

export const AuthContext = createContext<null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { user: web3user, getAccount, signPersonalMessage, getChain, logout } = useWeb3Auth();
  const dispatch = useAppDispatch();
  const shouldAutoLogin = useAppSelector(selectShouldAutoLogin);
  const { web3Auth } = useWeb3Auth();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const router = useRouter();
  const { t } = useTranslation('common');

  const signOut = useCallback(async () => {
    if (isAuthenticated) {
      nookies.set(undefined, 'token', '', { path: '/' });
      await firebaseAuth.signOut();
      dispatch(resetAuthState());
      await logout();
    }
  }, [dispatch, isAuthenticated, logout]);

  const handleFirebaseTokenChange = useCallback(
    async (user: User | null) => {
      if (shouldAutoLogin) {
        try {
          if (!user) {
            await signOut();
            return;
          }
          const token = await user.getIdToken();
          nookies.set(undefined, 'token', token, { path: '/' });
          dispatch(setToken(token));

          if (web3Auth?.loginModal) web3Auth?.loginModal?.closeModal();

          const userReference = doc(firestore, 'users', user.uid);
          const userDoc = await getDoc(userReference);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            dispatch(
              setUser({
                id: userData.id,
                email: userData.email,
                address: userData.address,
                name: userData.name,
                surname: userData.surname,
              }),
            );
          }
        } catch (error) {
          // TODO: call sentry?
          console.log(error);
          await signOut();
        }
      }
    },
    [dispatch, shouldAutoLogin, signOut, web3Auth?.loginModal],
  );

  useEffect(() => {
    const unsubscribe = firebaseAuth?.onIdTokenChanged(handleFirebaseTokenChange);

    return () => {
      unsubscribe && unsubscribe();
    };
  }, [handleFirebaseTokenChange]);

  useEffect(() => {
    const getNonceAndSignInWithCustomToken = async () => {
      const address = await getAccount();
      const network = await getChain();
      if (!web3user || !address || !network || isAuthenticated) return;

      try {
        const { nonce } = await apiGetNonce(address);
        const message = createSiweMessage(address, nonce, network.chainId);
        const signature = await signPersonalMessage(message.prepareMessage());
        if (!signature) return;
        const { token } = await apiVerifyMessage(address, message, signature);
        signInWithCustomToken(firebaseAuth, token);
      } catch (error) {
        if (error instanceof AxiosError && error?.response?.status === 608) {
          router.push('/no-membership');
          Toast({ variant: 'warning', message: t('no_membership_page.error') });
        } else {
          Toast({ variant: 'error', message: 'Something went Wrong' });
        }
        if (web3Auth) web3Auth.loginModal.closeModal();
        await logout();
        await signOut();
      }
    };
    if (shouldAutoLogin) {
      getNonceAndSignInWithCustomToken().catch(async () => {
        await signOut();
      });
    }
  }, [
    web3user,
    getAccount,
    getChain,
    signPersonalMessage,
    shouldAutoLogin,
    dispatch,
    logout,
    web3Auth,
    isAuthenticated,
    signOut,
    router,
    t,
  ]);

  useEffect(() => {
    const handleForceRefreshToken = setInterval(async () => {
      const user = firebaseAuth.currentUser;
      if (!user) return;
      const userReference = doc(firestore, 'users', user.uid);
      const userDoc = await getDoc(userReference);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData?.address) {
          const isMember = await apiInternalHasMembership(userData.address);
          if (!isMember) {
            await logout();
            await signOut();
          }
        }
      }
      await user.getIdToken(true);
    }, 59 * 60 * 1000); // force refresh token every 59 minutes (Firebase token lasts for max 1 hour)
    return () => clearInterval(handleForceRefreshToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>;
};
