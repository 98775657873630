import { Button, FormErrorMessage, Toast } from '@components';
import { AddReferralDto, apiAddReferral } from '@services/user';
import { AxiosError } from 'axios';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { RequiredValidation, EmailValidation } from '../../utils/form-validations';
import styles from './referral-form.module.scss';

const ReferralForm = () => {
  const { t } = useTranslation('common');
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddReferralDto>();

  const onSubmit = async (data: AddReferralDto) => {
    setIsFetching(true);

    await apiAddReferral(data)
      .then(() => {
        reset();
        Toast({
          variant: 'success',
          message: t('referral_form.success'),
        });
      })
      .catch((err: AxiosError) => {
        let errorKey = '';
        switch (err.response?.status) {
          case 409:
            errorKey = 'already_has_referral';
            break;
          case 500:
            errorKey = 'technical_error';
            break;
          default:
            errorKey = 'technical_error';
            break;
        }
        Toast({
          variant: 'error',
          message: `
        ${t(errorKey)}
        ${t('contact_support')}
        `,
        });
      })
      .finally(() => setIsFetching(false));
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t('referral_form.title')}</h2>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.inputsContainer}>
          <div className={styles.input}>
            <input
              placeholder={t('email')}
              type="text"
              id="email"
              {...register('email', {
                required: new RequiredValidation(),
                pattern: new EmailValidation(),
              })}
            />
            <FormErrorMessage errors={errors} name="email" />
          </div>
          <div className={styles.input}>
            <input
              {...register('name', { required: new RequiredValidation() })}
              type="text"
              id="name"
              name="name"
              placeholder={t('name')}
            />
            <FormErrorMessage errors={errors} name="name" />
          </div>
          <div className={styles.input}>
            <input
              {...register('surname', { required: new RequiredValidation() })}
              type="text"
              id="surname"
              name="surname"
              placeholder={t('surname')}
            />
            <FormErrorMessage errors={errors} name="surname" />
          </div>
        </div>
        <div className={styles.submitButton}>
          <Button variant="privatePrimary" isLoading={isFetching}>
            {t('invite')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ReferralForm;
