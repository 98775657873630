import { Tag } from '@components';
import classnames from 'classnames';
import { gsap } from 'gsap';
import { FC, useEffect, useRef } from 'react';
import { TokenizedCommunitySectionProps } from '@types';
import SVGMainShape0 from './shapes/svg-main-shape0';
import styles from './slide0.module.scss';

const Slide0: FC<TokenizedCommunitySectionProps> = ({
  isTablet,
  shouldAvoidMovementAnimations,
  tokenizedCommunityData,
}) => {
  // const firstLoadTl = useRef<GSAPTimeline>();
  const containerRef = useRef<HTMLDivElement>(null);
  const titleLine1Ref = useRef(null);
  const title1Part1Ref = useRef(null);
  const title1Part2Ref = useRef<HTMLDivElement>(null);
  const titleLine2Ref = useRef(null);
  const collectionRef = useRef(null);
  const tagRef = useRef(null);
  const subtitleRef = useRef(null);
  const mainShapeRef = useRef(null);
  const leftSmallTextRef = useRef(null);
  const rightSmallTextRef = useRef(null);

  useEffect(() => {
    gsap.set('#tokenizedShapeGradient', { attr: { x1: -2500, x2: 0 } });
    gsap.set(tagRef.current, { opacity: 0 });
    gsap.set(subtitleRef.current, { opacity: 0 });

    const inScrollTrigger = {
      trigger: containerRef.current,
      start: `-=${isTablet ? '125' : '100'}% top`,
      end: `-=${isTablet ? '55' : '25'}% top`,
      scrub: 2,
    };

    const inFancyAnimationTl = gsap
      .timeline({
        scrollTrigger: { ...inScrollTrigger, once: true },
      })
      .set(containerRef.current, { css: { marginTop: '10%' } })
      .titleIn(titleLine1Ref.current)
      .titleIn(titleLine2Ref.current, 0.5)
      .to(
        '#tokenizedShapeGradient',
        {
          duration: 2,
          attr: { x1: 1500, x2: 2000 },
          ease: 'shine',
        },
        0,
      )
      .to(tagRef.current, { opacity: 1, delay: 0.5 }, 0)
      .to(subtitleRef.current, { opacity: 1, delay: 0.5 }, 0)
      .paragraphFadeIn(rightSmallTextRef.current, { delay: 0.1 }, 0)
      .paragraphFadeIn(leftSmallTextRef.current, { delay: 0.1 }, 0);

    const inBasicTween = gsap.from(
      containerRef.current,
      shouldAvoidMovementAnimations
        ? {
            autoAlpha: 0,
            scrollTrigger: inScrollTrigger,
          }
        : {
            yPercent: 25,
            autoAlpha: 0,
            scrollTrigger: inScrollTrigger,
          },
    );

    return () => {
      inFancyAnimationTl.kill();
      inBasicTween.kill();
    };
  }, [isTablet, shouldAvoidMovementAnimations]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div ref={mainShapeRef} className={styles.mainShape}>
        <SVGMainShape0 isMobile={isTablet} />
      </div>
      <div ref={tagRef} className={styles.tag}>
        <Tag mode="dark" text={tokenizedCommunityData.tagDark} color="#CCC9F4" />
      </div>
      <div className={styles.textWrapper}>
        <div ref={titleLine1Ref} className={styles.titleLineFirst}>
          <div ref={title1Part1Ref} className={classnames(styles.titleSecondary, styles.title1)}>
            <div ref={leftSmallTextRef} className={classnames(styles.titleAppendix, styles.before)}>
              {tokenizedCommunityData.prefix}
            </div>
            {tokenizedCommunityData.titlePart1}
          </div>
          <div ref={title1Part2Ref} className={styles.titlePrimary}>
            {tokenizedCommunityData.titlePart2}
          </div>
        </div>
        <div ref={titleLine2Ref} className={styles.titleLine}>
          <div className={styles.titlePrimary}>{tokenizedCommunityData.titlePart3}</div>
          <div ref={collectionRef} className={styles.titleSecondary}>
            {tokenizedCommunityData.titlePart4}
          </div>
          <div ref={rightSmallTextRef} className={classnames(styles.titleAppendix, styles.after)}>
            {tokenizedCommunityData.suffix}
          </div>
        </div>
      </div>
      <h2 ref={subtitleRef} className={styles.subtitle}>
        {tokenizedCommunityData.subtitle}
      </h2>
    </div>
  );
};

export default Slide0;
