import { useAppSelector, useTabletMediaQuery } from '@hooks';
import { selectLeftText, selectRightText } from '@store/dom-slice';
import { gsap } from 'gsap';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';
import styles from './lateral-texts.module.scss';

const LateralTexts = () => {
  const { t } = useTranslation('common');
  const leftText = useAppSelector(selectLeftText);
  const rightText = useAppSelector(selectRightText);
  const ref = useRef(null);
  const leftTextRef = useRef(null);
  const rightTextRef = useRef(null);
  const isTablet = useTabletMediaQuery();

  useEffect(() => {
    gsap
      .timeline()
      .fromTo(ref.current, { opacity: 0 }, { opacity: 1, delay: 1.5 })
      .set(leftTextRef.current, { text: t(leftText) })
      .set(rightTextRef.current, {
        text: t('first_of_second', { first: rightText, second: '07' }),
      });
  }, [leftText, rightText, t]);

  useEffect(() => {
    if (isTablet) return;
    const fadeInOutAnimation = gsap
      .timeline()
      .swapText(leftTextRef.current, { text: t(leftText), duration: 1 })
      .swapText(
        rightTextRef.current,
        { text: t('first_of_second', { first: rightText, second: '07' }), duration: 1 },
        '<',
      );

    return () => {
      fadeInOutAnimation.kill();
    };
  }, [leftText, rightText]);

  return !isTablet ? (
    <div ref={ref}>
      <div ref={leftTextRef} className={styles.leftText}></div>
      <div ref={rightTextRef} className={styles.rightText}></div>
    </div>
  ) : null;
};

export default LateralTexts;
