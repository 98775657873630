import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth-slice';
import domReducer from './dom-slice';
import userReducer from './user-slice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    dom: domReducer,
  },
});

export default store;
