import { useAppDispatch, useAppSelector } from '@hooks';
import { selectIsNavbarModalOpen, setIsNavbarModalOpen } from '@store/dom-slice';
import { gsap } from 'gsap';
import { SVGProps, useEffect, useRef, useState } from 'react';
import styles from './hamburger-menu.module.scss';

const HamburgerMenu = ({ width = '42px', height = '42px' }: SVGProps<SVGElement>) => {
  const bar1 = useRef(null);
  const bar2 = useRef(null);
  const animation = useRef<GSAPTimeline>();
  const [active, setActive] = useState(false);
  const dispatch = useAppDispatch();
  const isNavbarModalOpen = useAppSelector(selectIsNavbarModalOpen);

  const handleOnClick = () => {
    if (active) {
      animation.current?.timeScale(2).reverse();
      setActive(false);
      dispatch(setIsNavbarModalOpen(false));
    } else {
      animation.current?.timeScale(2).play();
      setActive(true);
      dispatch(setIsNavbarModalOpen(true));
    }
  };

  useEffect(() => {
    if (!isNavbarModalOpen) {
      animation.current?.timeScale(2).reverse();
      setActive(false);
    }
  }, [isNavbarModalOpen]);

  useEffect(() => {
    animation.current = gsap
      .timeline({ paused: true })
      .add('join')
      .to(bar1.current, { y: 6 }, 'join')
      .to(bar2.current, { y: -6 }, 'join')
      .add('spin', '-=.1')
      .to(bar1.current, { rotate: 135, transformOrigin: 'center center' }, 'spin')
      .to(bar2.current, { rotate: 225, transformOrigin: 'center center' }, 'spin');
  }, []);

  return (
    <button onClick={handleOnClick} className={styles.button}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 42 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path ref={bar1} d="M1 1H41" stroke="#27272A" strokeWidth="2" strokeLinecap="round" />
        <path ref={bar2} d="M1 12H41" stroke="#27272A" strokeWidth="2" strokeLinecap="round" />
      </svg>
    </button>
  );
};

export default HamburgerMenu;
