import { useTabletMediaQuery } from '@hooks';
import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';
import ParallaxImage from '../parallax-image/parallax-image';

interface ParallaxProps {
  parentRef: any;
}

const height = -400;

const Parallax = ({ parentRef }: ParallaxProps) => {
  const tl = useRef<GSAPTimeline>();
  const isTablet = useTabletMediaQuery();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const ref10 = useRef(null);
  const ref11 = useRef(null);
  const ref12 = useRef(null);
  const ref13 = useRef(null);
  const ref14 = useRef(null);
  const ref15 = useRef(null);
  const ref16 = useRef(null);

  useEffect(() => {
    tl.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: parentRef.current,
          start: '+=10% top',
          end: '+=550%',
          scrub: 2,
          toggleActions: 'restart pause revert pause',
        },
        ease: 'none',
      })
      .set(
        [
          ref1.current,
          ref2.current,
          ref3.current,
          ref4.current,
          ref5.current,
          ref6.current,
          ref7.current,
          ref8.current,
        ],
        {
          autoAlpha: 1,
        },
      )
      .set(
        [
          ref9.current,
          ref10.current,
          ref11.current,
          ref12.current,
          ref13.current,
          ref14.current,
          ref15.current,
          ref16.current,
        ],
        { autoAlpha: 0.1 },
      )
      .to(ref1.current, { y: `${height}vh`, duration: 1 }, 0)
      .to(ref2.current, { y: `${height - 50}vh`, duration: 1 }, 0)
      .to(ref3.current, { y: `${height + 50}vh`, duration: 1 }, 0)
      .to(ref4.current, { y: `${height}vh`, duration: 1 }, 0)
      .to(ref5.current, { y: `${height + 25}vh`, duration: 1 }, 0)
      .to(ref6.current, { y: `${height + 15}vh`, duration: 1 }, 0)
      .to(ref7.current, { y: `${height}vh`, duration: 1 }, 0)
      .to(ref8.current, { y: `${height}vh`, duration: 1 }, 0)
      .to(ref9.current, { y: `${height - 50}vh`, duration: 1 }, 0)
      .to(ref10.current, { y: `${height - 100}vh`, duration: 1 }, 0)
      .to(ref11.current, { y: `${height - 150}vh`, duration: 1 }, 0)
      .to(ref12.current, { y: `${height}vh`, duration: 1 }, 0)
      .to(ref13.current, { y: `${height}vh`, duration: 1 }, 0) // -50
      .to(ref14.current, { y: `${height}vh`, duration: 1 }, 0) // -100
      .to(ref15.current, { y: `${height - 75}vh`, duration: 1 }, 0)
      .to(ref16.current, { y: `${height - 15}vh`, duration: 1 }, 0);

    return () => {
      tl.current?.kill();
    };
  }, [parentRef]);

  return (
    <>
      <ParallaxImage
        ref={ref1}
        src={
          isTablet
            ? '/videos/YAEL X MANOR_10000-0192_400.mp4'
            : '/videos/YAEL X MANOR_10000-0192_720.mp4'
        }
        styles={{ top: '125vh', left: '57.29%', zIndex: 3 }}
        text={'image 1'}
        srcType="video"
      />
      <ParallaxImage
        ref={ref2}
        src={
          isTablet
            ? '/videos/YAEL X MANOR_20000-0192_400.mp4'
            : '/videos/YAEL X MANOR_20000-0192_720.mp4'
        }
        styles={{ top: `${125 * (1 + 0.4081)}vh`, left: '11.87%', zIndex: 3 }}
        text={'image 2'}
        srcType="video"
      />
      <ParallaxImage
        ref={ref3}
        src={
          isTablet
            ? '/videos/YAEL X MANOR_30000-0192_400.mp4'
            : '/videos/YAEL X MANOR_30000-0192_720.mp4'
        }
        styles={{ top: `${125 * (1 + 0.3)}vh`, left: '73.69%', zIndex: 3 }}
        text={'image 3'}
        srcType="video"
      />
      <ParallaxImage
        ref={ref4}
        src={
          isTablet
            ? '/videos/YAEL X MANOR_40000-0192_400.mp4'
            : '/videos/YAEL X MANOR_40000-0192_720.mp4'
        }
        styles={{ top: `${125 * (1 + 1)}vh`, left: '35.05%', zIndex: 3 }}
        text={'image 4'}
        srcType="video"
      />
      <ParallaxImage
        ref={ref5}
        src={
          isTablet
            ? '/videos/YAEL X MANOR_50000-0192_400.mp4'
            : '/videos/YAEL X MANOR_50000-0192_720.mp4'
        }
        styles={{ top: `${125 * (1 + 1)}vh`, left: '65.98%', zIndex: 3 }}
        text={'image 5'}
        srcType="video"
      />
      <ParallaxImage
        ref={ref6}
        src={
          isTablet
            ? '/videos/YAEL X MANOR_60000-0192_400.mp4'
            : '/videos/YAEL X MANOR_60000-0192_720.mp4'
        }
        styles={{ top: `${125 * (1 + 1.3877)}vh`, left: '11.87%', zIndex: 3 }}
        text={'image 6'}
        srcType="video"
      />
      <ParallaxImage
        ref={ref7}
        src={
          isTablet
            ? '/videos/YAEL X MANOR_70000-0192_400.mp4'
            : '/videos/YAEL X MANOR_70000-0192_720.mp4'
        }
        styles={{ top: `${125 * (1 + 2)}vh`, left: '73.69%', zIndex: 3 }}
        text={'image 7'}
        srcType="video"
      />
      {!isTablet ? (
        <ParallaxImage
          ref={ref8}
          src={
            isTablet
              ? '/videos/YAEL X MANOR_80000-0192_400.mp4'
              : '/videos/YAEL X MANOR_80000-0192_720.mp4'
          }
          styles={{ top: `${125 * (1 + 2.2408)}vh`, left: '41.77%', zIndex: 3 }}
          text={'image 8'}
          srcType="video"
        />
      ) : null}
      <ParallaxImage
        ref={ref9}
        src="/images/parallax-image.png"
        styles={{ top: `${125 * (1 - 0.0861)}vh`, left: '4.16%' }}
        text={'image 9'}
        srcType="image"
      />
      {!isTablet ? (
        <>
          <ParallaxImage
            ref={ref10}
            src="/images/parallax-image.png"
            styles={{ top: `${125 * (1 + 0.0861)}vh`, left: '80.36%' }}
            text={'image 10'}
            srcType="image"
          />
          <ParallaxImage
            ref={ref11}
            src="/images/parallax-image.png"
            styles={{ top: `${125 * (1 + 0.3183)}vh`, left: '42.81%' }}
            text={'image 11'}
            srcType="image"
          />
          <ParallaxImage
            ref={ref12}
            src="/images/parallax-image.png"
            styles={{ top: `${125 * (1 + 1.0204)}vh`, left: '80.36%' }}
            text={'image 12'}
            srcType="image"
          />
        </>
      ) : null}

      <ParallaxImage
        ref={ref13}
        src="/images/parallax-image.png"
        styles={{ top: `${125 * (1 + 1.1224)}vh`, left: '4.16%' }}
        text={'image 13'}
        srcType="image"
      />
      <ParallaxImage
        ref={ref14}
        src="/images/parallax-image.png"
        styles={{ top: `${125 * (1 + 1.7551)}vh`, left: '49.73%' }}
        text={'image 14'}
        srcType="image"
      />
      {!isTablet ? (
        <>
          <ParallaxImage
            ref={ref15}
            src="/images/parallax-image.png"
            styles={{ top: `${125 * (1 + 2.204)}vh`, left: '11.87%' }}
            text={'image 15'}
            srcType="image"
          />
          <ParallaxImage
            ref={ref16}
            src="/images/parallax-image.png"
            styles={{ top: `${125 * (1 + 2.4489)}vh`, left: '69.98%' }}
            text={'image 16'}
            srcType="image"
          />
        </>
      ) : null}
    </>
  );
};

export default Parallax;
