const SVGTopBigShape = () => {
  return (
    <svg
      width="2241"
      height="2001"
      viewBox="0 0 2241 2001"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2240.33 1892.37C2185.2 1903.98 2139.15 1917.99 2094.07 1922.37C1970.66 1934.34 1847.81 1940.73 1724.44 1952.24C1621.96 1961.78 1518.44 1979.63 1416.48 1986.19C1282.42 1994.81 1149.36 1996.13 1015.96 2000.12C1006.86 2000.39 996.655 1998.71 990.077 1994.65C913.217 1947.16 817.633 1934.1 718.061 1931.92C644.939 1930.32 596.689 1900.78 561.513 1857.27C472.81 1747.46 387.033 1635.7 301.983 1523.52C209.099 1401 121.895 1274.79 25.0237 1154.95C-17.2975 1102.57 1.19512 1048.12 29.6589 991.154C112.754 824.863 243.845 675.638 352.296 518.393C443.658 385.956 545.343 257.393 641.969 126.913C658.691 104.32 671.437 79.9904 688.838 57.7679C702.695 40.0622 719.875 13.9175 737.309 9.85487C776.302 0.766153 823.249 -12.6737 840.511 31.3193C851.786 60.0637 868.024 86.8518 884.239 113.255C973.439 258.486 1059.64 405.499 1154.5 547.472C1204.12 621.807 1270.15 686.44 1327.38 756.387C1393.6 837.33 1455.61 921.219 1524.3 1000.38C1635.02 1128.08 1755.57 1249.14 1860.84 1380.35C1941.87 1481.35 2003.86 1594.83 2078.21 1700.39C2117.74 1756.5 2165.45 1807.65 2209.73 1860.86C2217.02 1869.56 2225.66 1877.33 2240.33 1892.37Z"
        fill="#FFC93E"
      />
    </svg>
  );
};

export default SVGTopBigShape;
