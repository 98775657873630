import { SerializedPortableText, Tag } from '@components';
import { gsap } from 'gsap';
import { FC, useEffect, useRef } from 'react';
import { TokenizedCommunitySectionProps } from '../../../../../types';
import SVGGraphicShape from './shapes/svg-graphic-shape';
import SVGMainShape1 from './shapes/svg-main-shape1';
import styles from './slide1.module.scss';

const Slide1: FC<TokenizedCommunitySectionProps> = ({
  isTablet = false,
  isDesktop = false,
  shouldAvoidMovementAnimations,
  tokenizedCommunityData,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const tagRef = useRef<HTMLDivElement>(null);
  const mainShapeRef = useRef<HTMLDivElement>(null);
  const graphicShapeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const inAnimationTL = gsap
      .timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: `${isTablet || isDesktop ? '-=150%' : '-=200%'} top`,
          end: '-=25% top',
          toggleActions: 'play none none reverse',
          scrub: 2,
          once: true,
        },
      })
      .paragraphFadeIn(textRef.current, { duration: 1, delay: 0.1 }, 0)
      .paragraphFadeIn(tagRef.current, { duration: 1, delay: 0.1 }, 0)
      .from(
        containerRef.current,
        shouldAvoidMovementAnimations
          ? {
              autoAlpha: 0,
            }
          : {
              yPercent: 25,
              autoAlpha: 0,
            },
        0,
      )
      .from(graphicShapeRef.current, { autoAlpha: 0, duration: 1, delay: 0.1 }, 1)
      .from(mainShapeRef.current, { autoAlpha: 0, yPercent: 100, duration: 2, delay: 0 }, 0);

    const outAnimationTL = gsap
      .timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: '+=25% top',
          scrub: 2,
        },
      })
      .to(
        containerRef.current,
        shouldAvoidMovementAnimations
          ? {
              autoAlpha: 0,
            }
          : {
              autoAlpha: 0,
              yPercent: -50,
            },
      );

    return () => {
      inAnimationTL.kill();
      outAnimationTL.kill();
    };
  }, [isDesktop, isTablet, shouldAvoidMovementAnimations]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.text}>
          <div ref={tagRef} className={styles.tag}>
            <Tag text={tokenizedCommunityData.tagLight} mode="light" />
          </div>
          <div ref={textRef}>
            <SerializedPortableText value={tokenizedCommunityData.text} />
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.mainShape} ref={mainShapeRef}>
          <SVGMainShape1 isMobile={isTablet} />
        </div>
        <div className={styles.graphicShape} ref={graphicShapeRef}>
          <SVGGraphicShape isMobile={isTablet} />
        </div>
      </div>
    </div>
  );
};

export default Slide1;
