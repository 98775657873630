import { Button, Tag } from '@components';
import { SVGArrowSolid } from '@components/svgs';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import styles from './footer.module.scss';

const Footer = () => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.footer}>
      <div className={styles.innerWrapper}>
        {/* CONTENT */}
        <div className={styles.contentWrapper}>
          <div className={styles.poweredBy}>
            <div>
              <Tag mode="dark" text={t('powered_by')} outlined />
            </div>
            <div className={styles.vary}>
              <a href="https://vary.ar" target="_blank" rel="noreferrer">
                <Image
                  src="/svgs/vary-logo.svg"
                  alt="VARY logo"
                  width={185}
                  height={52}
                  layout="fixed"
                />
              </a>
            </div>
          </div>
          <div className={styles.shapeWrapper}>
            <Image src="/svgs/footer-shapes.svg" alt="Shape" width={52} height={58} />{' '}
          </div>
          <div className={styles.subFooterWrapper}>
            <div className={styles.moreAboutManor}>
              <Tag mode="dark" text={t('more_about_manor')} outlined />
            </div>
            <div className={styles.buttonsWrapper}>
              <a
                href={`mailto:${process.env.NEXT_PUBLIC_COMMUNITY_EMAIL}`}
                target="_blank"
                rel="noreferrer">
                <Button variant="tertiary" color="#E3FC5D">
                  {t('contact')}
                </Button>
              </a>
              <Link href="/terms">
                <a>
                  <Button variant="tertiary" color="#E3FC5D">
                    {t('terms')}
                  </Button>
                </a>
              </Link>
              <Link href="/privacy">
                <a>
                  <Button variant="tertiary" color="#E3FC5D">
                    {t('privacy')}
                  </Button>
                </a>
              </Link>
            </div>

            <div className={styles.followUsWrapper}>
              {/* <div className={styles.followUsText}>{t('follow_us')}:</div>  */}
              <div className={styles.followUsText}></div>
              <div className={styles.iconsWrapper}>
                <a
                  href={`mailto:${process.env.NEXT_PUBLIC_SUPPORT_EMAIL}`}
                  target="_blank"
                  rel="noreferrer">
                  <Button variant="privatePrimary">{t('help')}</Button>
                </a>
                {/* <div className={styles.icon}>
                  <Icon name={IconNames.instagram} />
                </div>
                <div className={styles.icon}>
                  <Icon name={IconNames.twitter} />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* COPYRIGHT */}
        <div className={styles.copyRight}>
          <div>
            <SVGArrowSolid color="#E3FC5D" />
          </div>
          <div>
            © {new Date().getFullYear()} Manor AG.{' '}
            <span className={styles.allRightsReserved}>{t('all_rights_reserved')}</span>.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
