import { forwardRef, ForwardedRef } from 'react';
import { SVGExtendedProps } from '@types';

const SVGTopShape = forwardRef(
  ({ color = '#E3FC5D' }: SVGExtendedProps, ref: ForwardedRef<SVGSVGElement> | undefined) => {
    return (
      <svg
        ref={ref}
        width="581"
        height="506"
        viewBox="0 0 581 506"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.22"
          d="M206.115 502.952C249.493 510.513 280.492 502.212 311.016 495.055C378.639 479.246 434.908 442.845 477.882 389.93C499.617 363.184 514.265 330.127 528.707 298.383C546.83 258.61 561.431 217.141 577.65 176.522C583.606 161.602 581.797 154.828 568.308 148.034C521.382 124.285 475.036 99.2419 427.128 77.5998C376.834 54.8756 325.466 34.2336 273.648 15.0576C234.655 0.609453 193.623 -4.3895 152.837 7.69393C137.813 12.1441 123.669 20.3784 110.049 28.4186C67.4441 53.5908 37.9904 91.6138 21.2943 136.111C5.08462 178.943 -1.78979 224.743 1.13099 270.446C4.50221 323.896 22.1399 370.839 51.9962 413.497C75.7825 447.493 109.818 471.516 147.806 487.832C169.48 497.162 193.907 499.954 206.115 502.952Z"
          fill={color}
        />
      </svg>
    );
  },
);
SVGTopShape.displayName = 'SVGTopShape';

export default SVGTopShape;
