import { Logo120Years } from '@components/svgs';
import { gsap } from 'gsap';
import { useEffect, useRef, useState } from 'react';
import { useDesktopMediaQuery, useLargeDesktopMediaQuery, useXlDesktopMediaQuery } from '@hooks';
import styles from './manor-logo.module.scss';

const ManorLogo = () => {
  const logoRef = useRef(null);
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [height, setHeight] = useState<number | undefined>(undefined);
  const isXlDesktop = useXlDesktopMediaQuery();
  const isLargeDesktop = useLargeDesktopMediaQuery();
  const isDesktop = useDesktopMediaQuery();

  useEffect(() => {
    gsap.fromTo(logoRef.current, { opacity: 0 }, { opacity: 1, delay: 1.5 });
  }, []);

  useEffect(() => {
    if (isDesktop) {
      setWidth(85);
      setHeight(50);
    } else if (isLargeDesktop) {
      setWidth(105);
      setHeight(65);
    } else if (isXlDesktop) {
      setWidth(135);
      setHeight(85);
    } else {
      setWidth(165);
      setHeight(100);
    }
  }, [isDesktop, isLargeDesktop, isXlDesktop]);

  return (
    <div ref={logoRef} className={styles.logo120years}>
      <Logo120Years width={width} height={height} />
    </div>
  );
};

export default ManorLogo;
