import { useRouter } from 'next/router';
import { SVGProps } from 'react';

const Logo120Years = ({ width = '69px', height = '43px' }: SVGProps<SVGElement>) => {
  const { locale } = useRouter();
  switch (locale) {
    case 'en':
    case 'fr':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 165 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2164_8415)">
            <path
              d="M131.747 0.211914C112.917 0.211914 99.7578 12.4746 99.7578 30.5489V65.4384H131.107C151.719 65.4384 164.994 51.5289 164.994 32.4424C164.994 13.3559 151.478 0.211914 131.747 0.211914ZM110.575 60.216L109.931 58.2167H106.013L105.338 60.216H103.066L106.838 50.1792H109.306L112.982 60.216H110.58H110.575ZM122.999 60.216H120.632L117.248 54.7872C116.941 54.2886 116.618 53.6894 116.392 53.2411H116.362C116.377 53.6742 116.412 54.193 116.412 54.6764V60.2211H114.448V50.1843H116.785L120.169 55.6131C120.476 56.1117 120.829 56.7714 121.04 57.2397H121.09C121.06 56.7865 121.04 56.162 121.04 55.7088V50.1792H123.004V60.216H122.999ZM128.09 60.347C126.831 60.347 125.834 60.1203 125.064 59.7829V57.8642C125.789 58.2016 126.721 58.539 127.688 58.539C128.801 58.539 129.365 58.1513 129.365 57.411C129.365 56.5397 128.609 56.444 127.093 55.7843C126.061 55.316 125.079 54.5908 125.079 52.9138C125.079 50.9649 126.544 50.0433 128.574 50.0433C129.652 50.0433 130.573 50.3001 131.087 50.4965V52.3649C130.573 52.1232 129.652 51.8311 128.735 51.8311C127.738 51.8311 127.27 52.2037 127.27 52.8483C127.27 53.5232 127.834 53.7347 128.851 54.1224C130.155 54.7822 131.671 55.0743 131.671 57.3304C131.671 59.2793 130.14 60.3419 128.095 60.3419L128.09 60.347Z"
              fill="#BAB8D3"
            />
            <path
              d="M108.017 52.2236H107.986C107.856 52.6769 107.664 53.2862 107.473 53.8654L106.602 56.4438H109.356L108.52 53.8654C108.329 53.2711 108.133 52.6869 108.022 52.2236H108.017Z"
              fill="#BAB8D3"
            />
            <path
              d="M164.997 74.1701C164.997 72.5737 163.808 71.3398 162.076 71.3398C160.343 71.3398 159.266 72.4175 159.266 74.0039V77.0658H162.015C163.823 77.0658 164.992 75.8471 164.992 74.1701H164.997Z"
              fill="#BAB8D3"
            />
            <path
              d="M154.502 71.3449C152.906 71.3449 151.672 72.5283 151.672 74.2607C151.672 75.9931 152.75 77.0658 154.336 77.0658H157.398V74.3161C157.398 72.5082 156.174 71.3398 154.502 71.3398V71.3449Z"
              fill="#BAB8D3"
            />
            <path
              d="M151.677 81.84C151.677 83.4365 152.865 84.6703 154.593 84.6703C156.32 84.6703 157.398 83.5926 157.398 82.0062V78.9443H154.648C152.84 78.9443 151.672 80.1631 151.672 81.84H151.677Z"
              fill="#BAB8D3"
            />
            <path
              d="M162.335 78.9395H159.273V81.6891C159.273 83.497 160.497 84.6604 162.169 84.6604C163.841 84.6604 164.999 83.4719 164.999 81.7445C164.999 80.0172 163.922 78.9395 162.335 78.9395V78.9395Z"
              fill="#BAB8D3"
            />
            <path
              d="M147.348 85.4712C147.348 80.4503 143.249 77.0762 137.15 77.0762H126.398V99.99H130.714V81.0848H137.15C140.781 81.0848 143.037 82.8172 143.037 85.6072C143.037 88.9259 140.696 90.5626 135.876 90.5928V91.036L142.917 99.9951H148.255L142.509 92.6626L142.68 92.577C145.913 91.031 147.353 88.8403 147.353 85.4712H147.348Z"
              fill="#BAB8D3"
            />
            <path
              d="M108.116 100.736C114.96 100.736 120.319 95.3773 120.319 88.5333C120.319 81.6894 114.96 76.3311 108.116 76.3311C101.272 76.3311 95.9141 81.6894 95.9141 88.5333C95.9141 95.3773 101.272 100.736 108.116 100.736ZM108.116 80.3448C112.608 80.3448 115.998 83.8649 115.998 88.5383C115.998 93.2118 112.608 96.7269 108.116 96.7269C103.624 96.7269 100.235 93.2067 100.235 88.5383C100.235 83.87 103.624 80.3448 108.116 80.3448Z"
              fill="#BAB8D3"
            />
            <path
              d="M89.447 100.272H89.8851V77.0758H85.5692V91.4637L67.9381 76.7988H67.5V99.9897H71.8159V85.6521L89.447 100.272Z"
              fill="#BAB8D3"
            />
            <path
              d="M26.3585 76.7988L17.2131 92.5112L8.06266 76.7988H7.61949L0 99.9897H4.48205L8.75763 86.9162L8.89864 86.4881L16.9764 100.272H17.4448L25.5225 86.4881L29.9391 99.9897H34.4262L26.8067 76.7988H26.3585Z"
              fill="#BAB8D3"
            />
            <path
              d="M44.1699 95.7748H56.3672L58.4219 99.9748H63.0399L50.6614 76.7588H49.8707L37.4922 99.9748H42.1102L44.1699 95.7748V95.7748ZM50.2686 84.0509L54.5542 92.1086H45.9879L50.2736 84.0509H50.2686Z"
              fill="#BAB8D3"
            />
            <path
              d="M33.8672 65.9567V2.3418H7.93676L0 17.3189H7.93676V65.9567H33.8672Z"
              fill="#BAB8D3"
            />
            <path
              d="M87.1798 44.7853C90.2971 41.3558 92.5482 37.1305 93.6158 32.4571C94.0238 30.6643 94.2605 28.806 94.3007 26.8974C94.2856 26.2981 94.2554 25.7038 94.2051 25.1196C92.9964 11.044 81.197 0 66.8142 0C52.4313 0 39.9722 11.6735 39.3427 26.3031H66.8142L39.3125 44.8407V65.438H94.3159V44.9011C94.3159 44.9011 93.4144 44.8709 92.1353 44.8407C90.7101 44.8105 88.8216 44.7752 87.1798 44.7802V44.7853Z"
              fill="#BAB8D3"
            />
            <path
              d="M106.842 50.1738L103.07 60.2106H105.342L106.016 58.2113H109.934L110.579 60.2106H112.981L109.305 50.1738H106.837H106.842ZM106.601 56.4437L107.472 53.8652C107.663 53.2861 107.86 52.6717 107.985 52.2235H108.016C108.126 52.6918 108.323 53.271 108.514 53.8652L109.35 56.4437H106.596H106.601Z"
              fill="white"
            />
            <path
              d="M121.037 55.7034C121.037 56.1566 121.053 56.7811 121.088 57.2343H121.037C120.826 56.766 120.473 56.1063 120.166 55.6077L116.782 50.1789H114.445V60.2156H116.409V54.671C116.409 54.1875 116.379 53.6739 116.359 53.2357H116.389C116.616 53.689 116.938 54.2832 117.245 54.7818L120.63 60.2106H122.996V50.1738H121.032V55.7034H121.037Z"
              fill="white"
            />
            <path
              d="M128.85 54.1221C127.832 53.7344 127.268 53.5279 127.268 52.848C127.268 52.1682 127.737 51.8308 128.734 51.8308C129.65 51.8308 130.572 52.1228 131.086 52.3646V50.4962C130.572 50.3048 129.65 50.043 128.573 50.043C126.543 50.043 125.078 50.9595 125.078 52.9135C125.078 54.5905 126.06 55.3157 127.092 55.784C128.608 56.4437 129.363 56.5394 129.363 57.4107C129.363 58.151 128.799 58.5387 127.686 58.5387C126.719 58.5387 125.783 58.2013 125.062 57.8639V59.7826C125.838 60.12 126.835 60.3467 128.089 60.3467C130.134 60.3467 131.665 59.2841 131.665 57.3351C131.665 55.079 130.149 54.7869 128.845 54.1272L128.85 54.1221Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_2164_8415">
              <rect width="165" height="100.735" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'de':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 165 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2164_8392)">
            <path
              d="M131.747 0.211914C112.917 0.211914 99.7578 12.4746 99.7578 30.5489V65.4384H131.107C151.719 65.4384 164.994 51.5289 164.994 32.4424C164.994 13.3559 151.478 0.211914 131.747 0.211914Z"
              fill="#BAB8D3"
            />
            <path
              d="M164.997 74.1701C164.997 72.5737 163.808 71.3398 162.076 71.3398C160.343 71.3398 159.266 72.4175 159.266 74.0039V77.0658H162.015C163.823 77.0658 164.992 75.8471 164.992 74.1701H164.997Z"
              fill="#BAB8D3"
            />
            <path
              d="M154.336 77.0716H157.398V74.322C157.398 72.5141 156.174 71.3457 154.502 71.3457C152.83 71.3457 151.672 72.5292 151.672 74.2616C151.672 75.9939 152.75 77.0666 154.336 77.0666V77.0716Z"
              fill="#BAB8D3"
            />
            <path
              d="M151.677 81.84C151.677 83.4365 152.865 84.6703 154.593 84.6703C156.32 84.6703 157.398 83.5926 157.398 82.0062V78.9443H154.648C152.84 78.9443 151.672 80.1631 151.672 81.84H151.677Z"
              fill="#BAB8D3"
            />
            <path
              d="M162.335 78.9395H159.273V81.6891C159.273 83.497 160.497 84.6604 162.169 84.6604C163.841 84.6604 164.999 83.4719 164.999 81.7445C164.999 80.0172 163.922 78.9395 162.335 78.9395V78.9395Z"
              fill="#BAB8D3"
            />
            <path
              d="M142.675 92.577C145.908 91.031 147.348 88.8403 147.348 85.4712C147.348 80.4503 143.249 77.0762 137.15 77.0762H126.398V99.99H130.714V81.0848H137.15C140.781 81.0848 143.037 82.8172 143.037 85.6072C143.037 88.9259 140.696 90.5626 135.876 90.5928V91.036L142.917 99.9951H148.255L142.509 92.6626L142.68 92.577H142.675Z"
              fill="#BAB8D3"
            />
            <path
              d="M108.116 76.3311C101.272 76.3311 95.9141 81.6894 95.9141 88.5333C95.9141 95.3773 101.272 100.736 108.116 100.736C114.96 100.736 120.319 95.3773 120.319 88.5333C120.319 81.6894 114.96 76.3311 108.116 76.3311ZM108.116 96.7219C103.624 96.7219 100.235 93.2017 100.235 88.5333C100.235 83.8649 103.624 80.3397 108.116 80.3397C112.608 80.3397 115.998 83.8599 115.998 88.5333C115.998 93.2067 112.608 96.7219 108.116 96.7219Z"
              fill="#BAB8D3"
            />
            <path
              d="M85.5692 91.4637L67.9381 76.7988H67.5V99.9897H71.8159V85.6521L89.447 100.272H89.8851V77.0758H85.5692V91.4637Z"
              fill="#BAB8D3"
            />
            <path
              d="M26.3585 76.7988L17.2131 92.5112L8.06266 76.7988H7.61949L0 99.9897H4.48205L8.75763 86.9162L8.89864 86.4881L16.9764 100.272H17.4448L25.5225 86.4881L29.9391 99.9897H34.4262L26.8067 76.7988H26.3585Z"
              fill="#BAB8D3"
            />
            <path
              d="M49.8785 76.7588L37.5 99.9748H42.118L44.1778 95.7748H56.375L58.4297 99.9748H63.0477L50.6692 76.7588H49.8785V76.7588ZM45.9907 92.1086L50.2764 84.0509L54.562 92.1086H45.9958H45.9907Z"
              fill="#BAB8D3"
            />
            <path
              d="M33.8672 65.9567V2.3418H7.93676L0 17.3189H7.93676V65.9567H33.8672Z"
              fill="#BAB8D3"
            />
            <path
              d="M87.1798 44.7853C90.2971 41.3558 92.5482 37.1305 93.6158 32.4571C94.0238 30.6643 94.2605 28.806 94.3007 26.8974C94.2856 26.2981 94.2554 25.7038 94.2051 25.1196C92.9964 11.044 81.197 0 66.8142 0C52.4313 0 39.9722 11.6735 39.3427 26.3031H66.8142L39.3125 44.8407V65.438H94.3159V44.9011C94.3159 44.9011 93.4144 44.8709 92.1353 44.8457C90.7101 44.8155 88.8216 44.7802 87.1798 44.7853V44.7853Z"
              fill="#BAB8D3"
            />
            <path
              d="M102.625 59.0476V57.6476C102.842 57.703 103.189 57.7533 103.421 57.7533C104.025 57.7533 104.378 57.4713 104.378 56.8771V48.5273H106.221V57.008C106.221 58.5692 105.224 59.2289 103.944 59.2289C103.421 59.2289 102.978 59.1483 102.625 59.0425V59.0476Z"
              fill="white"
            />
            <path
              d="M110.582 48.5322H112.642L115.709 56.9222H113.705L113.166 55.2553H109.892L109.328 56.9222H107.43L110.577 48.5322H110.582ZM112.682 53.7697L111.982 51.6143C111.821 51.1157 111.66 50.6322 111.564 50.2394H111.539C111.433 50.6171 111.272 51.1258 111.106 51.6143L110.381 53.7697H112.682V53.7697Z"
              fill="white"
            />
            <path
              d="M116.922 48.5322H118.765V51.8711H122.24V48.5322H124.083V56.9222H122.24V53.3567H118.765V56.9222H116.922V48.5322Z"
              fill="white"
            />
            <path
              d="M130.396 55.8848L128.9 53.5682H128.054V56.9222H126.211V48.5322H129.484C131.207 48.5322 132.229 49.4991 132.229 51.0653C132.229 51.9013 131.796 52.8028 130.683 53.2308L132.002 55.1697C132.229 55.5071 132.476 55.5877 132.743 55.5877C132.823 55.5877 132.904 55.5877 132.984 55.5726V56.9474C132.798 57.0028 132.581 57.028 132.365 57.028C131.559 57.028 131.005 56.8114 130.401 55.8848H130.396ZM129.132 52.3042C129.968 52.3042 130.356 51.8207 130.356 51.1459C130.356 50.4308 129.978 49.9876 129.132 49.9876H128.054V52.3042H129.132Z"
              fill="white"
            />
            <path
              d="M134.289 48.5324H139.406V50.0533H136.132V51.8561H138.917V53.2562H136.132V55.3965H139.406V56.9173H134.289V48.5273V48.5324Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_2164_8392">
              <rect width="165" height="100.735" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'it':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 165 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2164_8434)">
            <path
              d="M131.747 0.211914C112.917 0.211914 99.7578 12.4746 99.7578 30.5489V65.4384H131.107C151.719 65.4384 164.994 51.5289 164.994 32.4424C164.994 13.3559 151.478 0.211914 131.747 0.211914ZM109.769 60.2664L109.205 58.5189H105.781L105.187 60.2664H103.197L106.496 51.4836H108.651L111.864 60.2664H109.764H109.769ZM120.642 60.2664H118.567L115.606 55.5124C115.339 55.0743 115.057 54.5555 114.861 54.1577H114.831C114.846 54.5404 114.871 54.9886 114.871 55.4117V60.2614H113.149V51.4785H115.193L118.154 56.2325C118.421 56.6707 118.734 57.2498 118.915 57.6577H118.955C118.925 57.2649 118.915 56.711 118.915 56.3182V51.4836H120.637V60.2664H120.642ZM130.372 60.2664H128.297L125.336 55.5124C125.069 55.0743 124.787 54.5555 124.59 54.1577H124.56C124.575 54.5404 124.6 54.9886 124.6 55.4117V60.2614H122.878V51.4785H124.923L127.884 56.2325C128.151 56.6707 128.463 57.2498 128.644 57.6577H128.685C128.654 57.2649 128.644 56.711 128.644 56.3182V51.4836H130.367V60.2664H130.372ZM134.572 60.2664H132.638V51.4836H134.572V60.2664Z"
              fill="#BAB8D3"
            />
            <path
              d="M107.525 53.2705H107.495C107.384 53.6633 107.213 54.2022 107.042 54.7108L106.281 56.9669H108.693L107.958 54.7108C107.787 54.1871 107.621 53.6835 107.52 53.2705H107.525Z"
              fill="#BAB8D3"
            />
            <path
              d="M164.997 74.1701C164.997 72.5737 163.808 71.3398 162.076 71.3398C160.343 71.3398 159.266 72.4175 159.266 74.0039V77.0658H162.015C163.823 77.0658 164.992 75.8471 164.992 74.1701H164.997Z"
              fill="#BAB8D3"
            />
            <path
              d="M154.502 71.3449C152.906 71.3449 151.672 72.5283 151.672 74.2607C151.672 75.9931 152.75 77.0658 154.336 77.0658H157.398V74.3161C157.398 72.5082 156.174 71.3398 154.502 71.3398V71.3449Z"
              fill="#BAB8D3"
            />
            <path
              d="M151.677 81.84C151.677 83.4365 152.865 84.6703 154.593 84.6703C156.32 84.6703 157.398 83.5926 157.398 82.0062V78.9443H154.648C152.84 78.9443 151.672 80.1631 151.672 81.84H151.677Z"
              fill="#BAB8D3"
            />
            <path
              d="M162.335 78.9395H159.273V81.6891C159.273 83.497 160.497 84.6604 162.169 84.6604C163.841 84.6604 164.999 83.4719 164.999 81.7445C164.999 80.0172 163.922 78.9395 162.335 78.9395V78.9395Z"
              fill="#BAB8D3"
            />
            <path
              d="M147.348 85.4712C147.348 80.4503 143.249 77.0762 137.15 77.0762H126.398V99.99H130.714V81.0848H137.15C140.781 81.0848 143.037 82.8172 143.037 85.6072C143.037 88.9259 140.696 90.5626 135.876 90.5928V91.036L142.917 99.9951H148.255L142.509 92.6626L142.68 92.577C145.913 91.031 147.353 88.8403 147.353 85.4712H147.348Z"
              fill="#BAB8D3"
            />
            <path
              d="M108.116 100.736C114.96 100.736 120.319 95.3773 120.319 88.5333C120.319 81.6894 114.96 76.3311 108.116 76.3311C101.272 76.3311 95.9141 81.6894 95.9141 88.5333C95.9141 95.3773 101.272 100.736 108.116 100.736ZM108.116 80.3448C112.608 80.3448 115.998 83.8649 115.998 88.5383C115.998 93.2118 112.608 96.7269 108.116 96.7269C103.624 96.7269 100.235 93.2067 100.235 88.5383C100.235 83.87 103.624 80.3448 108.116 80.3448Z"
              fill="#BAB8D3"
            />
            <path
              d="M89.447 100.272H89.8851V77.0758H85.5692V91.4637L67.9381 76.7988H67.5V99.9897H71.8159V85.6521L89.447 100.272Z"
              fill="#BAB8D3"
            />
            <path
              d="M26.3585 76.7988L17.2131 92.5112L8.06266 76.7988H7.61949L0 99.9897H4.48205L8.75763 86.9162L8.89864 86.4881L16.9764 100.272H17.4448L25.5225 86.4881L29.9391 99.9897H34.4262L26.8067 76.7988H26.3585Z"
              fill="#BAB8D3"
            />
            <path
              d="M44.1699 95.7748H56.3672L58.4219 99.9748H63.0399L50.6614 76.7588H49.8707L37.4922 99.9748H42.1102L44.1699 95.7748V95.7748ZM50.2686 84.0509L54.5542 92.1086H45.9879L50.2736 84.0509H50.2686Z"
              fill="#BAB8D3"
            />
            <path
              d="M33.8672 65.9567V2.3418H7.93676L0 17.3189H7.93676V65.9567H33.8672Z"
              fill="#BAB8D3"
            />
            <path
              d="M87.1798 44.7853C90.2971 41.3558 92.5482 37.1305 93.6158 32.4571C94.0238 30.6643 94.2605 28.806 94.3007 26.8974C94.2856 26.2981 94.2554 25.7038 94.2051 25.1196C92.9964 11.044 81.197 0 66.8142 0C52.4313 0 39.9722 11.6735 39.3427 26.3031H66.8142L39.3125 44.8407V65.438H94.3159V44.9011C94.3159 44.9011 93.4144 44.8709 92.1353 44.8457C90.7101 44.8155 88.8216 44.7802 87.1798 44.7853V44.7853Z"
              fill="#BAB8D3"
            />
            <path
              d="M106.502 51.4785L103.203 60.2613H105.192L105.787 58.5138H109.211L109.775 60.2613H111.875L108.662 51.4785H106.507H106.502ZM106.29 56.9627L107.051 54.7066C107.222 54.198 107.388 53.6641 107.504 53.2663H107.534C107.635 53.6742 107.801 54.1829 107.972 54.7066L108.707 56.9627H106.295H106.29Z"
              fill="white"
            />
            <path
              d="M118.922 56.3181C118.922 56.7109 118.938 57.2649 118.963 57.6577H118.922C118.741 57.2498 118.429 56.6706 118.162 56.2325L115.201 51.4785H113.156V60.2613H114.879V55.4116C114.879 54.9886 114.848 54.5354 114.838 54.1577H114.868C115.065 54.5505 115.347 55.0742 115.614 55.5124L118.575 60.2664H120.65V51.4836H118.928V56.3181H118.922Z"
              fill="white"
            />
            <path
              d="M128.649 56.3181C128.649 56.7109 128.664 57.2649 128.689 57.6577H128.649C128.468 57.2498 128.156 56.6706 127.889 56.2325L124.927 51.4785H122.883V60.2613H124.605V55.4116C124.605 54.9886 124.575 54.5354 124.565 54.1577H124.595C124.791 54.5505 125.073 55.0742 125.34 55.5124L128.302 60.2664H130.376V51.4836H128.654V56.3181H128.649Z"
              fill="white"
            />
            <path d="M134.574 51.4785H132.641V60.2613H134.574V51.4785Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_2164_8434">
              <rect width="165" height="100.735" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 165 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2164_8415)">
            <path
              d="M131.747 0.211914C112.917 0.211914 99.7578 12.4746 99.7578 30.5489V65.4384H131.107C151.719 65.4384 164.994 51.5289 164.994 32.4424C164.994 13.3559 151.478 0.211914 131.747 0.211914ZM110.575 60.216L109.931 58.2167H106.013L105.338 60.216H103.066L106.838 50.1792H109.306L112.982 60.216H110.58H110.575ZM122.999 60.216H120.632L117.248 54.7872C116.941 54.2886 116.618 53.6894 116.392 53.2411H116.362C116.377 53.6742 116.412 54.193 116.412 54.6764V60.2211H114.448V50.1843H116.785L120.169 55.6131C120.476 56.1117 120.829 56.7714 121.04 57.2397H121.09C121.06 56.7865 121.04 56.162 121.04 55.7088V50.1792H123.004V60.216H122.999ZM128.09 60.347C126.831 60.347 125.834 60.1203 125.064 59.7829V57.8642C125.789 58.2016 126.721 58.539 127.688 58.539C128.801 58.539 129.365 58.1513 129.365 57.411C129.365 56.5397 128.609 56.444 127.093 55.7843C126.061 55.316 125.079 54.5908 125.079 52.9138C125.079 50.9649 126.544 50.0433 128.574 50.0433C129.652 50.0433 130.573 50.3001 131.087 50.4965V52.3649C130.573 52.1232 129.652 51.8311 128.735 51.8311C127.738 51.8311 127.27 52.2037 127.27 52.8483C127.27 53.5232 127.834 53.7347 128.851 54.1224C130.155 54.7822 131.671 55.0743 131.671 57.3304C131.671 59.2793 130.14 60.3419 128.095 60.3419L128.09 60.347Z"
              fill="#BAB8D3"
            />
            <path
              d="M108.017 52.2236H107.986C107.856 52.6769 107.664 53.2862 107.473 53.8654L106.602 56.4438H109.356L108.52 53.8654C108.329 53.2711 108.133 52.6869 108.022 52.2236H108.017Z"
              fill="#BAB8D3"
            />
            <path
              d="M164.997 74.1701C164.997 72.5737 163.808 71.3398 162.076 71.3398C160.343 71.3398 159.266 72.4175 159.266 74.0039V77.0658H162.015C163.823 77.0658 164.992 75.8471 164.992 74.1701H164.997Z"
              fill="#BAB8D3"
            />
            <path
              d="M154.502 71.3449C152.906 71.3449 151.672 72.5283 151.672 74.2607C151.672 75.9931 152.75 77.0658 154.336 77.0658H157.398V74.3161C157.398 72.5082 156.174 71.3398 154.502 71.3398V71.3449Z"
              fill="#BAB8D3"
            />
            <path
              d="M151.677 81.84C151.677 83.4365 152.865 84.6703 154.593 84.6703C156.32 84.6703 157.398 83.5926 157.398 82.0062V78.9443H154.648C152.84 78.9443 151.672 80.1631 151.672 81.84H151.677Z"
              fill="#BAB8D3"
            />
            <path
              d="M162.335 78.9395H159.273V81.6891C159.273 83.497 160.497 84.6604 162.169 84.6604C163.841 84.6604 164.999 83.4719 164.999 81.7445C164.999 80.0172 163.922 78.9395 162.335 78.9395V78.9395Z"
              fill="#BAB8D3"
            />
            <path
              d="M147.348 85.4712C147.348 80.4503 143.249 77.0762 137.15 77.0762H126.398V99.99H130.714V81.0848H137.15C140.781 81.0848 143.037 82.8172 143.037 85.6072C143.037 88.9259 140.696 90.5626 135.876 90.5928V91.036L142.917 99.9951H148.255L142.509 92.6626L142.68 92.577C145.913 91.031 147.353 88.8403 147.353 85.4712H147.348Z"
              fill="#BAB8D3"
            />
            <path
              d="M108.116 100.736C114.96 100.736 120.319 95.3773 120.319 88.5333C120.319 81.6894 114.96 76.3311 108.116 76.3311C101.272 76.3311 95.9141 81.6894 95.9141 88.5333C95.9141 95.3773 101.272 100.736 108.116 100.736ZM108.116 80.3448C112.608 80.3448 115.998 83.8649 115.998 88.5383C115.998 93.2118 112.608 96.7269 108.116 96.7269C103.624 96.7269 100.235 93.2067 100.235 88.5383C100.235 83.87 103.624 80.3448 108.116 80.3448Z"
              fill="#BAB8D3"
            />
            <path
              d="M89.447 100.272H89.8851V77.0758H85.5692V91.4637L67.9381 76.7988H67.5V99.9897H71.8159V85.6521L89.447 100.272Z"
              fill="#BAB8D3"
            />
            <path
              d="M26.3585 76.7988L17.2131 92.5112L8.06266 76.7988H7.61949L0 99.9897H4.48205L8.75763 86.9162L8.89864 86.4881L16.9764 100.272H17.4448L25.5225 86.4881L29.9391 99.9897H34.4262L26.8067 76.7988H26.3585Z"
              fill="#BAB8D3"
            />
            <path
              d="M44.1699 95.7748H56.3672L58.4219 99.9748H63.0399L50.6614 76.7588H49.8707L37.4922 99.9748H42.1102L44.1699 95.7748V95.7748ZM50.2686 84.0509L54.5542 92.1086H45.9879L50.2736 84.0509H50.2686Z"
              fill="#BAB8D3"
            />
            <path
              d="M33.8672 65.9567V2.3418H7.93676L0 17.3189H7.93676V65.9567H33.8672Z"
              fill="#BAB8D3"
            />
            <path
              d="M87.1798 44.7853C90.2971 41.3558 92.5482 37.1305 93.6158 32.4571C94.0238 30.6643 94.2605 28.806 94.3007 26.8974C94.2856 26.2981 94.2554 25.7038 94.2051 25.1196C92.9964 11.044 81.197 0 66.8142 0C52.4313 0 39.9722 11.6735 39.3427 26.3031H66.8142L39.3125 44.8407V65.438H94.3159V44.9011C94.3159 44.9011 93.4144 44.8709 92.1353 44.8407C90.7101 44.8105 88.8216 44.7752 87.1798 44.7802V44.7853Z"
              fill="#BAB8D3"
            />
            <path
              d="M106.842 50.1738L103.07 60.2106H105.342L106.016 58.2113H109.934L110.579 60.2106H112.981L109.305 50.1738H106.837H106.842ZM106.601 56.4437L107.472 53.8652C107.663 53.2861 107.86 52.6717 107.985 52.2235H108.016C108.126 52.6918 108.323 53.271 108.514 53.8652L109.35 56.4437H106.596H106.601Z"
              fill="white"
            />
            <path
              d="M121.037 55.7034C121.037 56.1566 121.053 56.7811 121.088 57.2343H121.037C120.826 56.766 120.473 56.1063 120.166 55.6077L116.782 50.1789H114.445V60.2156H116.409V54.671C116.409 54.1875 116.379 53.6739 116.359 53.2357H116.389C116.616 53.689 116.938 54.2832 117.245 54.7818L120.63 60.2106H122.996V50.1738H121.032V55.7034H121.037Z"
              fill="white"
            />
            <path
              d="M128.85 54.1221C127.832 53.7344 127.268 53.5279 127.268 52.848C127.268 52.1682 127.737 51.8308 128.734 51.8308C129.65 51.8308 130.572 52.1228 131.086 52.3646V50.4962C130.572 50.3048 129.65 50.043 128.573 50.043C126.543 50.043 125.078 50.9595 125.078 52.9135C125.078 54.5905 126.06 55.3157 127.092 55.784C128.608 56.4437 129.363 56.5394 129.363 57.4107C129.363 58.151 128.799 58.5387 127.686 58.5387C126.719 58.5387 125.783 58.2013 125.062 57.8639V59.7826C125.838 60.12 126.835 60.3467 128.089 60.3467C130.134 60.3467 131.665 59.2841 131.665 57.3351C131.665 55.079 130.149 54.7869 128.845 54.1272L128.85 54.1221Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_2164_8415">
              <rect width="165" height="100.735" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
  }
};

export default Logo120Years;
