import { useAppDispatch, useAppSelector, useOnClickOutside } from '@hooks';
import { BenefitsSectionModel } from '@models';
import { selectIsSideModalOpen, setCursorStatus, setIsSideModalOpen } from '@store/dom-slice';
import { CursorStatus } from '@types';
import { gsap } from 'gsap';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';
import SVGCrossIcon from '../sections/benefits-section/icons/svg-cross-icon';
import styles from './side-modal.module.scss';

interface SideModalProps {
  benefitsSectionData: BenefitsSectionModel;
}

const SideModal = ({ benefitsSectionData }: SideModalProps) => {
  const { t } = useTranslation('common');
  const modalAnimation = useRef<GSAPTimeline>();
  const modalRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef(null);
  const listRef = useRef(null);
  const crossIconRef = useRef(null);
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsSideModalOpen);
  const words = useRef(t('general_benefits').split(' '));

  const handleCloseModal = () => {
    modalAnimation.current?.timeScale(2).reverse();
    gsap.delayedCall(modalAnimation.current!.duration(), () => dispatch(setIsSideModalOpen(false)));
  };

  useOnClickOutside(modalRef, () => {
    handleCloseModal();
  });
  useEffect(() => {
    modalAnimation.current = gsap
      .timeline({ paused: true })
      .to(modalRef.current, { autoAlpha: 1, duration: 0 })
      .fromTo(modalRef.current, { xPercent: -100 }, { xPercent: 0, ease: 'shine' })
      .to(
        [titleRef.current, listRef.current, crossIconRef.current],
        { autoAlpha: 1, duration: 0.75 },
        '>-15%',
      );
  }, []);

  useEffect(() => {
    isOpen && modalAnimation.current?.timeScale(1).play();
  }, [isOpen]);

  const onMouseOver = () => {
    dispatch(setCursorStatus(CursorStatus.large));
  };

  const onMouseLeave = () => dispatch(setCursorStatus(CursorStatus.small));

  return (
    <div ref={modalRef} className={styles.modalWrapper}>
      <div className={styles.innerWrapper}>
        <button
          ref={crossIconRef}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          onClick={handleCloseModal}
          className={styles.crossIcon}>
          <SVGCrossIcon />
        </button>
        <div ref={titleRef} className={styles.titleWrapper}>
          <h3 className={styles.titleFirstWord}>{words.current[0]}</h3>
          <h3 className={styles.titleSecondWord}>{words.current[1]}</h3>
        </div>
        <div ref={listRef} className={styles.listWrapper}>
          <ul>
            {benefitsSectionData.contentModalList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideModal;
