export enum Web3Network {
  mainnet = 'mainnet',
  testnet = 'testnet',
  cyan = 'cyan',
}

export enum Web3Chain {
  ethereum = 'ethereum',
  polygon = 'polygon',
  mumbai = 'mumbai',
}

export enum AttendingStatus {
  YES = 'YES',
  NO = 'NO',
  // MAYBE = 'MAYBE',
}

export enum IconNames {
  instagram = 'instagram',
  twitter = 'twitter',
}

export enum LandingSectionType {
  heroSection = 'heroSection',
  projectSection = 'projectSection',
  collectionSection = 'collectionSection',
  tokenizedCommunitySection = 'tokenizedCommunitySection',
  benefitsSection = 'benefitsSection',
  roadmapSection = 'roadmapSection',
  moreTCSection = 'moreTCSection',
  faqsSection = 'faqsSection',
}

export enum GSAPEffect {
  titleIn = 'titleIn',
  paragraphFadeIn = 'paragraphFadeIn',
  swapText = 'swapText',
  swapTitleIn = 'swapTitleIn',
}

export enum ScrollDirection {
  SCROLL_UP = 'up',
  SCROLL_DOWN = 'down',
}

export enum CursorStatus {
  small = 'small',
  large = 'large',
  drag = 'drag',
}

export enum BreakPoints {
  xxlDesktop = 1920,
  xlDesktop = 1400,
  lDesktop = 1200,
  desktop = 1024,
  tablet = 768,
  largeMobile = 600,
  mobile = 480,
}

export enum AirdropType {
  /**
   * Airdrop PHASE 1 NFT
   */
  PHASE1 = 'PHASE1',
  /**
   * Airdrop PHASE 2 1/10 unique NFT
   */
  PHASE2 = 'PHASE2',
  /**
   * Airdrop PHASE 3 NFT long term
   */
  PHASE3 = 'PHASE3',
}

export enum ApiRouteMethod {
  get = 'get',
  post = 'post',
  put = 'put',
}

export enum ApiRouteAuth {
  basic = 'basic',
  cookie = 'cookie',
}

export enum TrackType {
  REQUEST_PRIVATE_KEY = 'request_private_key',
}
