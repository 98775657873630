import { Tag } from '@components';
import {
  useAppDispatch,
  useGSAPSelector,
  useMobileMediaQuery,
  useOnScreen,
  useWindowSize,
} from '@hooks';
import { setCursorStatus, setLateralTexts } from '@store/dom-slice';
import { BenefitsSectionProps, CursorStatus } from '@types';
import { gsap } from 'gsap';
import Draggable from 'gsap/dist/Draggable';
import { InertiaPlugin } from 'gsap/dist/InertiaPlugin';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { FC, useEffect, useRef } from 'react';
import styles from './benefits-section.module.scss';
import SVGHoodie from './graphics/svg-hoodie';
import SVGParachute from './graphics/svg-parachute';
import { SVGPremium } from './graphics/svg-premium';
import Phase1Shape from './shapes/phase-1-shape';
import Phase2Shape from './shapes/phase-2-shape';
import Phase3Shape from './shapes/phase-3-shape';

const BenefitsSection: FC<BenefitsSectionProps> = ({
  shouldAvoidMovementAnimations,
  benefitsSectionData,
}) => {
  const { t } = useTranslation('common');
  const { ref, q } = useGSAPSelector();
  const masterWrapRef = useRef<HTMLDivElement>(null);
  const panelWrapRef = useRef<HTMLDivElement>(null);
  const phase1ShapeRef = useRef(null);
  const phase2ShapeRef = useRef(null);
  const phase3ShapeRef = useRef(null);
  const title1TagRef = useRef(null);
  const title1GraphicRef = useRef(null);
  const list1Ref = useRef(null);
  const title2TagRef = useRef(null);
  const title2GraphicRef = useRef(null);
  const list2Ref = useRef(null);
  const title3TagRef = useRef(null);
  const title3GraphicRef = useRef(null);
  const list3Ref = useRef(null);
  const titleWrapper1Ref = useRef(null);
  const titleWrapper2Ref = useRef(null);
  const titleWrapper3Ref = useRef(null);
  const isOnScreen = useOnScreen(ref);
  const dispatch = useAppDispatch();
  const isMobile = useMobileMediaQuery();
  const windowSize = useWindowSize();

  useEffect(() => {
    if (!windowSize.width) return;

    const calculateWrapperTranslation = () => {
      if (!windowSize.width) return 0;
      if (windowSize.width <= 375) return 20;
      if (windowSize.width <= 480) return 30;
      if (windowSize.width <= 500) return 15;
      if (windowSize.width <= 600) return 25;
      if (windowSize.width <= 768) return 5;
      if (windowSize.width <= 880) return 15;
      if (windowSize.width <= 1024) return 20;
      if (windowSize.width <= 1200) return 10;

      return 0;
    };
    const tagTranslation = 6;
    const wrapperTranslation = calculateWrapperTranslation();
    gsap.registerPlugin(Draggable, InertiaPlugin);
    gsap.set(title1TagRef.current, { x: `-${tagTranslation}vw` });
    gsap.set(titleWrapper2Ref.current, { x: `-${wrapperTranslation}vw` });
    gsap.set(
      [
        phase2ShapeRef.current,
        phase3ShapeRef.current,
        title2GraphicRef.current,
        title3GraphicRef.current,
        list2Ref.current,
        list3Ref.current,
      ],
      { opacity: 0 },
    );

    const slideDelay = 1000000000;
    const slideDuration = 1.5;
    const slides = q(`.${styles.slide}`);
    gsap.set(slides, {
      // backgroundColor: 'random([green, purple, orange, yellow, lime, pink])',
      xPercent: (i) => i * 100,
    });
    const numSlides = slides.length;
    const progressPerItem = 1 / (numSlides - 1);
    const snapProgress = gsap.utils.snap(progressPerItem);
    let slideWidth: number;
    let totalWidth: number;

    // ======= Animations =======
    const animation = gsap.to(slides, {
      xPercent: '-=' + (numSlides - 1) * 100,
      duration: 1,
      ease: 'none',
      paused: true,
    });

    const shapeAnimation = gsap
      .timeline({ paused: true })
      .to(phase1ShapeRef.current, {
        opacity: 0,
      })
      .to(phase2ShapeRef.current, { opacity: 1 }, '>')
      .to(phase2ShapeRef.current, { opacity: 0 }, '>')
      .to(phase3ShapeRef.current, { opacity: 1 }, '>');

    const tagAnimation = gsap
      .timeline({ paused: true })
      .to(title1TagRef.current, { x: 0 })
      .to([title1TagRef.current, title1GraphicRef.current, list1Ref.current], { opacity: 0 }, '<')
      .to([title2TagRef.current, title2GraphicRef.current, list2Ref.current], { opacity: 1 }, '>')
      .to(title2TagRef.current, { x: `-${tagTranslation}vw` }, '<')
      .to([title3TagRef.current, title3GraphicRef.current, list3Ref.current], { opacity: 1 }, '>')
      .to(title3TagRef.current, { x: `-${tagTranslation}vw` }, '<');

    const titleWrapperAnimation = gsap
      .timeline({ paused: true })
      .to(titleWrapper1Ref.current, { x: `${wrapperTranslation * 0.5}vw` }) // TODO aqui
      .to(titleWrapper2Ref.current, { x: 0 }, '<')
      .to(titleWrapper3Ref.current, { x: `-${wrapperTranslation}vw` }, '<')
      .to(titleWrapper2Ref.current, { x: `${wrapperTranslation * 0.5}vw` }, '>') // TODO aqui
      .to(titleWrapper3Ref.current, { x: 0 }, '<');

    // ====================

    const snapProgressDirectional = (value: number, direction: number) => {
      const constrainValue = Math.min(Math.max(value, 0), 1);
      const snapped = snapProgress(constrainValue);
      const result =
        snapped - constrainValue < 0 === direction < 0
          ? snapped
          : snapProgress(
              direction < 0 ? constrainValue - progressPerItem : constrainValue + progressPerItem,
            );
      const snapProg = snapProgress(
        direction < 0 ? constrainValue - progressPerItem : constrainValue + progressPerItem,
      );
      return result;
    };

    const animateSlides = (direction: number) => {
      const progress = snapProgress(animation.progress() + direction * progressPerItem);
      if (progress >= 0 && progress <= 1) {
        gsap.to(animation, {
          progress: progress,
          duration: slideDuration,
          overwrite: true,
          onComplete: () => timer.restart(true) as any,
        });
      }
    };

    const autoPlay = () => {
      if (draggable.isDragging || draggable.isThrowing || gsap.isTweening(animation)) {
        timer.restart(true);
      } else {
        animateSlides(1);
      }
    };

    const resize = () => {
      slideWidth = slides[0].offsetWidth;
      totalWidth = slideWidth * numSlides;
    };

    const timer = gsap.delayedCall(slideDelay, autoPlay);

    const tracker = InertiaPlugin.track(shapeAnimation, 'progress')[0]; // tracks velocity of progress
    const draggable = new Draggable(document.createElement('div'), {
      type: 'x',
      trigger: `.${styles.slidesContainer}`,
      onPress() {
        gsap.killTweensOf(animation);
        gsap.killTweensOf(shapeAnimation);
        gsap.killTweensOf(tagAnimation);
        gsap.killTweensOf(titleWrapperAnimation);
        this.startProgress = animation.progress();
      },
      onDrag() {
        const change = (draggable.startX - draggable.x) / totalWidth;
        animation.progress(this.startProgress + change);
        shapeAnimation.progress(this.startProgress + change);
        tagAnimation.progress(this.startProgress + change);
        titleWrapperAnimation.progress(this.startProgress + change);
      },
      onRelease() {
        const velocity = tracker.get('progress');
        // const newVelocity = Math.min(Math.max(velocity, -0.5), 0.5);
        gsap.to([animation, shapeAnimation, tagAnimation, titleWrapperAnimation], {
          inertia: {
            duration: { min: 0.5, max: 2 },
            progress: {
              velocity: velocity,
              min: 0,
              max: 1,
              end: (value) => snapProgressDirectional(value, velocity),
            },
          },
          onComplete: () => timer.restart(true) as any,
          overwrite: true,
        });
      },
    });

    resize();

    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [q, ref, windowSize.width]);

  useEffect(() => {
    const outAnimationTL = gsap
      .timeline({
        scrollTrigger: {
          trigger: ref.current,
          start: '+=25% top',
          scrub: 2,
        },
      })
      .to(
        ref.current,
        shouldAvoidMovementAnimations
          ? {
              autoAlpha: 0,
            }
          : {
              autoAlpha: 0,
              yPercent: -50,
            },
      );

    const inAnimationTL = gsap
      .timeline({
        scrollTrigger: {
          trigger: ref.current,
          start: `-=${shouldAvoidMovementAnimations ? 50 : 150}% top`,
          end: 'top top',
          once: true,
        },
        defaults: {
          duration: 2,
        },
      })
      .fromTo(masterWrapRef.current, { x: '50vw' }, { x: '0vw' })
      .from(
        ref.current,
        shouldAvoidMovementAnimations
          ? {
              autoAlpha: 0,
            }
          : {
              yPercent: 75,
              autoAlpha: 0,
            },
        0,
      );

    return () => {
      inAnimationTL.kill();
      outAnimationTL.kill();
    };
  }, [ref]);

  useEffect(() => {
    isOnScreen &&
      dispatch(setLateralTexts({ leftText: 'lateral_texts.benefits', rightText: '05' }));
  }, [isOnScreen, dispatch]);

  const onMouseOver = () => dispatch(setCursorStatus(CursorStatus.drag));
  const onMouseLeave = () => dispatch(setCursorStatus(CursorStatus.small));

  return (
    <section
      id="benefits"
      data-section-id="#benefits"
      ref={ref}
      className={styles.section}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}>
      <div ref={phase1ShapeRef} className={styles.shapeContainerPhase1}>
        <Phase1Shape />
      </div>
      <div ref={phase2ShapeRef} className={styles.shapeContainerPhase2}>
        <Phase2Shape />
      </div>
      <div ref={phase3ShapeRef} className={styles.shapeContainerPhase3}>
        <Phase3Shape />
      </div>

      <div className={styles.tagWrapper}>
        <Tag mode="dark" text={benefitsSectionData.tag} />
      </div>
      <div ref={masterWrapRef} className={styles.slidesContainer}>
        <div ref={panelWrapRef} className={styles.slidesInner}>
          <div className={styles.slide}>
            <div ref={titleWrapper1Ref} className={styles.titleWrapper}>
              <div ref={title1TagRef} className={styles.titleTag}>
                01
              </div>
              <div ref={title1GraphicRef} className={styles.title1Graphic}>
                <SVGPremium height={'80%'} width={'113%'} />
              </div>
              <h1 className={styles.title}>{t('phase')} 01</h1>
              <div className={styles.titleSeparator}>
                <Image
                  src="/svgs/ellipse.svg"
                  alt="ellipse"
                  height={10}
                  width={10}
                  layout="fixed"
                />
              </div>
            </div>
            <div ref={list1Ref} className={styles.listWrapper}>
              <ul>
                {benefitsSectionData.phase1List.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className={styles.slide}>
            <div ref={titleWrapper2Ref} className={styles.titleWrapper}>
              <div ref={title2TagRef} className={styles.titleTag}>
                02
              </div>
              <div ref={title2GraphicRef} className={styles.title2Graphic}>
                <SVGHoodie height={'100%'} width={'100%'} />
              </div>
              <h1 className={styles.title}>{t('phase')} 02</h1>
              <div className={styles.titleSeparator}>
                <Image
                  src="/svgs/ellipse.svg"
                  alt="ellipse"
                  height={10}
                  width={10}
                  layout="fixed"
                />
              </div>
            </div>
            <div ref={list2Ref} className={styles.listWrapper}>
              <ul>
                {benefitsSectionData.phase2List.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className={styles.slide}>
            <div ref={titleWrapper3Ref} className={styles.titleWrapper}>
              <div ref={title3TagRef} className={styles.titleTag}>
                03
              </div>
              <div ref={title3GraphicRef} className={styles.title3Graphic}>
                <SVGParachute height={'100%'} width={'100%'} />
              </div>
              <h1 className={styles.title}>{t('phase')} 03</h1>
            </div>
            <div ref={list3Ref} className={styles.listWrapper}>
              <ul>
                {benefitsSectionData.phase3List.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.generalBenefitsWrapper}>
        <button onClick={() => dispatch(setIsSideModalOpen(true))} className={styles.generalButton}>
          <div className={styles.generalBenefitsText}>{t('general_benefits')}</div>
          <div className={styles.generalButtonIcon}>
            <SVGPlusIcon />
          </div>
        </button>
      </div> */}
    </section>
  );
};

export default BenefitsSection;
