import { EnvBanner, ErrorFallback, LoadingScreen, MainLayout } from '@components';
import * as Sentry from '@sentry/react';
import store from '@store/store';
import { initialize as initializeFirebase } from '@utils/firebase';
import { registerEffects } from '@utils/gsap';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/dist/CSSRulePlugin';
import { CustomEase } from 'gsap/dist/CustomEase';
import { DrawSVGPlugin } from 'gsap/dist/DrawSVGPlugin';
import { InertiaPlugin } from 'gsap/dist/InertiaPlugin';
import { MotionPathPlugin } from 'gsap/dist/MotionPathPlugin';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { SplitText } from 'gsap/dist/SplitText';
import { TextPlugin } from 'gsap/dist/TextPlugin';
import { NextComponentType, NextLayoutComponentType, NextPageContext } from 'next';
import { appWithTranslation } from 'next-i18next';
import type { AppLayoutProps, AppProps } from 'next/app';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { ReactNode, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { listenWeb3AuthModalLayout } from '@utils/dom';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/globals.scss';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(
    SplitText,
    ScrollTrigger,
    MotionPathPlugin,
    TextPlugin,
    DrawSVGPlugin,
    InertiaPlugin,
    CustomEase,
    CSSRulePlugin,
    ScrollToPlugin,
  );
  registerEffects();
}

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}

declare module 'next' {
  type NextLayoutComponentType<P = unknown> = NextComponentType<NextPageContext, any, P> & {
    getLayout?: (page: ReactNode) => ReactNode;
  };
}

declare module 'next/app' {
  type AppLayoutProps<P = unknown> = AppProps & {
    Component: NextLayoutComponentType;
  };
}

function MyApp({ Component, pageProps }: AppLayoutProps) {
  const [loading, setLoading] = useState(true);
  const [isGlobalLoaderVisible, setIsGlobalLoaderVisible] = useState(true);
  const router = useRouter();
  const getLayout = Component.getLayout || ((page: ReactNode) => <MainLayout>{page}</MainLayout>);

  useEffect(() => {
    initializeFirebase();
    listenWeb3AuthModalLayout();
    if (process.env.NODE_ENV === 'production') {
      gsap.config({ nullTargetWarn: false });
    }
  }, []);

  useEffect(() => {
    const hasLoadedBefore = localStorage.getItem('hasLoadedBefore');
    const delay = router.asPath === '/' && !hasLoadedBefore ? 1500 : 0;
    const nestedDelay = delay > 0 ? delay + 500 : 0;
    if (!hasLoadedBefore) localStorage.setItem('hasLoadedBefore', 'true');

    let nestedTimer: NodeJS.Timeout;
    const timer = setTimeout(() => {
      if (typeof window !== 'undefined') {
        const loader = document.getElementById('globalLoader');
        if (loader) loader.style.display = 'none';
        setIsGlobalLoaderVisible(false);
      }
      nestedTimer = setTimeout(() => {
        setLoading(false);
      }, nestedDelay);
    }, delay);

    return () => {
      clearTimeout(timer);
      clearTimeout(nestedTimer);
    };
  }, [router.asPath]);

  useEffect(() => {
    NProgress.configure({ showSpinner: false });
    const handleRouteStart = () => NProgress.start();
    const handleRouteDone = () => NProgress.done();

    Router.events.on('routeChangeStart', handleRouteStart);
    Router.events.on('routeChangeComplete', handleRouteDone);
    Router.events.on('routeChangeError', handleRouteDone);

    return () => {
      Router.events.off('routeChangeStart', handleRouteStart);
      Router.events.off('routeChangeComplete', handleRouteDone);
      Router.events.off('routeChangeError', handleRouteDone);
    };
  }, []);

  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
    return <LoadingScreen isVisible={isGlobalLoaderVisible} showProgressBar={false} />;
  }

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError, eventId }) => (
        <ErrorFallback
          error={error}
          componentStack={componentStack}
          resetError={resetError}
          eventId={eventId}
        />
      )}>
      <Provider store={store}>
        <>
          {loading ? (
            <LoadingScreen isVisible={!isGlobalLoaderVisible} />
          ) : (
            getLayout(<Component {...pageProps} />)
          )}
        </>
        <EnvBanner />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Provider>
    </Sentry.ErrorBoundary>
  );
}

export default appWithTranslation(MyApp);
