import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@types';
import { User } from '@models';

interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state: UserState, action: PayloadAction<User>) => {
      state.user = action.payload ?? '';
    },
  },
});

export const selectCurrentUser = (state: RootState) => state.user.user;
export const { setUser } = userSlice.actions;

export default userSlice.reducer;
